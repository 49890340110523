import React, {useEffect, useState} from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';
import { Form,InputGroup } from 'react-bootstrap';
import { Formik } from 'formik';
import styles from '../../../rates.module.css';
import {toast} from "react-toastify";
import MultiSelect from "../../BulkDefaultChanges/MultiSelect";
import {useSelector} from "react-redux";

function TaxForm(props){
    const rentals = useSelector(({ rentals }) => rentals && rentals.rentals);
    const getValue=()=>{
        if(initValue && initValue.rentalId){
            const row=rentals.find((row)=>{
                return row.id===initValue.rentalId
            })
            return [{value:row.id, label:row.name}];
        }else{
            return [];
        }
    }
    const {schema,handleSubmit,initValue,currency,properties, onHide} = props;
    const [selectedCurr,setSelectedCurr]=useState(
        initValue && initValue.currencyVal?initValue.currencyVal:currency);
    const currencyVal=properties && properties.length!==0 && properties[0].currency;
    const [selectedRentals, setSelectedRentals] = useState(()=>getValue());
    const [count,setCount]=useState(0);

    const sameCurrency=(row)=>{
        return row.currency===currencyVal;
    }

    function diffCurrencyWarning() {
        if (!properties.every(sameCurrency)) {
            setCount(count+1);
        }
    }

    useEffect( () => {
        count === 1 && toast.warn(`Different currencies used in different rentals. Check the changes.`
            ,{
                style:{
                    color:'#484848'
                }
            });
    }, [count]);

    const getOptions = () => {
        const tmp=[];
        rentals.map((row) => (tmp.push({ value: row.id, label: row.name })));
        return tmp;
    }

    const onSubmit=(data)=>{
        if(selectedRentals && selectedRentals.length!==0){
            handleSubmit(data,selectedRentals);
        }
    }

    const handleReset=()=>{
        setSelectedRentals([]);
        onHide();
    }

    const validate=(values)=>{
        let errors = {};
        if(values.tax_type === "percentage" && values.modality !== "per stay"){
            errors.modality = 'Select per stay as modality while fee type is percentage.'
        }
        if(!selectedRentals.length){
            errors.multiRentals = 'Rental selection is a required field'
        }
        return errors;
    }

    return(
        <>
        <Formik
            validationSchema={schema}
            initialValues={(props.initValue) ? {
                multiRentals: selectedRentals,
                tax_name:props.initValue.name,
                tax_type:props.initValue.taxType,
                amount: props.initValue.amount,
                modality: props.initValue.modality,
            }:{
                multiRentals: [],
              tax_name: '',
              tax_type: '',
              amount: '',
              modality:'',
            }}
            onSubmit={onSubmit}
            validateOnChange={false}
            validateOnBlur={false}
            validate={validate}
        >
        {({ 
         values,errors,handleChange,handleSubmit,touched,initialValues
        }) => (
            <Form onSubmit={handleSubmit}>
                <Form.Row className='py-1'>
                    <Form.Label className={`col-sm-4 col-form-label`}>Select rental:*</Form.Label>
                    <div className="col-md-8 col-lg-6">
                        <MultiSelect
                            options={getOptions()}
                            value={selectedRentals}
                            name="multiRentals"
                            onChange={setSelectedRentals}
                            onBlur={diffCurrencyWarning}
                            disabled={initValue && initValue.rentalId}
                            placeholder={"---Select Rental---"}
                            fieldHasError={!!errors.multiRentals}
                        />
                        <div style={{fontSize:'0.8em',color:'#dc3545',padding:'0em'}}>{errors.multiRentals}</div>
                    </div>
                </Form.Row>
                <br/>
              <Form.Row>
                  <Form.Label className="col-sm-4 col-form-label">Name of tax:</Form.Label>
                  <div className="col-md-5 col-lg-6">
                  <Form.Control
                    type="text"
                    name="tax_name"
                    onChange={handleChange}
                    isInvalid={!!errors.tax_name }
                    defaultValue={initialValues.tax_name}
                    className={styles.form_entry}
                  />
                  <Form.Control.Feedback type="invalid">{errors.tax_name}</Form.Control.Feedback>
                  </div>
              </Form.Row>
              <br/>
              <Form.Row>
                  <Form.Label className="col-sm-4 col-form-label">Type of tax:</Form.Label>
                  <div className="col-md-5 col-lg-6">
                  <Form.Check
                    inline
                    name="tax_type"
                    label="percentage"
                    id={"percentage"}
                    onChange={handleChange}
                    value="percentage"
                    type="radio"
                    defaultChecked={initialValues.tax_type==="percentage"}
                  />
                  <Form.Check
                    inline
                    name="tax_type"
                    label="flat rate"
                    id={"flat rate"}
                    onChange={handleChange}
                    value="flat rate"
                    type="radio"
                    defaultChecked={initialValues.tax_type==="flat rate"}
                  />
                  <div style={{fontSize:'0.8em',color:'#dc3545',padding:'0em'}}>{errors.tax_type}</div>
                  </div>
              </Form.Row>
              <br/>
              <Form.Row>
                  <Form.Label className="col-sm-4 col-form-label">Amount:</Form.Label>
                  <div className="col-md-5 col-lg-6">
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="float"
                      name="amount"
                      onChange={handleChange}
                      isInvalid={!!errors.amount}
                      defaultValue={initialValues.amount}
                      className={styles.form_entry}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text id="basic-addon2" className={styles.form_entry}>{(values.tax_type==="percentage")?"%"
                          :getSymbolFromCurrency(`${selectedCurr}`)}</InputGroup.Text>
                    </InputGroup.Append>
                    <Form.Control.Feedback type="invalid">{errors.amount}</Form.Control.Feedback>
                  </InputGroup>
                  </div>
              </Form.Row>
              <br/>
                <Form.Row>
                    <Form.Label className="col-sm-4 col-form-label">Modality:</Form.Label>
                    <div className="col-md-8">
                        <Form.Check
                            inline
                            name="modality"
                            label="per stay"
                            id={"perStay"}
                            onChange={handleChange}
                            value="per stay"
                            type="radio"
                            defaultChecked={initialValues.modality==="per stay"}
                        />
                        <Form.Check
                            inline
                            name="modality"
                            label="per night"
                            id={"perNight"}
                            onChange={handleChange}
                            value="per night"
                            type="radio"
                            disabled={values.tax_type==="percentage"}
                            defaultChecked={initialValues.modality==="per night"}
                        />
                        <Form.Check
                            inline
                            name="modality"
                            label="per person"
                            id={"perPerson"}
                            onChange={handleChange}
                            value="per person"
                            type="radio"
                            disabled={values.tax_type==="percentage"}
                            defaultChecked={initialValues.modality==="per person"}
                        />
                        <Form.Check
                            inline
                            name="modality"
                            label="per person per night"
                            id={"pppn"}
                            onChange={handleChange}
                            value="pppn"
                            type="radio"
                            disabled={values.tax_type==="percentage"}
                            defaultChecked={initialValues.modality==="pppn"}
                        />
                        <div style={{fontSize:'0.8em',color:'#dc3545',padding:'0em'}}>{errors.modality}</div>
                    </div>
                </Form.Row>
                <br/>
              <div className='p-2 d-flex justify-content-center'>
                  <div className='col-md-8 d-flex justify-content-around'>
                      <button  type="submit" className={`col-lg-5 p-1 mx-2 col-sm-5 ${styles.allBtn}`} >Save</button>
                      <button type="reset" className={`col-lg-5 p-1 mx-2 col-sm-5 ${styles.allBtn}`} style={{backgroundColor:"#6c757d"}} onClick={handleReset}>Discard</button>
                  </div>
              </div>
          </Form>
        )}
        </Formik>
        </>
    )
}

export default TaxForm;