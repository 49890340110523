import React, {useState} from 'react';
import { Nav, NavDropdown, Navbar } from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';
import ReactTooltip from "react-tooltip";

import styles from './topbar.module.css';
import logo from '../../../../icons/Logo_B_1000.png';
import address from '../../../../icons/address-book-regular.svg';
import help from '../../../../icons/question-circle-solid.svg';
import person from '../../../../icons/person-lines.svg';
import calendar from '../../../../icons/calendar-alt-regular.svg';
import settingIcon from '../../../../icons/cog-solid.svg';
import rentalB from '../../../../icons/building-solid.svg';
import rates from '../../../../icons/comment-dollar-solid.svg';
import hotel from '../../../../icons/inquiries-icon.svg';
import invoice from '../../../../icons/file-invoice-dollar-solid.svg';
import channel from '../../../../icons/channel management.svg';

import { useSelector, useDispatch } from 'react-redux';
import { logoutUser } from '../../../../general_redux/user/actions';
import {changeInternalRoute} from '../../../../general_redux/actions';

function TopBar() {
  const settings=useSelector(({generalSetting})=>generalSetting && generalSetting.setting);

  const links = [
    { lable: 'Rentals', img: rentalB, to: '/view/rentals',path:"/rentals", icon: 'fas fa-home' },
    { lable: 'Rates', img: rates, to: '/view/rates',path:"/rates", icon: 'fas fa-tags' }, // fa-money
    { lable: 'Settings', img: settingIcon, to: '/view/settings',path:"/settings", icon: 'fa fa-cog' },
    { lable: 'Reservations', img: hotel, to: '/view/inquiry',path:"/inquiry", fa: 'fa fa-list-alt',display:true },
    { lable: 'Invoices', img: invoice, to: '/view/invoice',path:"/invoice", fa: 'fa fa-file',display:true },
    { lable: 'Channels', img: channel, to:'/view/channel',path:"/channel", icon:'fas fa-project-diagram',hidden:settings && settings.accountType==="free"},
  ];
  const sideLinks = [
    { lable: 'Calendar', img: calendar, to: '/view/calendar',path:'/calendar', fa: 'fa fa-calendar' },
    { lable: 'Guests', img: address, to: '/view/guest',path:'/guest', fa: 'fa fa-address-book' },
    { lable: 'Reservations', img: hotel, to: '/view/inquiry',path:'/inquiries', fa: 'fa fa-list-alt',display:true },
    { lable: 'Invoices', img: invoice, to: '/view/invoice',path:'/invoices', fa: 'fa fa-file',display:true },
  ];

  const user = useSelector(({ user }) => user && user.user);
  const dispatch = useDispatch();
  const logOut = () => {
    dispatch(logoutUser());
  };

  const setCollapse=(path)=>{
    let elem=document.getElementById('navbar-nav-main');
    let elem2=document.getElementById('responsive-navbar-nav');
    elem2.classList.remove('show');
    elem.classList.add('collapsed');
    elem.classList.remove(`${styles.rotate}`);
    dispatch(changeInternalRoute(path));
  }

  const [toggle, setToggle] = useState(false);

  return (
    <>
      <Navbar collapseOnSelect expand="lg" variant="light" className={styles.topBarPanel}>
        <Navbar.Brand className="mr-0" href="/view">
          <div className="d-none d-xl-block  d-lg-block">
            <img src={logo} className={styles.logoHeader} alt="logo"/>
          </div>
          <div className={`d-flex d-xl-none d-xs-none  d-lg-none justify-content-between ${styles.header_sm_menu}`}>
            <img src={logo} className={styles.logoHeader} alt="logoSmall"/>
            <div className="d-flex">
              {sideLinks &&
                sideLinks.map((item,index) => (
                  <Nav.Item className={`${(item.display)?styles.hideLinks:styles.side_links}`} key={`topbarLink_${index}`}>
                    <NavLink
                      to={item.to}
                      activeClassName={styles.side_links__isActive}
                      className={`w-100 d-flex flex-column align-items-center text-decoration-none`}
                      onClick={(e)=>dispatch(changeInternalRoute(item.path))}
                    >
                      <i className={`${item.fa} ${styles.sidebar_icons}`}/>
                    </NavLink>
                  </Nav.Item>
                ))}
            </div>
          </div>
        </Navbar.Brand>
        <div onClick={() => setToggle(!toggle)}>
          <Navbar.Toggle className={`${styles.toggle} ${toggle ? styles.rotate : null}`} aria-controls="responsive-navbar-nav " id={"navbar-nav-main"}/>
        </div>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className={`mr-auto ${styles.topLinkContainer}`}>
            {links &&
              links.map((item, i) =>
                !item.hidden?(
                    <NavLink
                        to={item.to}
                        className={`${(item.display)?styles.topLinkHide:styles.topLink}`}
                        key={`topMenu_${i}`}
                        activeClassName={styles.isActive}
                        onClick={(e)=>setCollapse(item.path)}
                    >
                      <div className={styles.linkcontainer}>
                        {(
                            <div className={styles.iconStyle}>
                              <i className={`${item.icon} ${styles.icon}`}/>
                            </div>
                        )}
                        <span className={styles.topLinkText} style={{fontWeight:'bold'}}>{item.lable}</span>
                      </div>
                    </NavLink>
                ):(
                    <NavLink
                        to={item.to}
                        className={`${(item.display)?styles.topLinkHide:styles.topLink}`}
                        key={`topMenu_${i}`}
                        onClick={(e)=>dispatch(changeInternalRoute(item.path))}
                    >
                      <div className={styles.linkcontainer}>
                        {(
                            <>
                              <div className={styles.iconStyle} data-for="channelPlusIcon" data-tip>
                                <i className={`${item.icon} ${styles.disabledIcon}`} />
                              </div>
                              <ReactTooltip place="bottom" type="dark" id="channelPlusIcon" effect="solid" className={styles.toolTip}>
                                <span>
                                Only available for Plus Plan users.
                                </span>
                              </ReactTooltip>
                            </>
                        )}
                        <span className={styles.topLinkText} style={{fontWeight:'bold',color:"#adacac"}}>{item.lable}</span>
                      </div>
                    </NavLink>
                )
              )}
          </Nav>
          <Nav className={styles.navbar2}>
            <a href={"https://www.beehaz-resources.com/"} target={"_blank"} rel="noreferrer">
              <img src={help} className={styles.imgStyle} alt="helpImage"/>
            </a>
            <img src={person} className={styles.imgStyle} alt="personImage"/>
            <div className={styles.dropdownBox}>
              <NavDropdown title={user.name} id="collapsible-nav-dropdown" className={styles.dropdownStyle}>
                <NavDropdown.Item id="settings" className={styles.dropDownBox2}>
                  <div className={styles.dropdownItemStyle}>
                    <Link
                        to="/view/userSetting"
                        className={styles.dropdownItemStyle}
                        onClick={(e)=>dispatch(changeInternalRoute('/userSetting'))}
                    >
                      User Settings
                    </Link>
                  </div>
                </NavDropdown.Item>
                <NavDropdown.Item
                    id="logout"
                    onClick={logOut}
                    className={styles.dropDownBox2}
                >
                  <div className={styles.dropdownItemStyle}>Logout</div>
                </NavDropdown.Item>
              </NavDropdown>
            </div>
            <div className={styles.hiddenLink}>
              <NavLink
                  to="/view/userSetting"
                  activeClassName={styles.isActive}
                  className={styles.hiddenNavLink}
                  onClick={(e)=>setCollapse('/userSetting')}
              >
                <div className={styles.hiddenLinkItem}>User Settings</div>
              </NavLink>
              <div className={styles.hiddenLinkItem}>
                <div onClick={logOut}>Logout</div>
              </div>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}
export default TopBar;
