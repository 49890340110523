import React,{useState,useEffect} from 'react';
import {useDispatch,useSelector} from "react-redux";
import * as yup from 'yup';
import moment from 'moment';
import { withFormik } from 'formik';
import countryList from "react-select-country-list";
import AddGuestForm from './AddGuestForm';
import GuestBookingList from '../List/GuestBookingList';
import GuestInvoiceList from '../List/GuestInvoiceList';
import styles2 from '../../guest.module.css';
import {isoCountries} from "../../../../../../config/data/iso2Country";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {invoiceByGuest} from '../../../../../../general_redux/invoice/actions';

function AddGuests(props){
    const initValue=props.value;
    const dispatch=useDispatch();
    const [currentTab,setCurrentTab]=useState("Guest Details");
    const tabs=["Guest Details","Bookings","Invoices"];
    const user= useSelector(({user})=>user && user.user);
    const invoiceGuest= useSelector(({invoice})=>invoice && invoice.invoiceByGuest);
    const dateDisplay = useSelector(({generalSetting})=>generalSetting && generalSetting.setting && generalSetting.setting.dateDisplay);

    useEffect(()=>{
        const id=initValue?initValue.id:null;
        if(id){
            dispatch(invoiceByGuest(id));
        }
    },[])

    const findArray = (val)=>{
      const ar=countryList().getData().filter((row)=>row.label===val);
      if(ar.length!==0){
          return ar[0];
      }
    }

    const findCountry=(val)=>{
        if(val){
            for (const key in isoCountries){
                if(isoCountries[key]===val){
                    return key.toLocaleLowerCase();
                }
            }
        }else{
            return "us";
        }
    }

    const handleSubmit=(data)=>{
        const payload={
            id:(props.value)?props.value.id :undefined,
            name: data.name,
            emailId:data.emailId,
            phoneNo:data.phoneNo,
            customerId:(!props.value)?user.id:undefined,
            postalCode: data.postalCode,
            secondaryEmailId: data.secondaryEmailId,
            country: (data.country)?data.country.label:"",
            address: data.addressDetail,
            maxGuests: data.maxGuests,
            street: data.street!==null?data.street:"",
            state:data.state,
            nationality:data.nationality,
            language:data.language,
            company:data.company,
            notes:data.notes,
            dob:moment(data.dob).format().slice(0,10),
        }
        // console.log(payload);
        props.onSubmit(payload);
    }

    const phoneRegExp = /^[0-9-+\s()]*$/;

    const FormikEnhancer = withFormik({
        validationSchema:yup.object({
            name: yup.string().required(),
            company: yup.string().nullable(),
            emailId:yup.string().email('Invalid email').required('Email is a required field'),
            secondaryEmailId :yup.string().email('Invalid email'),
            phoneNo:yup.string().matches(phoneRegExp, 'Only numbers, space, parenthesis, + and - are accepted.'),
            country:yup.array().nullable(),
            address: yup.string(),
            postalCode: yup.string(),
            addressDetail:yup.string(),
            street: yup.string().nullable(),
            state: yup.string(),
            nationality:yup.string(),
            language:yup.string(),
            notes:yup.string(),
            dob: yup.string(),
            code:yup.string(),
        }),
        mapPropsToValues: (props)=>({
            name: (initValue)?initValue.name:"",
            company: (initValue)?initValue.company:"",
            emailId: (initValue)?initValue.emailId:"",
            secondaryEmailId: (initValue)? initValue.secondaryEmailId:"",
            phoneNo: (initValue)?initValue.phoneNo:"",
            postalCode: (initValue)?initValue.postalCode:"",
            addressDetail: (initValue)?initValue.address:"",
            country:(initValue)? findArray(initValue.country):findArray(user.country),
            address:(initValue)?initValue.address:"",
            nationality: (initValue)?initValue.nationality:"",
            street:(initValue)?initValue.street:"",
            state:(initValue)?initValue.state:"",
            language:(initValue)?initValue.language:"",
            notes:(initValue)?initValue.notes:"",
            dob:(initValue && (initValue.dob!=="Invalid da" && initValue.dob!=="" && initValue.dob!==null && initValue.dob!=="[NULL]"))?new Date(initValue.dob):"",
            code:(initValue)?findCountry(initValue.country):findCountry(user.country),
            dateDisplay:dateDisplay,
            paddingWrapper: 'p-3',
        }),
        validateOnBlur:false,
        validateOnChange:false,
        handleSubmit :  (values, { setSubmitting }) => {
            handleSubmit(values);
            setSubmitting(false);
          },
        displayName: 'AddGuestForm',
      })(AddGuestForm);

    return(
      <>
          <div className={styles2.upperRow}>
              <a className={`${styles2.tabBox} ${styles2.tabButton}`} onClick={props.onHide}>
                  <span >
                      <ArrowBackIosIcon className={styles2.arrowIcon} />{"Back"}
                  </span>
              </a>
              {tabs.length!==0 && tabs.map((tab,index)=>(
                  <>
                      {((tab==="Bookings" || tab==="Invoices") && !initValue) ?(
                         null
                      ):(
                          <a className={(currentTab===tab)?`${styles2.tabBox} ${styles2.active}`:`${styles2.tabBox}`} key={index} onClick={()=>setCurrentTab(tab)}>
                              {tab}
                          </a>
                      )}
                  </>
              ))}
          </div>
          <div style={{margin:'0em 1em'}}>
              {currentTab==="Guest Details" && (
                  <FormikEnhancer/>
              )}
              {initValue && (
                  <>
                      {currentTab==="Bookings" && (
                          <GuestBookingList id={initValue? initValue.id:"1"} guestName={initValue?initValue.name:false}/>
                      )}
                      {currentTab==="Invoices" && (
                          <GuestInvoiceList invoiceGuest={invoiceGuest} guestDetail={initValue} guestName={initValue?initValue.name:false}/>
                      )}
                  </>
              )}
          </div>
      </>
    )
}
export default AddGuests;