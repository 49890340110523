import {toast} from "react-toastify";

import {
  LOAD_INQUIRIES,
  DELETE_INQUIRIES,
  DOWNLOAD_HISTORIC_DATA
} from './types' ;

import {actionForState} from "../../common/functions/utils";
toast.configure();

const INITIAL_STATE = {
  inquiryData: [],
  historyData: [],
  action:{
    loading:false,
    success:undefined
  },
  actionForInquiries:{
    loading:false,
    success:false,
    error:false
  },
  actionForDeleteInquiries:{
    loading:false,
    success:false,
    error:false
  },
  actionForHistory:{
    loading:false,
    success:false,
    error:false,
  }
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOAD_INQUIRIES.pending:
      return{
        ...state,
        actionForInquiries: actionForState(state.action, 'pending'),
      }
    case LOAD_INQUIRIES.success:
      return{
        ...state,
        inquiryData:action.payload.data,
        actionForInquiries: actionForState(state.action,'success')
      }
    case LOAD_INQUIRIES.error:
      toast.error(action.payload.message)
      return{
        ...state,
        actionForInquiries: actionForState(state.action,'error',action.payload.message),
        inquiryData: INITIAL_STATE.inquiryData,
      }
    case DELETE_INQUIRIES.pending:
      return{
        ...state,
        actionForDeleteInquiries: actionForState(state.action,'pending'),
      }
    case DELETE_INQUIRIES.success:
      toast.success('Reservations deleted successfully!')
      const inquiryF = [...state.inquiryData]
      const deleteInquiry = action.payload.inquiry
      let filterInquiry = inquiryF.filter((inq)=> !deleteInquiry.includes(inq.id))
      return{
        ...state,
        inquiryData:filterInquiry,
        actionForDeleteInquiries: actionForState(state.action,'success')
      }
    case DELETE_INQUIRIES.error:
      toast.error(action.payload.message)
      return{
        ...state,
        actionForDeleteInquiries: actionForState(state.action,'error')
      }
    case DOWNLOAD_HISTORIC_DATA.pending:
      return {
        ...state,
        actionForHistory: actionForState(state.action,'pending')
      }
    case DOWNLOAD_HISTORIC_DATA.success:
      toast.success("Historic data downloaded successfully!")
      return{
        ...state,
        historyData: action.payload.historicData,
        actionForHistory: actionForState(state.action,'success')
      }
    case DOWNLOAD_HISTORIC_DATA.error:
      toast.error("Some error occurred!")
      return{
        ...state,
        actionForHistory: actionForState(state.action,'error')
      }
    default:
      return state;
  }
}
