import React, { useRef } from "react";
import ReactSelect from "react-select";

const MultiSelect = (props) => {
  const valueRef = useRef(props.value);
  valueRef.current = props.value;

  const selectAllOption = {
    value: "<SELECT_ALL>",
    label: "All Rentals"
  };

  const isSelectAllSelected = () =>
    valueRef.current.length === props.options.length;

  const isOptionSelected = (option) => {
    const check =
      valueRef.current.some(({ value }) => value === option.value) ||
      isSelectAllSelected();
    return check;
  };

  const getOptions = () => {
    if(props.addAllOpt){
      return [...props.options]
    }else{
      return [selectAllOption, ...props.options]
    }
  }

  const getValue = () =>
    isSelectAllSelected() ? [selectAllOption] : props.value;

  const onChange = (newValue, actionMeta) => {
    const { action, option, removedValue } = actionMeta;

    if (action === "select-option" && option.value === selectAllOption.value) {
      props.onChange(props.options, actionMeta);
    } else if (
      (action === "deselect-option" &&
        option.value === selectAllOption.value) ||
      (action === "remove-value" &&
        removedValue.value === selectAllOption.value)
    ) {
      props.onChange([], actionMeta);
    } else if (
      actionMeta.action === "deselect-option" &&
      isSelectAllSelected()
    ) {
      props.onChange(
        props.options.filter(({ value }) => value !== option.value),
        actionMeta
      );
    } else {
      props.onChange(newValue || [], actionMeta);
    }
  };
  const customStyles = (hasError) => ({
    control: (styles) => ({
      ...styles,
      ...(hasError && { borderColor: 'red' }),
    }),
  });

  return (
    <ReactSelect
      isOptionSelected={isOptionSelected}
      options={getOptions()}
      value={getValue()}
      onChange={onChange}
      hideSelectedOptions={false}
      isMulti
      onBlur={props.onBlur}
      isDisabled={props.disabled}
      styles={customStyles(props.fieldHasError)}
      placeholder={props.placeholder}
    />
  );
};

export default MultiSelect;