import React, {useState, useEffect, useRef} from 'react';
import * as yup from 'yup';
import { Formik, Form, Field } from 'formik';
import styles from '../editBooking.module.css';
import moment from 'moment';
import {useSelector,useDispatch} from "react-redux";
import {
  editBooking,
  chargeCalculation,
  fetchBookingById,
  resetCharges,
  fetchDiscountByBooking,
  editDiscount,
  clearBooking
} from "../../../../../../../general_redux/calendar/actions";
import { Row, Col } from 'react-bootstrap';
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ReactTooltip from "react-tooltip";
import Loading from "../../../../../../../components/loading";
import {toast} from "react-toastify";
import {editGuest} from "../../../../../../../general_redux/guest/actions";
import CustomDate from "../../../../../../../components/CustomDate";
import {zeroPad} from '../../../../../../../common/functions/utils';

const BookingDetails = ({ bookingId,rentals,handleEditBooking }) => {
  const firstUpdate = useRef(true);
  const bookingData= useSelector(({calendar}) => calendar && calendar.bookingById);
  const guestDetails = useSelector(({guests})=>guests && guests.guestByBooking)[0];
  const bookingDiscount=useSelector(({calendar})=>calendar && calendar.bookingDiscount);
  const [moreInfo,setMoreInfo]=useState(guestDetails && guestDetails.notes);
  const [miniNotes,setMiniNotes]=useState(bookingData && bookingData.miniNotes);
  const rentalList= useSelector(({rentals})=>rentals && rentals.rentals);
  const currentCharges=useSelector(({calendar})=>calendar && calendar.currentCharges);
  const actionForFetchBooking=useSelector(({calendar})=>calendar && calendar.actionForFetchBooking);
  const actionEditBooking = useSelector(({calendar})=>calendar && calendar.actionEditBooking);
  const {numberOf, allowBookingFor} = useSelector(({generalSetting}) => generalSetting && generalSetting.setting);
  const [rental,setRental]=useState(bookingData.rentalId);
  const [source,setSource]=useState(bookingData.source);
  const [color,setColor]=useState(bookingData.color);
  const [paymentStatus,setPaymentStatus]=useState(bookingData.paymentStatus);
  const [notes,setNotes]=useState(bookingData.notes);
  const [children,setChildren]=useState(bookingData.noOfChildren);
  const [adult,setAdult]=useState(bookingData.noOfAdults);
  const [totalGuest,setTotalGuest]=useState(bookingData.noOfAdults);
  const [arrival,setArrival]=useState((bookingData && bookingData.arrive)? new Date(bookingData.arrive) : "");
  const [departure,setDeparture]=useState((bookingData && bookingData.depart) ? new Date(bookingData.depart) : "");
  const [finalPrice,setFinalPrice]=useState(bookingData && bookingData.price);
  const [totalNights,setTotalNights]=useState(bookingData?moment(bookingData.depart).diff(moment(bookingData.arrive),'days'):"")
  const [checkIn,setCheckIn]=useState(bookingData?bookingData.checkInTime:"")
  const [checkOut,setCheckOut]=useState(bookingData?bookingData.checkOutTime:"")

  const findRentalDetail=(rentalId)=>{
    const ar=rentalList.filter((rt)=>Number(rt.id)===Number(rentalId));
    if(ar.length!==0){
      return ar[0].maxGuests;
    }else{
      return 1;
    }
  }

  const setData=(guest)=>{
    setTotalGuest(guest);
    setAdult(guest-children);
  }

  const validate=(val,name)=>{
    if(name==="totalGuests") {
      if(!totalGuest || totalGuest <= 0){
        return 'At least 1 guest is required';
      } else if (totalGuest > rentalDetail) {
        return `Only ${rentalDetail} guests are allowed!`
      }
    }
    if(name==="adult" && adult<1){
      return `At least 1 adult is required!`
    }
    if(name==="children" && children<0){
      return `Value should be a positive number!`
    }
    else{
      return "";
    }
  }

  const [rentalDetail,setRentalDetail]=useState(findRentalDetail(bookingData.rentalId));

  const paymentOpt = ['Payment Pending', 'Partially Paid', 'Paid'];
  const dispatch=useDispatch();

  useEffect(()=>{
    dispatch(fetchBookingById(bookingId));
    dispatch(fetchDiscountByBooking(bookingId));
  },[])

  useEffect(()=>{
    return()=>{
      dispatch(resetCharges());
      dispatch(clearBooking());
    }
  },[])

  useEffect(()=>{
    if(actionEditBooking.success && currentCharges){
      const discountPayload={
        bookingId:bookingId,
        rentalId:Number(rental),
        discountName:bookingDiscount.discountName,
        discountAmount:bookingDiscount.discountAmount,
        chargeName:bookingDiscount.chargeName,
        chargeAmount:bookingDiscount.chargeAmount,
        tax: currentCharges.taxes,
        fees:currentCharges.fees,
        extraGuestFee:currentCharges.extra_guest_fees,
        baseRate:currentCharges.daily_rate,
        totalPrice:currentCharges.total,
        bookingPrice:currentCharges.total,
        nights:totalNights,
        noOfGuests:adult + children
      }
      dispatch(editDiscount(discountPayload,bookingDiscount.id));
    }
  },[actionEditBooking])

  useEffect(()=>{
    if(actionForFetchBooking.success && bookingData){
      setRental(bookingData.rentalId)
      setPaymentStatus(bookingData.paymentStatus);
      setColor(bookingData.color);
      setAdult(bookingData.noOfAdults);
      setChildren(bookingData.noOfChildren);
      setArrival(new Date(bookingData.arrive));
      setDeparture(new Date(bookingData.depart));
      setTotalGuest(bookingData.noOfAdults + bookingData.noOfChildren);
      setTotalNights(bookingData?moment(bookingData.depart).diff(moment(bookingData.arrive),'days'):"")
      setSource(bookingData.source);
      setPaymentStatus(bookingData.paymentStatus);
      setMiniNotes(bookingData.miniNotes);
      setNotes(bookingData.notes);
      setCheckIn(bookingData.checkInTime);
      setCheckOut(bookingData.checkOutTime);
      setFinalPrice(bookingData.price);
    }
  },[bookingData])

  useEffect(()=>{
    if(arrival===undefined && departure===undefined && rental===undefined && totalGuest===undefined ){
      //do nothing
      return
    }
    else if (firstUpdate.current ){
      firstUpdate.current = false;
    }
    else if(!firstUpdate.current){
      if(bookingDiscount && bookingDiscount.chargeAmount!==undefined && bookingDiscount.discountAmount!==undefined && arrival && departure && rental && totalGuest){
        // const data = {
        //   arrive:moment(arrival, "YYYY-MM-DD").format().slice(0,10),
        //   depart:moment(departure, "YYYY-MM-DD").format().slice(0,10),
        //   noOfGuests:Number(totalGuest),
        //   rentalId:Number(rental),
        //   extraCharge:bookingDiscount.chargeAmount,
        //   discount:bookingDiscount.discountAmount
        // };
        // console.log("Data departure:::",data);
        
        dispatch(chargeCalculation({
          arrive:moment(arrival).format().slice(0,10),
          depart:moment(departure).format().slice(0,10),
          noOfGuests:Number(totalGuest),
          rentalId:Number(rental),
          extraCharge:bookingDiscount.chargeAmount,
          discount:bookingDiscount.discountAmount
        }))
        setTotalNights(moment(departure).diff(moment(arrival),'days'));
      }
    }
  },[arrival,departure,rental,totalGuest])

  useEffect(()=>{
    if(currentCharges ){
      if(!currentCharges.minStay){
        toast.warn(`Minimum stay requirements set for this rental: ${currentCharges.minStayConstrain} nights!!`
          ,{
            style:{
              color:'#484848'
            }
          });
      }
      setFinalPrice(currentCharges.total);
    }
  },[currentCharges])

  const updateSchema = yup.object().shape({
    adult: yup.number().when('isDisabled', {
      is: true,
      then: yup.number().min(1, 'At least 1 adult is required').required('At least 1 adult is required')
    }),
    children: yup.number().min(0, 'Cannot be a negative number'),
    arrival: yup.date().required('Arrival date is required').nullable(),
    departure: yup.date().required('Departure date is required').nullable(),
  });

  const rentalSelector = rentals.map((row) => ({ id: row.id, name: row.name }));
  const channels=[{label:'Beehaz',name: 'Beehaz'},{label:'Agoda', name: 'Agoda'},{label: 'Airbnb', name: 'Airbnb'},{label:'Booking.com', name: 'Booking.com'},{label: 'VRBO(HomeAway)', name: 'VRBO'},{label:'Others', name: 'Others'}];

  const colorDrop = ['#dcdcdc','#a4bdfc','#4287f5','#7ae7bf','#46d6db','#53bf4b','#ffc107','#C8A2C8','#dbadff','#dc3545'];

  const handleSubmit = (data) => {
    const payload = {
      id:bookingData.id,
      rentalId: Number(rental),
      price: finalPrice,
      tax: 0,
      noOfAdults: adult,
      arrive: moment(arrival).format().slice(0,10),
      depart: moment(departure).format().slice(0,10),
      arriveUTC : moment(arrival).format().slice(0,10),
      departUTC : moment(departure).format().slice(0,10),
      noOfChildren: children || 0,
      source: source,
      checkInTime:checkIn,
      checkOutTime:checkOut,
      bookingType:bookingData.bookingType,
      noOfGuests: totalGuest,
      paymentStatus: paymentStatus,
      title : `${guestDetails.name}| ${paymentStatus}| Direct Booking`,
      nights: totalNights,
      color: color,
      status:"Booked",
      notes:notes,
      miniNotes:miniNotes,
      discountName:bookingDiscount.discountName,
      discountAmount:bookingDiscount.discountAmount,
      chargeAmount:bookingDiscount.chargeAmount,
      chargeName:bookingDiscount.chargeName,
    }

    if(moreInfo!==guestDetails.notes){
      const updatedData = {
        address:guestDetails.address,
        company:guestDetails.company,
        country:guestDetails.country,
        customerId:guestDetails.customerId,
        dob:guestDetails.dob,
        emailId:guestDetails.emailId,
        id:guestDetails.id,
        language:guestDetails.language,
        name:guestDetails.name,
        nationality:guestDetails.nationality,
        notes:moreInfo,
        phoneNo:guestDetails.phoneNo,
        postalCode:guestDetails.postalCode,
        secondaryEmailId: guestDetails.secondaryEmailId,
        state: guestDetails.state,
        street: guestDetails.street,
      };
      dispatch(editGuest(updatedData));
    }
    // console.log(payload,arrival,departure);
    dispatch(editBooking(payload));
  };

  const initialValues = {
    rental:bookingData?bookingData.rentalId:'',
    totalGuests: bookingData?bookingData.noOfAdults + bookingData.noOfChildren:1,
    adult: bookingData?bookingData.noOfAdults:1,
    children: bookingData?bookingData.noOfChildren:0,
    finalPrice: bookingData?bookingData.price:0.0,
    arrival: bookingData.arrive,
    checkIn: bookingData?bookingData.checkInTime:'',
    departure: bookingData.depart,
    nights:bookingData?bookingData.nights:0,
    checkOut: bookingData?bookingData.checkOutTime:'',
    source: bookingData?bookingData.source:'beehaz',
    color: bookingData?bookingData.color:'',
    paymentStatus:bookingData?bookingData.paymentStatus:'Payment pending',
    title:`${guestDetails.name} ¦ ${paymentStatus} ¦ Direct Booking`,
    notes: `${bookingData.notes? bookingData.notes:""}`
  };

  return (
      <div className="mt-3">
        <Loading loadingStatus={actionForFetchBooking.loading} />
        <Formik
            initialValues={initialValues}
            validationSchema={updateSchema}
            onSubmit={handleSubmit}
            validateOnChange={false}
            validateOnBlur={false}
        >
          {({ errors, handleChange,setFieldValue }) => (
              <>
                <Form>
                  <div className={styles.mainContainer}>
                    <div className={`col-lg-6 col-md-12 px-xs-1`}>
                      <div className="pb-2">
                        <div className={styles.subHeader}>{guestDetails.name}</div>
                        <div className={styles.defaultFont}>{guestDetails.emailId}</div>
                        <div className={styles.defaultFont}>{guestDetails.phoneNo}</div>
                      </div>
                    </div>
                    <div className={`col-lg-6 col-md-12`}>
                      <div className="pb-2">
                        <div className="mb-3 mt-sm-3 mt-md-0">
                          <div className={`${styles.smallHeader} font-weight-normal`}>More info
                            <InfoOutlinedIcon
                                fontSize="small"
                                color="inherit"
                                data-tip
                                data-for="moreInfo"
                                className={styles.toolTipIcon}
                            />
                            <ReactTooltip place="bottom" type="dark" id="moreInfo" effect="solid" className={styles.toolTip}>
                          <span>
                            Write more information specific to your guests here. These can be retrieved later if the guest makes another reservation.
                          </span>
                            </ReactTooltip>
                          </div>
                          <Field
                              className={`form-control ${styles.form_entry} w-100`}
                              type={"text"}
                              name={"moreInfo"}
                              onChange={(e)=>setMoreInfo(e.target.value)}
                              value={moreInfo}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {(bookingData.bookingType=== "weekly" || bookingData.bookingType=== "monthly") ? (
                    <div className={styles.mainContainer}>
                      <div className={`col ${styles.defaultFont}`}>{`Special fixed term reservation: ${bookingData.bookingType} pricing used.`}</div>
                    </div>
                  ):null}
                  <hr className="my-1" />
                  <div className={styles.mainContainer}>
                    <div className={`col-lg-6 col-md-12 px-xs-1`}>
                      <div className="pb-2">
                        <div className={styles.subHeader}> Booking </div>
                        <div className={styles.defaultFont}>{`Booking Number: ${new Date().getUTCFullYear()}${zeroPad(bookingData.id,4)}`}</div>
                      </div>
                      <Row className={`${styles.form_wrapper} m-0`} key={'selectRental'}>
                        <div className={styles.form_title}>{'Select rental: '}</div>
                        <Field
                            as={'select'}
                            name={'rental'}
                            value={rental}
                            onChange={(e)=> {
                              setRental(e.target.value);
                              setRentalDetail(findRentalDetail(e.target.value));
                            }}
                            className={errors.rental ? styles.form_entry_err : styles.form_entry}
                        >
                          {rentalSelector.map((opt) => (
                              <option key={opt.id} value={opt.id} >
                                {opt.name}
                              </option>
                          ))}
                        </Field>
                        <div className={errors.rental ? styles.form_error : styles.form_error_hidden}>{errors.rental}</div>
                      </Row>
                      <Row className={`${styles.form_wrapper} m-0`} key={'totalGuest'}>
                        <div className={styles.form_title}>{'Number of guests: '}</div>
                        <div className={styles.field_width}>
                          <Field
                              type={'number'}
                              name={'totalGuests'}
                              className={errors.totalGuests ? styles.form_entry_err : styles.form_entry}
                              value={totalGuest}
                              onChange={(e)=>setData(e.target.value)}
                              validate={(val)=>validate(val,"totalGuests")}
                          />
                          <div className={errors.totalGuests ? styles.form_error : styles.form_error_hidden}>
                            {errors.totalGuests}
                          </div>
                        </div>
                      </Row>
                      <Row className={`${styles.form_wrapper} m-0`} key={'adult'}>
                        <div className={`${styles.form_title}`}>{'Adults: '}</div>
                        <div className={styles.field_width}>
                          <Field
                              type={'number'}
                              name={'adult'}
                              disabled={true}
                              className={
                                errors.adult ? styles.form_entry_err : `${styles.form_entry}`
                              }
                              value={adult}
                              validate={(val)=>validate(val,"adult")}
                          />
                          <div className={errors.adult ? styles.form_error : styles.form_error_hidden}>
                            {errors.adult}
                          </div>
                        </div>
                      </Row>
                      <Row className={`${styles.form_wrapper} m-0`} key={'children'}>
                        <div className={`${styles.form_title}`}>{'Children: '}</div>
                        <div className={styles.field_width}>
                          <Field
                              type={'number'}
                              name={'children'}
                              value={children}
                              onChange={(e)=> {
                                setChildren(e.target.value)
                                setAdult(totalGuest-e.target.value)
                              }}
                              className={
                                errors.children ? styles.form_entry_err : `${styles.form_entry}`
                              }
                              validate={(val)=>validate(val,"children")}
                          />
                          <div className={errors.children ? styles.form_error : styles.form_error_hidden}>
                            {errors.children}
                          </div>
                        </div>
                      </Row>
                      <Row className={`${styles.form_wrapper} m-0`} key={'arrival'}>
                      <Col className="p-sm-0 pl-0 col-7 d-sm-flex align-items-center">
                          <div className={`${styles.form_title_half} ${styles.checkTitleWidth}`}>Check-in: </div>
                          <div className={styles.calendarWidth}>
                            <CustomDate
                                name={'arrival'}
                                id={'editBookingArrival'}
                                value={arrival}
                                hasError={errors.arrival}
                                handleChange={(date)=>{
                                  if(bookingData.bookingType==="monthly" || bookingData.bookingType==="weekly"){
                                    toast.warn(`You are modifying a fixed-term reservation. Note the prices will be recalculated using this special rates and not your usual rates.`
                                    ,{
                                      style:{
                                          color:'#484848'
                                      }
                                    });
                                  }
                                  setArrival(date);
                                  setFieldValue('arrival',date);
                                }}
                                max={(departure)? departure: new Date(`${moment().add(numberOf,allowBookingFor === "months" ? 'M' : 'y').format('YYYY-MM-DD')}`)}
                            />
                            <div className={errors.arrival ? styles.form_error : styles.form_error_hidden}>
                              {errors.arrival}
                            </div>
                          </div>
                        </Col>
                        <Col className={`p-0 col-5 mr-0 d-sm-flex align-items-center justify-content-md-end justify-content-lg-center ${styles.checkWrap}`}>
                          <div className={`${styles.form_title_half} ${styles.atWidth}`}>from : </div>
                          <Field
                              type={'time'}
                              name={'checkIn'}
                              id={'checkInInp'}
                              className={`${styles.form_entry_half} ${styles.atEntryWidth}`}
                              onChange={(e)=>setCheckIn(e.target.value)}
                              value={checkIn}
                          />
                        </Col>
                      </Row>
                      <Row className={`${styles.form_wrapper} m-0`} key={'departure'}>
                        <Col className="p-sm-0 pl-0 col-7 d-sm-flex align-items-center">
                          <div className={`${styles.form_title_half} ${styles.checkTitleWidth}`}>Check-out: </div>
                          <div className={styles.calendarWidth}>
                            <CustomDate
                                name={'departure'}
                                id={'editBookingDeparture'}
                                min={arrival}
                                handleChange={(date)=>{
                                  if(bookingData.bookingType==="monthly" || bookingData.bookingType==="weekly"){
                                    toast.warn(`You are modifying a fixed-term reservation. Note the prices will be recalculated using this special rates and not your usual rates.`
                                    ,{
                                      style:{
                                          color:'#484848'
                                      }
                                    });
                                  }
                                  setDeparture(date);
                                  setFieldValue('departure',date);
                                }}
                                value={departure}
                                max={new Date(`${moment().add(numberOf,allowBookingFor === "months" ? 'M' : 'y').format('YYYY-MM-DD')}`)}
                            />
                            <div className={errors.departure ? styles.form_error : styles.form_error_hidden}>
                              {errors.departure}
                            </div>
                          </div>
                        </Col>
                        <Col className={`p-0 col-5 mr-0 d-sm-flex align-items-center justify-content-md-end justify-content-lg-center ${styles.checkWrap}`}>
                          <div className={`${styles.form_title_half} ${styles.atWidth}`}>until: </div>
                          <Field
                              type={'time'}
                              name={'checkOut'}
                              id={'checkOutInp'}
                              className={`${styles.form_entry_half} ${styles.atEntryWidth}`}
                              onChange={(e)=>setCheckOut(e.target.value)}
                              value={checkOut}
                          />
                        </Col>
                      </Row>
                      <Row className={`${styles.form_wrapper} m-0`} key={'nights'}>
                        <div className={styles.form_title}>Total nights: </div>
                        <Field
                            type={'text'}
                            name={'nights'}
                            value={totalNights}
                            disabled={true}
                            className={errors.nights ? styles.form_entry_err : styles.form_entry}
                        />
                      </Row>

                      <Row className={`${styles.form_wrapper} m-0`} key={'source'}>
                        <div className={styles.form_title}>{'Source: '}</div>
                        <Field
                            as={'select'}
                            name={'source'}
                            value={source}
                            onChange={(e)=>setSource(e.target.value)}
                            className={errors.source ? styles.form_entry_err : styles.form_entry}
                        >
                          {channels.map((opt,index) => (
                              <option key={index} value={opt.name} >
                                {opt.label}
                              </option>
                          ))}
                        </Field>
                      </Row>
                      <hr className="my-1" />
                      <div className="mb-2">
                        <div className={styles.subHeader}>Payment
                          <InfoOutlinedIcon
                              fontSize="small"
                              color="inherit"
                              data-tip
                              data-for="paymentIcon"
                              className={styles.toolTipIcon}
                          />
                          <ReactTooltip place="bottom" type="dark" id="paymentIcon" effect="solid" className={styles.toolTip}>
                          <span>
                            The total includes rates, extra guest fees, fees, and taxes. Any discount or charge you have set
                            are also considered here. You can change this total under the Payments tab.
                          </span>
                          </ReactTooltip>
                        </div>
                      </div>
                      <Row className={`${styles.form_wrapper} d-flex justify-content-between m-0`} key={'finalPrice'}>
                        <div className="d-flex align-items-center ">
                          <div className={`${styles.form_title_half} ${styles.paymentTitleWidth}`}>Total: </div>
                          <div className={styles.totalEntryWidth}>
                            <Field
                                type={'number'}
                                name={'finalPrice'}
                                className={
                                  errors.finalPrice
                                      ? styles.form_entry_err
                                      : `${styles.form_entry_half} ${styles.totalEntryWidth}`
                                }
                                value={Number(finalPrice).toFixed(2)}
                                disabled={true}
                            />
                            <div className={errors.finalPrice ? styles.form_error : styles.form_error_hidden}>
                              {errors.finalPrice}
                            </div>
                          </div>
                        </div>
                        <div className={`d-flex align-items-center mt-3 mt-md-0 ${styles.paymentStatus}`}>
                          <div className={`${styles.form_title_half} ${styles.paymentTitleWidth}`}>Status: </div>
                          <Field
                              as={'select'}
                              name={'paymentStatus'}
                              value={paymentStatus}
                              onChange={(e)=> {
                                setPaymentStatus(e.target.value);
                              }}
                              className={
                                errors.paymentStatus
                                    ? styles.form_entry_err
                                    : `${styles.form_entry_half} ${styles.statusEntryWidth}`
                              }
                          >
                            {paymentOpt.map((opt) => (
                                <option value={opt} key={opt}>
                                  {opt}
                                </option>
                            ))}
                          </Field>
                        </div>
                      </Row>
                      <hr className="my-1" />
                      <div className="mb-2">
                        <div className={styles.subHeader}>Colour</div>
                      </div>
                      <Row className={`${styles.form_wrapper} m-0`} key={'colour'}>
                        <div className={styles.form_title}>Choose colour: </div>
                        <div className={styles.containerColor}>
                          {colorDrop.map((item)=>(
                              <div
                                  className={`${styles.containerBox} ${(color===item)?` active1`:``}`}
                                  style={{background:`${item}`}}
                                  onClick={()=>setColor(item)}
                                  key={item}
                              />
                          ))}
                          <Field
                              type={'color'}
                              name={'color'}
                              value={color}
                              onChange={(e)=>setColor(e.target.value)}
                              className={styles.form_entry_color}
                          />
                        </div>
                      </Row>
                    </div>
                    <div className={`col-lg-6 col-md-12`}>
                      <hr className="my-1 d-lg-none" />
                      <div>
                        <div className="mt-sm-3 mt-md-0">
                          <div className={`${styles.subHeader} font-weight-normal`}>Mini notes
                            <InfoOutlinedIcon
                                fontSize="small"
                                color="inherit"
                                data-tip
                                data-for="miniNotes"
                                className={styles.toolTipIcon}
                            />
                            <ReactTooltip place="bottom" type="dark" id="miniNotes" effect="solid" className={styles.toolTip}>
                          <span>
                            Write a short note to be displayed in the booking information summary box that appears when you click on a reservation in the calendar.
                          </span>
                            </ReactTooltip>
                          </div>
                        </div>
                        <Field
                            className={`form-control ${styles.form_entry} w-100`}
                            name="miniNotes"
                            type={"text"}
                            value={miniNotes}
                            onChange={(e)=>setMiniNotes(e.target.value)}
                        />
                      </div><br/>
                      <div>
                        <div className="mt-sm-3 mt-md-0">
                          <div className={styles.subHeader}>Notes
                            <InfoOutlinedIcon
                                fontSize="small"
                                color="inherit"
                                data-tip
                                data-for="notesIcon"
                                className={styles.toolTipIcon}
                            />
                            <ReactTooltip place="bottom" type="dark" id="notesIcon" effect="solid" className={styles.toolTip}>
                          <span>
                            Write longer notes specific to this booking here.
                          </span>
                            </ReactTooltip>
                          </div>
                        </div>
                        <textarea
                            className={`form-control ${styles.text_area} `}
                            rows="15"
                            name="notes"
                            onChange={(e)=>setNotes(e.target.value)}
                            value={notes}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={`d-flex justify-content-center ${styles.button_group}`}>
                    <div>
                      <button type="submit" className={styles.settings_save}>
                        Save changes
                      </button>
                    </div>
                  </div>
                </Form>
              </>
          )}
        </Formik>
      </div>
  );
};

export default BookingDetails;
