import React, { useEffect, useState } from 'react';
import {useSelector,useDispatch} from "react-redux";
import { Row, Col } from 'react-bootstrap';
import * as yup from 'yup';
import { Formik, Form, Field } from 'formik';
import moment from 'moment';
import Select from "react-select";
import PhoneInput from 'react-phone-input-2';
import countryList from "react-select-country-list";
import {toast} from "react-toastify";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ReactTooltip from "react-tooltip";
import {chargeCalculation,resetCharges} from '../../../../../../general_redux/calendar/actions';
import {isoCountries} from "../../../../../../config/data/iso2Country";
import CustomDate from '../../../../../../components/CustomDate';
import styles from './addnewbooking.module.css';

function BookingForm(props) {
  const {guestList,guestDetail,rentals,handleSave,userId } = props;
  const [guestLimit,setGuestLimit]=useState();
  const bookingWindow=useSelector(({generalSetting})=>generalSetting && generalSetting.setting && generalSetting.setting.allowBooking);
  const userCountry= useSelector(({generalSetting})=>generalSetting && generalSetting.setting && generalSetting.setting.country);

  const dispatch=useDispatch();
  //=============================================
  // SCHEMA REQUIREMENT FOR FORM VALIDATION
  //=============================================

  const SignupSchemaBooking = yup.object().shape({
    totalGuests: yup.number(),
    adult: yup.number().min(1, 'At least 1 adult is required').required('At least 1 adult is required'),
    children: yup.number().min(0, 'Cannot be a negative number'),
    arrival: yup.date().nullable().max(`${moment().add(2,'years').format('YYYY-MM-DD')}`,"Booking not allowed after 2 years").required('Arrival date is required'),
    departure: yup.date().nullable().max(`${moment().add(2,'years').format('YYYY-MM-DD')}`,"Booking not allowed after 2 years").required('Departure date is required'),
    notesBooking:yup.string().max(50,'Maximum 50 characters are allowed!'),
    miniNotes: yup.string().max(27,'Maximum 27 characters are allowed!'),
  });

  const signUpSchema=yup.object().shape({
    totalGuests: yup.number(),
    adult: yup.number().min(1, 'At least 1 adult is required').required('At least 1 adult is required'),
    children: yup.number().min(0, 'Cannot be a negative number'),
    arrival: yup.date().required('Arrival date is required').nullable(),
    departure: yup.date().required('Departure date is required').nullable(),
    notesBooking:yup.string().max(50,'Maximum 50 characters are allowed!'),
    miniNotes: yup.string().max(27,'Maximum 27 characters are allowed!'),
  });

  //=============================================
  // STATE FOR BOOKING DETAILS
  //=============================================

  const [values, setValues] = useState({});

  const [rental, setRental] = useState('');
  const [arrival,setArrival] = useState('');
  const [departure,setDeparture] = useState('');
  const [adult,setAdult] = useState(1);
  const [children,setChildren] = useState(0);
  const [totalGuest, setTotalGuest] = useState(adult+children);
  const [taxes, setTaxes] = useState(0);
  const [extraCharge,setExtraCharge]=useState(0);
  const [fees,setFees]=useState(0);
  const [finalPrice, setFinalPrice] = useState(0);

  const [price,setPrice]=useState(0);
  const [basePrice,setBasePrice]=useState(0);
  const [checkIn,setCheckIn]=useState('');
  const [checkOut,setCheckOut]=useState('');
  const [notesBooking,setNotesBooking]=useState('');
  const [miniNotes,setMiniNotes]=useState('');
  const [srcVal,setSrcVal]=useState('beehaz');

  const [guestName,setGuestName]=useState("");
  const [company,setCompany]=useState("");
  const [primaryEmail,setPrimaryEmail]=useState("");
  const [secondaryEmail,setSecondaryEmail]=useState("");
  const [phoneNumber,setPhoneNumber]=useState("");
  const [country,setCountry]=useState([]);
  const [streetAddress,setStreetAddress]=useState("");
  const [postalCode,setPostalCode]=useState("");
  const [stateProvince,setStateProvince]=useState("");
  const [addressDetails,setAddressDetail]=useState("")
  const [nationality,setNationality]=useState("");
  const [language,setLanguage]=useState("");
  const [birthday,setBirthDay]=useState("");
  const [notes,setNotes]=useState("");
  const [color,setColor]=useState('#d3d3d3');

  const [defVal,setDefVal]=useState("");
  const paymentOpt = ["Payment Pending","Partially Paid","Paid"];
  const colorDrop = ['#dcdcdc','#a4bdfc','#4287f5','#7ae7bf','#46d6db','#53bf4b','#ffc107','#C8A2C8','#dbadff','#dc3545'];
  // ==================================================
  // ==========PRICE CALCULATION STARTS HERE===========
  // ==================================================
  const ratesList = useSelector(({rates})=>rates && rates.rateSettings);
  const propertyList = useSelector(({rentals})=>rentals && rentals.rentals);
  const currentCharges=useSelector(({calendar})=>calendar && calendar.currentCharges);
  const {numberOf, allowBookingFor} = useSelector(({generalSetting}) => generalSetting && generalSetting.setting);
  const [selectedRate, setSelectedRate] = useState();
  const [selectedProperty, setSelectedProperty] = useState();
  const [totalNights, setTotalNights] = useState();

  const channels=['Beehaz','Agoda','Airbnb','Booking.com','VRBO(HomeAway)','Other'];

  useEffect(()=>{
    return()=>{
      dispatch(resetCharges());
    }
  },[])

  const findCountry=(val)=>{
    if(val){
      for (const key in isoCountries){
        if(isoCountries[key]===val){
          return key.toLocaleLowerCase();
        }
      }
    }else{
      return "us";
    }
  }
  //attempt to relocate : failed
  const [userCode,setUserCode]=useState(()=>findCountry(userCountry));

  useEffect( () => {
    if (rental && ratesList) {
      // eslint-disable-next-line array-callback-return
      propertyList.map((p)=>{
        if(p.id===Number(rental)){
          setGuestLimit(p.maxGuests);
          setCheckIn(p.checkInTime);
          setCheckOut(p.checkOutTime);
        }
      });
    }
  }, [rental])


  useEffect( () => {
    if (departure && arrival) {
      setTotalNights(moment(departure,"YYYY-MM-DD").diff(moment(arrival,"YYYY-MM-DD"),'day'));
    }
    if(rental && arrival && departure && totalGuest){
      dispatch(chargeCalculation({
        arrive: moment(arrival).format().slice(0,10),
        depart: moment(departure).format().slice(0,10),
        noOfGuests:Number(totalGuest),
        rentalId:Number(rental),
        extraCharge:extraCharge,
        discount:taxes
      }))
    }
  },[departure, arrival, rental, totalGuest, extraCharge, taxes, dispatch]);

  useEffect(()=>{
    if(currentCharges){
      setPrice(Number(currentCharges.total).toFixed(2));
      setFinalPrice(Number(currentCharges.total).toFixed(2));
      setBasePrice(Number((currentCharges.daily_rate + currentCharges.extra_guest_fees).toFixed(2)));
      setFees(Number(currentCharges.fees+currentCharges.taxes).toFixed(2));
    }
    if(currentCharges && !currentCharges.minStay){
      toast.warn(`Minimum stay requirements set for this rental: ${currentCharges.minStayConstrain} nights!!`
          ,{
            style:{
              color:'#484848'
            }
          });
    }
  },[currentCharges])

  useEffect(()=>{
    const tmp=Number(totalGuest)-Number(children);
    if(tmp<1){
      setAdult(1);
      setChildren(0);
    }else{
      setAdult(tmp);
    }
  },[totalGuest,children])
  // ==================================================
  // ==========PRICE CALCULATION ENDS HERE=============
  // ==================================================

  useEffect(()=>{
    if(guestDetail && guestDetail!==""){
      const guestData= guestList.filter((row)=>(row.name===guestDetail.label))
      setDefVal(guestData[0]);
      setGuestName(guestData[0].name);
      setPrimaryEmail(guestData[0].emailId);
      if(guestData[0].dob && (guestData[0].dob!=="Invalid da" && guestData[0].dob!=="" && guestData[0].dob!==null && guestData[0].dob!=="[NULL]")){
        setBirthDay(new Date(guestData[0].dob));
      }else{
        setBirthDay("");
      }
    }else {
      setDefVal("");
      setGuestName("");
      setCompany("");
      setPrimaryEmail("");
      setSecondaryEmail("");
      setCountry([]);
      setPhoneNumber("");
      setStreetAddress("");
      setPostalCode("");
      setStateProvince("");
      setAddressDetail("");
      setNationality("");
      setLanguage("");
      setNotes("");
      setBirthDay("");
    }
  },[guestDetail])
  //=============================================
  // FORM INITIAL VALUE
  //=============================================

  const initVal = () => {
    return {
      rental:'',
      totalGuest:1,
      adult: 1,
      children: 0,
      arrival:'',
      departure:'',
      price: 0,
      taxes:0,
      finalPrice:0,
      phoneNo:"",
      paymentStatus: 'Payment Pending',
    };
  };

  
  const handleFocusChange = ()=>{
       if(taxes==='0')
       {
           setTaxes('');
       }
  }

  const handleFocusChanges = ()=>{
    if(extraCharge==='0')
    {
        setExtraCharge('');
    }
}

  const validate = (field,value) => {
    let errorMessage;
    if(field==="emailId"){
      if(!value || value===""){
        errorMessage = 'Email address is required';
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        errorMessage = 'Invalid email address';
      }
    }else if(field==="name"){
      if(!value || value===""){
        errorMessage = 'Guest name is required';
      }
    }else if(field==="rental"){
      if(value===""){
        errorMessage= 'Rental is required';
      }
    }else if(field==="totalGuests"){
      if(!totalGuest || totalGuest <= 0){
        errorMessage = 'At least 1 guest is required';
      } else if(totalGuest >guestLimit){
        errorMessage=`Only ${guestLimit} guests are allowed!`;
      }
    }else if(field==="price") {
      if (Number(basePrice) < 0) {
        errorMessage = "Please enter valid price";
      }
    }else if(field==="finalPrice"){
      if(Number(finalPrice)<0){
        errorMessage = "Please enter valid price";
      }
    }
    return errorMessage;
  };

  const guestForm = [
    { title: 'Name: ', name: 'name' , value: guestName,type:"text"},
    { title: 'Company: ', name: 'company' ,value:company,type:"text"},
    { title: 'Email address: ', name: 'emailId',value:primaryEmail,type:"text" },
    { title: 'Secondary email: ', name: 'secondaryEmailId',value:secondaryEmail,type:"text" },
    { title: 'Phone: ', name: 'phoneNo' ,value:phoneNumber},
    { title: 'Country: ', name: 'country',value:country },
    { title: 'Street and nr. : ', name: 'address1',value:streetAddress,type:"text"},
    { title: 'Postal code: ', name: 'postalCode',value:postalCode,type:"text" },
    { title: 'State/Province: ', name: 'state',value:stateProvince,type:"text" },
    { title: 'Address details: ', name: 'address',value: addressDetails,type:"text"},
    { title: 'Nationality: ', name: 'nationality',value:nationality,type:"text" },
    { title: 'Language: ', name: 'language',value:language,type:"text" },
    { title: 'Birthday',name:'dob',value:birthday,type:'date'},
    { title: 'More info: ', name: 'notes',value:notes,isIcon:true, type:'text',iconContent:" Write more information or notes specific to your guest here. These can be retrieved later if the guest makes another reservation." },
  ];
  //===================================
  // HANDLE SUBMIT
  //===================================

  function handleSubmit() {
    let guestData={};
    const check=(defVal==="");
    const payload = {
      rentalId: Number(rental),
      price: Number(Number(finalPrice).toFixed(2)),
      noOfAdults: adult,
      arrive: moment(arrival).format().slice(0,10),
      depart: moment(departure).format().slice(0,10),
      noOfChildren: children || 0,
      source: srcVal,
      checkInTime:checkIn,
      checkOutTime:checkOut,
      arriveUTC : moment(arrival).format().slice(0,10),
      departUTC : moment(departure).format().slice(0,10),
      bookingType:"booking",
      noOfGuests: totalGuest,
      paymentStatus: values.paymentStatus,
      title : `${(defVal)?defVal.name:values.name} | ${values.paymentStatus} | Direct Booking`,
      nights: totalNights,
      color: color,
      discountName: "Discount",
      discountAmount:Number(Number(taxes).toFixed(2)),
      chargeName:"Extra charge",
      chargeAmount:Number(Number(extraCharge).toFixed(2)),
      fees:currentCharges.fees,
      tax:currentCharges.taxes,
      extraGuestFee:currentCharges.extra_guest_fees,
      baseRate:currentCharges.daily_rate,
      miniNotes:miniNotes,
      notes:notesBooking,
    }
    if(!check){
      guestData={
        guestId:defVal.id
      }
    }else{
      guestData={
        name: values.name,
        emailId: values.emailId,
        phoneNo: phoneNumber,
        customerId: userId,
        secondaryEmailId: (values.secondaryEmailId)?values.secondaryEmailId:"",
        country: (country.length!==0)?country.label:"",
        address: (values.address)?values.address:"",
        postalCode:( values.postalCode)? values.postalCode:"",
        state: (values.stateProvince)?values.stateProvince:"",
        nationality: (values.nationality)?values.nationality:"",
        language: (values.language)?values.language:"",
        notes: (values.notes)?values.notes:"",
        dob:birthday?moment(birthday).format().slice(0,10):"",
      }
    }
    // console.log(payload,guestData);
    handleSave(payload,guestData,check);
  }

  return (
      <>
        <Formik
            initialValues={initVal()}
            validationSchema={bookingWindow?SignupSchemaBooking:signUpSchema}
            onSubmit={handleSubmit}
            validateOnChange={false}
            validateOnBlur={false}
        >
          {({ values, errors,setFieldValue }) => (
              <div>
                <Form>
                  <Row>
                    <Col md={6} className={styles.wrapper}>
                      <h3 className={styles.header}>Booking Details</h3>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"selectRental"}>
                        <div className={`d-flex ${styles.form_title}`}>{"Select rental: "}</div>
                        <div className={`w-100`}>
                          <Field
                              as={"select"}
                              name={"rental"}
                              className={(errors.rental)?styles.form_entry_err:styles.form_entry}
                              validate={(value)=>validate('rental',value)}
                          >
                            <option value={""} label={"---Select Rental---"}/>
                            {rentals.map((opt)=>(
                                <option key={opt.id} value={opt.id}>{opt.name}</option>
                            ))}
                          </Field>
                          <div className={errors.rental ? styles.form_error : styles.form_error_hidden}>
                            {errors.rental}
                          </div>
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"totalGuest"}>
                        <div className={`d-flex ${styles.form_title}`}>{"Number of guests: "}</div>
                        <div className={`w-100`}>
                          <Field
                              type={"number"}
                              name={"totalGuests"}
                              className = {(errors.totalGuests)?styles.form_entry_err:styles.form_entry}
                              value={totalGuest}
                              onChange={(e)=>setTotalGuest(e.target.value)}
                              validate={(value)=>validate('totalGuests',value)}
                              min={1}
                          />
                          <div className={errors.totalGuests ? styles.form_error : styles.form_error_hidden}>
                            {errors.totalGuest}
                          </div>
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"adult"}>
                        <div className={`d-flex ${styles.form_title}`}>{"Adults: "}</div>
                        <Field
                            type={"number"}
                            name={"adult"}
                            value={adult}
                            disabled={true}
                            className={(errors.adult)?styles.form_entry_err:styles.form_entry}
                            min={1}
                        />
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"children"}>
                        <div className={`d-flex ${styles.form_title}`}>{"Children: "}</div>
                        <Field
                            type={"number"}
                            name={"children"}
                            value={children}
                            onChange={(e)=>setChildren(e.target.value)}
                            className={(errors.children)?styles.form_entry_err:styles.form_entry}
                            min={0}
                        />
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"arrival"}>
                        <div className={`d-flex ${styles.form_title}`}>{"Arrival date: "}</div>
                        <div className={`w-100`}>
                          <CustomDate
                              id={"arrival"}
                              name={"arrival"}
                              handleChange={(date)=>{
                                setFieldValue('arrival', date);
                                setArrival(date)
                              }}
                              value={arrival}
                              hasError={errors.arrival}
                              max={(departure)?departure:new Date(`${moment().add(numberOf,allowBookingFor === "months" ? 'M' : 'y').format('YYYY-MM-DD')}`)}
                          />
                          <div className={errors.arrival ? styles.form_error : styles.form_error_hidden}>
                            {errors.arrival}
                          </div>
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"departure"}>
                        <div className={`d-flex ${styles.form_title}`}>{"Departure date: "}</div>
                        <div className={`w-100`}>
                          <CustomDate
                              id={"departure"}
                              name={"departure"}
                              handleChange={(date)=>{
                                setFieldValue('departure',date);
                                setDeparture(date)
                              }}
                              value={departure}
                              min={arrival}
                              hasError={errors.departure}
                              max={new Date(`${moment().add(numberOf,allowBookingFor === "months" ? 'M' : 'y').format('YYYY-MM-DD')}`)}
                          />
                          <div className={errors.departure ? styles.form_error : styles.form_error_hidden}>
                            {errors.departure}
                          </div>
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"price"}>
                        <div className={`d-flex ${styles.form_title}`}>
                          <div>{"Price: "}
                            <InfoOutlinedIcon
                              fontSize="small"
                              color="inherit"
                              data-tip
                              data-for="stripIcon"
                              className={styles.toolTipIcon}
                          /></div>
                          <ReactTooltip place="bottom" type="dark" id="stripIcon" effect="solid" className={styles.toolTip}>
                            <span>
                              The price takes into account the rates set for these dates and extra guest fees. These can be
                              managed by clicking on the Rates icon. You can manually overwrite the total for this booking by
                              editing the booking later.
                            </span>
                          </ReactTooltip>
                        </div>
                        <div className={`w-100`}>
                          <Field
                              type={"number"}
                              name={"price"}
                              className={(errors.price)?styles.form_entry_err: styles.form_entry}
                              value={basePrice.toFixed(2)}
                              // onChange={(e)=>setPrice(e.target.value)}
                              validate={(value)=>validate('price',value)}
                              disabled={true}
                          />
                          <div className={errors.price ? styles.form_error : styles.form_error_hidden}>
                            {errors.price}
                          </div>
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"taxes"}>
                        <div className={`d-flex ${styles.form_title}`}>
                          {'Discount:'}
                        </div>
                        <Field
                            type={"number"}
                            name={"taxes"}
                            className={(errors.taxes)?styles.form_entry_err: styles.form_entry}
                            value={taxes}
    
                            onChange={(e)=>setTaxes(e.target.value)}
                        />
                        <div className={errors.taxes ? styles.form_error : styles.form_error_hidden}>
                          {errors.taxes}
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"extraCharge"}>
                        <div className={`d-flex ${styles.form_title}`}>
                          {'Extra charge:'}
                        </div>
                        <Field
                            type={"number"}
                            name={"extraCharge"}
                            className={(errors.extraCharge)?styles.form_entry_err: styles.form_entry}
                            value={extraCharge}
                            onChange={(e)=>setExtraCharge(e.target.value)}
                        />
                        <div className={errors.extraCharge ? styles.form_error : styles.form_error_hidden}>
                          {errors.extraCharge}
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"fees"}>
                        <div className={`d-flex ${styles.form_title}`}>
                          {'Fees and taxes:'}
                        </div>
                        <Field
                            type={"number"}
                            name={"fees"}
                            className={(errors.fees)?styles.form_entry_err: styles.form_entry}
                            value={fees}
                            disabled={true}
                        />
                        <div className={errors.fees ? styles.form_error : styles.form_error_hidden}>
                          {errors.fees}
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"finalPrice"}>
                        <div className={`d-flex ${styles.form_title}`}>{"Final price: "}
                          <InfoOutlinedIcon
                              fontSize="small"
                              color="inherit"
                              data-tip
                              data-for="finalPriceIcon"
                              className={styles.toolTipIcon}
                          />
                          <ReactTooltip place="bottom" type="dark" id="finalPriceIcon" effect="solid" className={styles.toolTip}>
                            <span>
                             Considers rates, extra guest fees, fees, and taxes. These can be managed by clicking on the
                             Rates icon. Any discount or charge you have set above are also considered here. You can
                             manually overwrite the total for this booking by editing the booking later
                            </span>
                          </ReactTooltip>
                        </div>
                        <div className={`w-100`}>
                          <Field
                              type={"number"}
                              name={"finalPrice"}
                              className={(errors.finalPrice)?styles.form_entry_err: styles.form_entry}
                              value={finalPrice}
                              validate={(value)=>validate('finalPrice',value)}
                              disabled={true}
                          />
                          <div className={errors.finalPrice ? styles.form_error : styles.form_error_hidden}>
                            {errors.finalPrice}
                          </div>
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"paymentStatus"}>
                        <div className={`d-flex ${styles.form_title}`}>{"Payment status: "}</div>
                        <Field
                            as={"select"}
                            name={"paymentStatus"}
                            className={(errors.paymentStatus)?styles.form_entry_err: styles.form_entry}
                        >
                          <option key={""} value={""} disabled={true} label={"--Select Status--"}/>
                          {paymentOpt.map((opt)=>(
                              <option value={opt} key={opt}>{opt}</option>
                          ))}
                        </Field>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"source:"}>
                        <div className={`d-flex ${styles.form_title}`}>{"Source: "}</div>
                        <Field
                            name={"source"}
                            as={'select'}
                            className={(errors.source)?styles.form_entry_err: styles.form_entry}
                            value={srcVal}
                            onChange={(e)=>setSrcVal(e.target.value)}
                        >
                          {channels.map((opt,index)=>(
                              <option value={opt} key={index}>{opt}</option>
                          ))}
                        </Field>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"checkintime"}>
                        <div className={`d-flex ${styles.form_title}`}>{"Check-in time: "}</div>
                        <Field
                            type={"time"}
                            name={"check-in"}
                            id={"checkInInp"}
                            className={styles.form_entry}
                            value={checkIn}
                            onChange={(e)=>(setCheckIn(e.target.value))}
                        />
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"checkouttime"}>
                        <div className={`d-flex ${styles.form_title}`}>{"Check-out time: "}</div>
                        <Field
                            type={"time"}
                            name={"check-out"}
                            id={"checkOutInp"}
                            className={styles.form_entry}
                            value={checkOut}
                            onChange={(e)=>(setCheckOut(e.target.value))}
                        />
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"notesBooking"}>
                        <div className={`d-flex ${styles.form_title}`}>{"Notes:"}
                          <InfoOutlinedIcon
                              fontSize="small"
                              color="inherit"
                              data-tip
                              data-for="notesBooking"
                              className={styles.toolTipIcon}
                          />
                          <ReactTooltip place="bottom" type="dark" id="notesBooking" effect="solid" className={styles.toolTip}>
                            <span>
                              Write longer notes specific to this booking here.
                            </span>
                          </ReactTooltip>
                        </div>
                        <Field
                            type={"text"}
                            name={"notes"}
                            id={"notesBooking"}
                            className={styles.form_entry}
                            value={notesBooking}
                            onChange={(e)=>(setNotesBooking(e.target.value))}
                        />
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"miniNotes"}>
                        <div className={`d-flex ${styles.form_title}`}>{"Mini notes:"}
                          <InfoOutlinedIcon
                              fontSize="small"
                              color="inherit"
                              data-tip
                              data-for="miniNotes"
                              className={styles.toolTipIcon}
                          />
                          <ReactTooltip place="bottom" type="dark" id="miniNotes" effect="solid" className={styles.toolTip}>
                            <span>
                              Write a short note to be displayed in the booking information summary box that appears when
                              you click on a reservation in the calendar.
                            </span>
                          </ReactTooltip>
                        </div>
                        <Field
                            type={"text"}
                            name={"miniNotes"}
                            id={"miniNotes"}
                            className={styles.form_entry}
                            value={miniNotes}
                            onChange={(e)=>(setMiniNotes(e.target.value))}
                        />
                      </div>
                    </Col>
                    <Col s={12} md={6} className={styles.wrapper}>
                      <h3 className={styles.header}>Guest Details</h3>
                      {guestForm.map((form) => {
                        const err = errors[form.name];
                        return (
                            <div className={`d-flex ${styles.form_wrapper}`} key={form.title}>
                              {form.isIcon ? (
                                  <>
                                    <div className={`d-flex ${styles.form_title}`}>{form.title}
                                      <InfoOutlinedIcon
                                          fontSize="small"
                                          color="inherit"
                                          data-tip
                                          data-for={`${form.title}-icon`}
                                          className={styles.toolTipIcon}
                                      />
                                      <ReactTooltip place="bottom" type="dark" id={`${form.title}-icon`} effect="solid" className={styles.toolTip}>
                                    <span>
                                      {form.iconContent}
                                    </span>
                                      </ReactTooltip>
                                    </div>
                                  </>
                              ):(
                                  <div className={`d-flex ${styles.form_title}`}>{form.title}</div>
                              )}
                              {(guestDetail && guestDetail!=="")? (
                                      (form.name==="dob" )?(
                                          <CustomDate
                                            id={"dob"}
                                            name={"dob"}
                                            readOnly={true}
                                            handleChange={(date)=>setBirthDay(date)}
                                            value={birthday}
                                            className={styles.form_entry}
                                            max={(departure)?departure:new Date(`${(new Date().getUTCFullYear())+3}-12-31`)}
                                          />
                                      ):(
                                          <Field
                                              id={form.name}
                                              readOnly={true}
                                              type={form.type}
                                              value={defVal[form.name]}
                                              className={styles.form_entry}
                                          />
                                      )
                                  ):
                                  (<>
                                        {(form.name==="country")?
                                            (<>
                                              <Select
                                                  id="contrySelect"
                                                  options={countryList().getData()}
                                                  isClearable={true}
                                                  value={country}
                                                  className={styles.form_entry_select}
                                                  onChange={(val)=>setCountry(val)}
                                              />
                                            </>):(
                                                (form.name==="phoneNo")?(
                                                    <div className="p-0 m-0 w-100">
                                                      <PhoneInput
                                                          name="phoneNo"
                                                          country={userCode}
                                                          value={phoneNumber}
                                                          onChange={(value, data, event, formattedValue) =>
                                                          {
                                                            const rawVal=value.slice(data.dialCode.length);
                                                            setPhoneNumber(`+${data.dialCode} ${rawVal}`);
                                                          }}
                                                          inputStyle={{width: '100%'}}
                                                          countryCodeEditable={false}
                                                          enableSearch={true}
                                                          disableSearchIcon={true}
                                                      />
                                                    </div>
                                                ):((form.name==="dob")?(
                                                    <div className={`w-100`}>
                                                      <CustomDate
                                                        id={"dob"}
                                                        name={"dob"}
                                                        handleChange={(date)=>setBirthDay(date)}
                                                        value={birthday}
                                                        max={(departure)?departure:new Date(`${(new Date().getUTCFullYear())+3}-12-31`)}
                                                      />
                                                    </div>
                                                ):(
                                                    <div className={`w-100`}>
                                                      <Field
                                                          id={form.name}
                                                          name={form.name}
                                                          type={form.type}
                                                          className={err ? styles.form_entry_err : styles.form_entry}
                                                          value={form.value}
                                                          validate={(value)=>validate(form.name,value)}
                                                      />
                                                      <div className={err ? styles.form_error : styles.form_error_hidden}>{errors[form.name]}</div>
                                                    </div>
                                                ))
                                            )}
                                      </>
                                  )}
                            </div>
                        );
                      })}
                      <Row className={`pl-3`}>
                        <div className={`d-flex ${styles.form_title}`}>Choose colour: </div>
                        <div className={styles.containerColor}>
                          {colorDrop.map((item)=>(
                              <div
                                  className={`${styles.containerBox} ${(color===item)?` active1`:``}`}
                                  style={{background:`${item}`}}
                                  onClick={()=>setColor(item)}
                                  key={item}
                              />
                          ))}
                          <Field
                              type={'color'}
                              name={'color'}
                              value={color}
                              onChange={(e)=>setColor(e.target.value)}
                              className={`d-flex ${styles.form_entry_color}`}
                          />
                        </div>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className={`d-flex justify-content-center`}>
                        <div>
                          <button type="submit" className={styles.settings_save}>
                            {'Save changes'}
                          </button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
                {setValues(values)}
                {setRental(values.rental)}
                {setGuestName(values.guestName)}
                {setCompany(values.company)}
                {setPrimaryEmail(values.primaryEmail)}
                {setSecondaryEmail(values.secondaryEmail)}
                {setStreetAddress(values.streetAddress)}
                {setPostalCode(values.postalCode)}
                {setStateProvince(values.stateProvince)}
                {setAddressDetail(values.addressDetails)}
                {setNationality(values.nationality)}
                {setLanguage(values.language)}
                {setNotes(values.notes)}
              </div>
          )}
        </Formik>
      </>
  )
};
export default BookingForm;
