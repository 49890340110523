import React,{useState,useEffect} from 'react';
import * as yup from 'yup';
import moment from 'moment';
import {useSelector,useDispatch} from "react-redux";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ReactTooltip from "react-tooltip";
import { Row,Col } from 'react-bootstrap';
import {
  Popover,
  Box,
} from '@material-ui/core';
import Table from '../../../../../../../components/Table';
import Loading from '../../../../../../../components/loading';
import CreateMode from './CreateMode/CreateMode';
import MainFile from '../../../../../../../components/InvoiceSend/MainFile';
import { zeroPad } from "../../../../../../../common/functions/utils";
import styles from '../editBooking.module.css';
import styles2 from './invoiceDetail.module.css';

import {addInvoice,getInvoiceByBooking,deleteInvoice,editInvoiceByBooking,getSendInvoiceData} from '../../../../../../../general_redux/invoice/actions';

const Invoice = (props) => {
    const [createMode,setCreateMode]=useState(false);
    const [viewSendInvoice,setViewSendInvoice]=useState(false);
    const [tableView,setTableView]=useState(true);
    const dispatch=useDispatch();
    const bookingData= useSelector(({calendar}) => calendar &&
        calendar.allBooking.filter((row)=>row.id===props.bookingId)[0]);
    const rentalDetail = useSelector(({rentals})=>rentals
        && rentals.rentals.filter((row)=>row.id===bookingData.rentalId)[0]);
    const guestDetails = useSelector(({guests})=>guests && guests.guestByBooking)[0];
    const settings= useSelector(({generalSetting})=>generalSetting && generalSetting.setting);
    const invoiceByBooking=useSelector(({invoice})=>invoice && invoice.invoiceByBooking);
    const invoiceList=useSelector(({invoice})=>invoice && invoice.invoiceList);
    const dateFormat = useSelector(({generalSetting})=>generalSetting && generalSetting.setting.dateDisplay);
    const actionForAddInvoice=useSelector(({invoice})=>invoice && invoice.actionForAddInvoice);
    const actionForEditInvoice = useSelector(({invoice}) => invoice && invoice.actionForEditInvoice);
    const actionForDeleteInvoice = useSelector(({invoice}) => invoice && invoice.actionForDeleteInvoice);
    const [selectedInvoice,setSelectedInvoice]=useState(null);
    const [invoiceBySend, setInvoiceBySend]=useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentDelete,setCurrentDelete]=useState(null);

    useEffect(()=>{
        dispatch(getInvoiceByBooking(props.bookingId));
    },[invoiceList]);

  const handleDiscardChanges = () => {
    setSelectedInvoice(null);
    setCreateMode(false);
    setTableView(true);
  }

    useEffect(() => {
        if(actionForAddInvoice.success || actionForEditInvoice.success){
          handleDiscardChanges();
        }
    }, [actionForAddInvoice.success, actionForEditInvoice.success]);

    const invoiceSchema =  yup.object().shape({
        invoiceNr:yup.string().trim().required('Invoice Nr is required!'),
        issueDate:yup.date().required('Issue Date is required!').nullable(),
        dueDate:yup.date().required('Due date is required!').nullable(),
        status: yup.string().required('Please add a status of payment!'),
        r_name:yup.string().required("Please enter recipient's name!"),
        r_comp:yup.string(),
        r_address1:yup.string(),
        r_address2:yup.string(),
        r_address3:yup.string(),
        s_name:yup.string().required("Sender name is required!"),
        s_address1:yup.string(),
        s_address2:yup.string(),
        s_address3:yup.string(),
        s_country:yup.string(),
        invoiceText:yup.string(),
        invoiceFooter:yup.string(),
        total:yup.number(),
    })

    const cols = [
        {lable:'Invoice Nr', key:'invoiceNumber'},
        {lable: 'Created', key: 'invoiceDate',isDate:true},
        {lable: 'Invoice Due', key: 'dueDate',isDate:true},
        {lable: 'Total', key: 'total'},
        {lable: 'Invoice Status', key: 'paymentStatus'},
        {lable: 'Mark as Sent', key: 'markSent'},
        {lable: 'Actions', key: ''},
    ];

  const handleSubmit = (data, totalCost, tableData, tableData2, tableData3)=>{
        const payload={
            id:(selectedInvoice)?selectedInvoice.id : undefined,
            createdOn:(selectedInvoice)?new Date():undefined,
            invoiceDate: (data.issueDate && data.issueDate!=="")?data.issueDate.toISOString().slice(0,10):"",
            invoiceNumber:data.invoiceNr || selectedInvoice.invoiceNumber,
            // invoiceDateFormatted : moment(`${data.issueDate}`, "YYYY-MM-DD").format(dateFormat),
            dueDate:(data.dueDate && data.dueDate!=="")?data.dueDate.toISOString().slice(0,10):"",
            // dueDateFormatted: moment(`${data.dueDate}`, "YYYY-MM-DD").format(dateFormat),
            invoiceDateUtc: moment(`${data.issueDate}`).format().slice(0,10),
            dueDateUtc: moment(`${data.dueDate}`).toISOString(),
            currency:rentalDetail.currency,
            invoiceItem:tableData.map(({ id, ...rest }) => rest), 
            feeItems:tableData2.map(({ id, ...rest }) => rest),
            taxItems:tableData3.map(({ id, ...rest }) => rest),
            total: Number(totalCost.toFixed(2)),
            markSent:selectedInvoice?false:undefined,
            downloadLink:selectedInvoice?selectedInvoice.downloadLink:undefined,
            recipientDetail:{
                name:data.r_name,
                company:data.r_comp,
                address1:data.r_address1,
                address2:data.r_address2,
                address3:data.r_address3,
                email:guestDetails.emailId,
            },
            senderDetail:{
                name:data.s_name,
                address1:data.s_address1,
                address2:data.s_address2,
                address3:data.s_address3,
                country:data.s_country
            },
            invoiceText:data.invoiceText,
            invoiceFooter:data.invoiceFooter,
            paymentStatus:data.status,
            bookingId:props.bookingId,
            guestId:guestDetails.id,
            bookingNumber:data.bookingNumber || "0",
        }
        // console.log(payload);
        if(!selectedInvoice){
            dispatch(addInvoice(payload));
        }else{
            dispatch(editInvoiceByBooking(payload))
        }
    }

    const handleEdit=(data)=>{
        setSelectedInvoice(data);
        setCreateMode(true);
        setTableView(false);
    }

    const handleSendInvoiceEdit = (data) => {
        const findInvoice = invoiceByBooking.filter(item => item.id === data.id);
        if(findInvoice.length) {
            setViewSendInvoice(false);
            handleEdit(findInvoice[0]);
        }
    }

    const handleSendInvoice = (data) => {
      console.log(data);
      setInvoiceBySend(data);
      setViewSendInvoice(true);
      setTableView(false);
      if(data && data.id){
        dispatch(getSendInvoiceData(data.id));
      }
    }

    const handleDelete=()=>{
        if(currentDelete && currentDelete.id){
            dispatch(deleteInvoice(currentDelete.id))
        }
        setAnchorEl(null);
        setCurrentDelete(null);
    }

    const handleDownload=(invoiceD)=>{
        window.open(invoiceD.downloadLink,"_parent")
    }

    const initialValuesEdit={
        invoiceNr: selectedInvoice ? selectedInvoice.invoiceNumber :"0",
        issueDate:(selectedInvoice && selectedInvoice.invoiceDate)? new Date(selectedInvoice.invoiceDate):"",
        dueDate:selectedInvoice?new Date(selectedInvoice.dueDate):"",
        status:selectedInvoice?selectedInvoice.paymentStatus:"Not Paid",
        r_name:selectedInvoice?selectedInvoice.recipientDetail.name:"",
        r_comp:selectedInvoice?selectedInvoice.recipientDetail.company:"",
        r_address1:selectedInvoice?selectedInvoice.recipientDetail.address1:"",
        r_address2:selectedInvoice?selectedInvoice.recipientDetail.address2:"",
        r_address3: selectedInvoice && selectedInvoice.recipientDetail.address3 ? selectedInvoice.recipientDetail.address3 : "",
        s_name:selectedInvoice?selectedInvoice.senderDetail.name:"",
        s_address1:selectedInvoice?selectedInvoice.senderDetail.address1:"",
        s_address2:selectedInvoice?selectedInvoice.senderDetail.address2:"",
        s_address3: selectedInvoice && selectedInvoice.senderDetail.address3 ? selectedInvoice.senderDetail.address3 : "",
        s_country:selectedInvoice && selectedInvoice.senderDetail.country ?selectedInvoice.senderDetail.country:"",
        invoiceText:selectedInvoice?selectedInvoice.invoiceText:"",
        invoiceFooter:selectedInvoice?selectedInvoice.invoiceFooter:"",
        total:selectedInvoice?selectedInvoice.total:bookingData.price,
        tableData: selectedInvoice ? selectedInvoice.invoiceItem : [],
        tableData2: selectedInvoice ? selectedInvoice.feeItems : [],
        tableData3: selectedInvoice ? selectedInvoice.taxItems : [],
        bookingNumber: (selectedInvoice && selectedInvoice.bookingNumber) ? selectedInvoice.bookingNumber : ""
    }

    const initialValues={
        invoiceNr:`${1000+(invoiceList? invoiceList.length:0)}`,
        issueDate:new Date(),
        dueDate: new Date(`${moment(new Date()).add(settings.invoiceDueDate || 0, 'days')}`),
        status:'Not Paid',
        r_name:guestDetails.name?`${guestDetails.name}`:"",
        r_comp:guestDetails.company?`${guestDetails.company}`:"",
        r_address1:guestDetails.street?`${guestDetails.street}`:"",
        r_address2: `${guestDetails.postal_code || ""} ${guestDetails.state || ""}`,
        r_address3:guestDetails.country?`${guestDetails.country}`:"",
        s_name:settings.name?`${settings.name}`:"",
        s_address1:settings.address1?`${settings.address1}`:"",
        s_address2:settings.address2?`${settings.address2}`:"",
        s_address3: settings.address3 ? `${settings.address3}` : "",
        s_country:settings.country?`${settings.country}`:"",
        invoiceText:settings.invoiceText?`${settings.invoiceText}`:"",
        invoiceFooter:settings.invoiceFooter?`${settings.invoiceFooter}`:"",
        total: bookingData ? bookingData.price : 0,
        bookingNumber: bookingData ? `${new Date().getUTCFullYear()}${zeroPad(bookingData.id, 4)}`: ''
    }

    const handleConfirmDialog = (e,row) => {
      setCurrentDelete(row);
      setAnchorEl( anchorEl ? null : e.currentTarget);
    }
  const invoiceDeleteDialog = () => {
    return (
      <Popover
        id={"popover-1"}
        open={Boolean(anchorEl)}
        onClose={handleConfirmDialog}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            borderRadius: 0,
            display: "flex",
          },
        }}
      >
        <div className={styles2.contentWrap}>
          <div className={styles2.deleteTitle}>
            Are you sure you want to delete this invoice?
          </div>
          <div className="text-center m-1">
            <button
              onClick={() => handleDelete()}
              className={styles2.delConfirmBtn}
            >
              Delete
            </button>
            <button
              onClick={handleConfirmDialog}
              className={styles2.cancelBtn}
            >
              Cancel
            </button>
          </div>
        </div>
        <Box
            className={styles2.rightArrow}
        />
      </Popover>
    );
  };

  return (
    <div className="p-3">
        <Loading loadingStatus={actionForAddInvoice.loading || actionForEditInvoice.loading || actionForDeleteInvoice.loading} />
        <Row >
          <div className="pb-2 pl-3 d-flex w-100 justify-content-between">
            <div className="d-flex">
              <div className={styles.subHeader}> Invoice </div>
              <InfoOutlinedIcon
                fontSize="small"
                color="inherit"
                data-tip
                data-for="invoiceEdit"
                className={styles.toolTipIcon}
              />
            </div>
            <div>
              <ReactTooltip place="bottom" type="dark" id="invoiceEdit" effect="solid" className={styles.toolTip}>
                  {
                      createMode || viewSendInvoice ? (
                          <span>
                              Standard invoice details such as sender, invoice text and footer information can be edited by
                              clicking on ‘Settings’ in the top menu under the title ‘ Invoice Settings’.
                              <br/>
                              There you will also be able to change your preferences for date display and currency. If you set a
                              different currency for this rental that is different from your currency in settings, then currency can
                              be changed under ‘Rentals’ in the top menu.
                          </span>
                      ) : (
                          <span>
                              You will see here invoices made for this reservation. To see all
                              invoices linked to a particular guest, click ‘Guests’ on the
                              left menu and search for the desired guest. To see all invoices,
                              click ‘Invoices’ on the left menu. Note that changing the
                              invoice status will not affect the payment status of the
                              booking. Changing the status of the total due can be done under
                              the ‘Booking Details’ or ‘Payment’ tabs.
                          </span>
                      )
                  }

              </ReactTooltip>
            </div>
            <button
              className={styles2.sideButton}
              onClick={() => {
                if (createMode) {
                  setSelectedInvoice(null);
                  setCreateMode(false);
                  setTableView(true);
                } else if (viewSendInvoice) {
                  setSelectedInvoice(null);
                  setViewSendInvoice(false);
                  setTableView(true);
                } else {
                  setCreateMode(true);
                  setTableView(false);
                }
              }}
            >
              {createMode || viewSendInvoice ? "View invoice" : "Create invoice"}
            </button>
          </div>
        </Row>
        {tableView && (
          <>
            <div className="p-0">
              <Col xs={12} md={12} lg={12} className="p-0">
                <Table
                  rows={invoiceByBooking}
                  cols={cols}
                  onEdit={(data) => handleEdit(data)}
                  onDelete={(val) => handleDelete(val)}
                  startKey={cols[0].key}
                  dateFormat={dateFormat.toUpperCase()}
                  onDownload={handleDownload}
                  onSendGuest={(data) => handleSendInvoice(data)}
                  confirmDeleteDialog={invoiceDeleteDialog}
                  handleConfirmDialog={handleConfirmDialog}
                  tableProps={
                    {
                      style:{
                       width: "750px", 
                       tableLayout: "fixed"
                      }
                    }
                  }
                  columnStyle={styles2.customWrapField}
                  actionColumnStyle = {styles2.actionField}
                />
              </Col>
            </div>
          </>
        )}
        {viewSendInvoice && (
          <>
            <MainFile
                invoiceData={invoiceBySend}
                bookingId={props.bookingId}
                onEdit={(data) => handleSendInvoiceEdit(data)}
            />
          </>
        )}
        {createMode && (
          <>
             <CreateMode
                initialValues={(selectedInvoice && selectedInvoice.length !== 0) ? initialValuesEdit : initialValues}
                validationSchema={invoiceSchema}
                onSubmit={handleSubmit}
                onReset={handleDiscardChanges}
                bookingId={props.bookingId}
              />
          </>
        )}

    </div>
  );
};

export default Invoice;
