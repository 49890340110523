import { BACKEND_API } from '../../config/middleware';
import API from '../../config/api-url';
import {
  LOGIN_USER,
  LOGOUT_USER,
  OAUTH_LOGIN_USER,
  REGISTER_USER,
  RESET_WELCOME,
  USER_TOKEN_VERIFY_FAIL,
  USER_TOKEN_VERIFY_SUCCESS,
  USER_TOKEN_ADD,
  ADD_USER_SETTINGS,
  GET_USER_INFO,
  DELETE_USER,
  RELOGIN_REQ,
  CONFIRM_MAIL_UPDATE,
  GET_PACKAGES,
  START_PAYMENT_SESSION,
  CREATE_USER_CONNECT,
  MAKE_CUSTOMER_PAYMENT,
  START_SUBSCRIPTION,
} from './types';

export const loginUser = (emailId) => ({
  type: BACKEND_API,
  payload: Object.assign({
    type: LOGIN_USER,
    method: 'post',
    url: API.LOGIN,
    data: { emailId },
  }),
});

export const oauthLoginUser = (oauthType) => ({
  type: BACKEND_API,
  payload: Object.assign({
    type: OAUTH_LOGIN_USER,
    method: 'get',
    url: (() => {
      let params = new URLSearchParams()
      params.append("type", oauthType);
      return `${API.OAUTH_LOGIN}` + "?" + params.toString();
    })()
  })
});

export const oauthLoginUserCallback = (code, state) => ({
  type: BACKEND_API,
  payload: Object.assign({
    type: OAUTH_LOGIN_USER,
    method: 'get',
    url: (() => {
      const params = new URLSearchParams()
      params.append("code", code);
      if(state) params.append("state", state)
      return `${API.OAUTH_LOGIN_CALLBACK}` + "?" + params.toString();
    })()
  })
});

export const userTokenVerify = (data) => ({
  type: BACKEND_API,
  payload: Object.assign({
    type: { success: USER_TOKEN_VERIFY_SUCCESS, error: USER_TOKEN_VERIFY_FAIL },
    method: 'get',
    url: `${API.LOGINBYAUTH}/${data}`,
  }),
});

export const addUserAuthToken = (data) => ({
  type: USER_TOKEN_ADD,
  payload: data,
});

export const registerUser = (data) => ({
  type: BACKEND_API,
  payload: Object.assign({
    type: REGISTER_USER,
    method: 'post',
    url: API.REGISTER,
    data,
  }),
});

export const logoutUser = (data) => (dispatch) => {
  sessionStorage.removeItem('state');
  let expires = new Date(Date.now() + 1 * 1000);
  document.cookie = 'BeehazToken=;' + expires + '; path=/';
  dispatch(logOutMain());
};

export const logOutMain = () => {
  return {
    type: LOGOUT_USER,
  };
};

export const resetUser = (data) => ({
  type: BACKEND_API,
  payload: Object.assign({
    type:RESET_WELCOME,
    method:'put',
    url: API.RESET_NEWUSER,
    data
  })
});

export const addUserSettings = (userSettingsData) => ({
  type: BACKEND_API,
  payload: Object.assign({
    type: ADD_USER_SETTINGS,
    method: 'put',
    url: API.ADD_USER_SETTINGS,
    data: userSettingsData,
  }),
});

export const getUserInfo = () => ({
  type: BACKEND_API,
  payload: Object.assign({
    type: GET_USER_INFO,
    method: 'get',
    url: API.CUSTOMER_SETTINGS,
    data: null,
  }),
});

export const deleteUser=(id)=>({
  type:BACKEND_API,
  payload: Object.assign({
    type: DELETE_USER,
    method: 'delete',
    url:`${API.CUSTOMER}${id}`
  })
})

export const authRelogin=()=>{
  return {
    type:RELOGIN_REQ,
  }
}

export const confirmMail=(data,token)=>({
  type: BACKEND_API,
  payload: Object.assign({
    type: CONFIRM_MAIL_UPDATE ,
    method: 'post',
    url: `${API.CUSTOMER}validateOTP/${token}`,
    data
  }),
})

export const GetPackages=()=>({
  type:BACKEND_API,
  payload: Object.assign({
    type:GET_PACKAGES,
    method:'get',
    url:`${API.PAYMENT}get_packages`
  })
})

export const paymentSession=(data)=>({
  type:BACKEND_API,
  payload: Object.assign({
    type:START_PAYMENT_SESSION,
    method:'post',
    url:`${API.PAYMENT_SESSION}`,
    data
  })
})

export const stripeConnect=(data)=>({
  type:BACKEND_API,
  payload: Object.assign({
    type:CREATE_USER_CONNECT,
    method:'post',
    url:`${API.PAYMENT_CONNECT_CREATE}`,
    data
  })
})

export const makeCustomerPayment=(data)=>({
  type:BACKEND_API,
  payload:Object.assign({
    type:MAKE_CUSTOMER_PAYMENT,
    method:'post',
    url:`${API.CUSTOMER_PAYMENT}`,
    data
  })
})

export const startSubscription = (data)=>({
  type:BACKEND_API,
  payload: Object.assign({
    type:START_SUBSCRIPTION,
    method:'post',
    url:`${API.PAYMENT_SUBSCRIPTION}`,
    data
  })
})