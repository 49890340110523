export const actionForState = (action, state, message) => ({
    ...action,
    success: state === 'success',
    loading: state === 'pending',
    error: state==='error',
    message: message || state
});
export const tConvert= (time)=> {
    // Check correct time format and split into components
    time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
        time = time.slice (1);  // Remove full string match value
        time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join (''); // return adjusted time or original string
}
export const zeroPad = (num, places) => String(num).padStart(places, '0')

export const setCookie = (name, value, expiration) => {
    var expires = '';
    if (expiration) {
      var date = new Date();
      date.setTime(date.getTime() + expiration * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
};

export const getCookie = (name) => {
    const value = document.cookie;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
};