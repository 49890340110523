import {
    GET_INVOICES,
    ADD_INVOICE,
    DELETE_INVOICE,
    EDIT_INVOICE_BY_BOOKING,
    EDIT_INVOICE_BY_GUEST,
    EDIT_INVOICE_LIST,
    INVOICE_BY_BOOKING,
    CLEAR_INVOICE,
    INVOICE_BY_GUEST,
    DELETE_MULTIPLE_INVOICE,
    GET_FILTER_INVOICES,
    MARK_INVOICE,
    SEND_INVOICE,
    SCHEDULE_INVOICE,
    GET_SCHEDULE_INVOICE,
    CANCEL_SCHEDULE_INVOICE,
    RESET_INVOICE_ACTIONS,
} from "./types";

import {BACKEND_API} from "../../config/middleware";
import API from "../../config/api-url";

export const addInvoice = data => ({
    type: BACKEND_API,
    payload: Object.assign(
        {
            type:ADD_INVOICE,
            method: 'post',
            url: API.INVOICE_PATH,
            data
        },
    )
});

export const getInvoiceByBooking=id=>({
    type:BACKEND_API,
    payload:Object.assign(
        {
            type:INVOICE_BY_BOOKING,
            method:'get',
            url:`${API.INVOICE_PATH}getInvoiceByBookingId/${id}`
        }
    )
})

export const getInvoices=()=>({
    type:BACKEND_API,
    payload:Object.assign(
        {
            type:GET_INVOICES,
            method:'get',
            url:API.INVOICE_PATH
        }
    )
})

export const editInvoiceByBooking=(data)=>({
    type: BACKEND_API,
    payload: Object.assign(
        {
            type:EDIT_INVOICE_BY_BOOKING,
            method: 'put',
            url: API.INVOICE_PATH,
            data
        },
    )
})

export const editInvoiceByGuest=(data)=>({
    type: BACKEND_API,
    payload: Object.assign(
        {
            type:EDIT_INVOICE_BY_GUEST,
            method: 'put',
            url: API.INVOICE_PATH,
            data
        },
    )
})

export const editInvoiceList=(data)=>({
    type: BACKEND_API,
    payload: Object.assign(
        {
            type: EDIT_INVOICE_LIST,
            method: 'put',
            url: API.INVOICE_PATH,
            data
        }
    )
})

export const clearInvoice=()=>({
    type:CLEAR_INVOICE
})

export const deleteInvoice=(id)=>({
    type:BACKEND_API,
    payload:Object.assign(
        {
            type:DELETE_INVOICE,
            method:'delete',
            url:`${API.INVOICE_PATH}${id}`
        }
    )
})

export const invoiceByGuest=(id)=>({
    type:BACKEND_API,
    payload: Object.assign(
        {
            type:INVOICE_BY_GUEST,
            method:'get',
            url: `${API.GUEST_INVOICE}${id}`
        }
    )
})

export const deleteMultipleInvoice=(data)=>({
    type:BACKEND_API,
    payload:Object.assign(
        {
            type:DELETE_MULTIPLE_INVOICE,
            method:'delete',
            url:`${API.INVOICE_PATH}multiple`,
            data
        }
    )
});

export const getFilterInvoices = (data) => ({
    type: BACKEND_API,
    payload: Object.assign(
        {
            type:GET_FILTER_INVOICES,
            method: 'post',
            url: `${API.INVOICE_FILTER}`,
            data
        },
    )
});

export const markInvoiceAsSent = (data)=> ({
    type:BACKEND_API,
    payload: Object.assign(
        {
            type:MARK_INVOICE,
            method:'put',
            url:`${API.MARK_SENT}`,
            data
        }
    )
});

export const sendInvoiceNw = (data)=>({
    type:BACKEND_API,
    payload:Object.assign(
        {
            type:SEND_INVOICE,
            method:'post',
            url:`${API.SEND_INVOICE_NW}`,
            data
        }
    )
});

export const scheduleInvoice = (data)=>({
    type:BACKEND_API,
    payload:Object.assign({
        type:SCHEDULE_INVOICE,
        method:'post',
        url:`${API.SCHEDULE_INVOICE}`,
        data
    })
});

export const getSendInvoiceData = (id)=>({
    type:BACKEND_API,
    payload:Object.assign(
        {
            type:GET_SCHEDULE_INVOICE,
            method:'get',
            url:`${API.SCHEDULE_INVOICE}/${id}`,
        }
    )
});

export const cancelScheduleInvoice = (data)=>({
    type:BACKEND_API,
    payload: Object.assign(
        {
            type:CANCEL_SCHEDULE_INVOICE,
            method:'post',
            url:`${API.CANCEL_INVOICE}`,
            data
        }
    )
});

export const resetInvoices = ()=>({
    type:RESET_INVOICE_ACTIONS
})