import React, { useEffect, useState, useRef } from 'react';
import exportFromJSON from 'export-from-json';
import {DropdownButton, Dropdown} from "react-bootstrap";
import styles from './guest.module.css';
import { useSelector, useDispatch } from 'react-redux';
import SearchField from 'react-search-field';
import AddGuests from './components/AddGuests/AddGuests';
import ListGuest from './components/List/ListGuests';
import { addGuests, editGuest, loadGuests, deleteGuest } from '../../../../general_redux/guest/actions';
import { CSVLink } from 'react-csv';
import * as XLSX from 'xlsx';

function Guests() {
  const dropWrapper=useRef(null);
  const [addGuestModal, setAddGuestModal] = useState(false);
  const [edited, setEdited] = useState(undefined);
  const [search, setSearch] = useState('');
  const [dropActive,setActive]=useState(false);
  const dispatch = useDispatch();
  let guestList = useSelector(({ guests }) => guests && guests.guestList);

  useEffect(() => {
    function handler(event) {
      if(!dropWrapper.current?.contains(event.target)){
        setActive(false);
      }
    }
    window.addEventListener('click', handler)
    return () => window.removeEventListener('click', handler)
  }, []);

  const headers = [
    { label: 'Name', key: 'name' },
    { label: 'Company', key: 'company' },
    { label: 'Primary Email', key: 'emailId' },
    { label: 'Secondary Email', key: 'secondaryEmailId' },
    { label: 'Phone Number', key: 'phoneNo' },
    { label: 'Country', key: 'country' },
    { label: 'Address', key: 'address' },
    { label: 'Postal Code', key: 'postalCode' },
    { label: 'State/Province', key: 'state' },
    { label: 'Nationality', key: 'nationality' },
    { label: 'Language', key: 'language' },
    { label: 'Notes', key: 'notes' },
  ];

  useEffect(() => {
    dispatch(loadGuests());
  }, []);

  const handleSubmit = (data) => {
    if (edited) {
      dispatch(editGuest(data));
    } else {
      dispatch(addGuests(data));
    }
    setAddGuestModal(false);
    setEdited(null);
  };
  const handleEdit = (value) => {
    setEdited(value);
    setAddGuestModal(true);
  };

  const handleDelete = (row) => {
    dispatch(deleteGuest(row.id));
  };

  const findCSVData=()=>{
    const data=guestList.filter(
      (guest) =>
        guest.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
        guest.emailId.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
    return data;
  }

  document.addEventListener('mouseup', function(e) {
    // if(dropActive){
    //   setActive(!dropActive);
    // }
  });

  const handleDownload=(e,eType)=>{
    e.preventDefault();
    const dataTobe=guestList.filter(
        (guest) =>
            guest.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
            guest.emailId.toLocaleLowerCase().includes(search.toLocaleLowerCase()));
    const fileName=`GuestList_${Date.now()}`;
    let data=[];
    dataTobe.forEach((items)=>{
      let tmp={};
      Object.keys(items).map((key,index)=>{
        tmp={
          "Name":items['name'],
          "Company":items['company'],
          "Birth Date":items["dob"],
          "Nationality":items["nationality"],
          "Country":items["country"],
          "Street and nr":items["street"],
          "Postal code":items["postalCode"],
          "State/Province":items["state"],
          "Phone":items["phoneNo"],
          "Email":items["emailId"]
        }
      })
      data.push(tmp);
    })
    const guestHeading = ["Name", "Company", "Birth Date", "Nationality", "Country", "Street and nr", "Postal code", "State/Province", "Phone", "Email"]
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data, { origin: 'A2', skipHeader: true });
    XLSX.utils.sheet_add_aoa(ws, [guestHeading], { origin: 'A1' });
    XLSX.utils.book_append_sheet(wb, ws, 'GuestSheet');
    XLSX.writeFile(wb, `${fileName}.${eType}`,{ bookType: eType, type: 'array' });
    return true;
  }

  return (
    <>
      {addGuestModal ?(
          <AddGuests
              show={addGuestModal}
              onHide={(data) => {
                setAddGuestModal(false);
                setEdited(null);
              }}
              onSubmit={(data)=>handleSubmit(data)}
              value={edited}
          />
      ):(
          <>
            <div className={styles.guestHeader}>
              <div className={styles.searchContainer}>
                <SearchField
                    placeholder="Search Guests"
                    onChange={(val) => setSearch(val)}
                    searchText=""
                    classNames={styles.search_field}
                />
              </div>
              <div className={styles.flexEnd}>
                {/*<CSVLink className={`w-25 ${styles.btn_csv} ${styles.btn}`} data={findCSVData()} headers={headers} filename={`BeeHaz_Guest_List_${Date.now()}.csv`}>*/}
                {/*  Download Excel*/}
                {/*</CSVLink>*/}
                <div className={`${styles.dropdown}`} onClick={()=>setActive(!dropActive)} ref={dropWrapper}>
                  <button className={`${styles.btn}`} >Download
                    {/*<Dropdown.ItemText>Download</Dropdown.ItemText>*/}
                    {/*<Dropdown.Item as="button" onClick={()=>handleDownload('csv')}>*/}
                    {/*  Download as CSV</Dropdown.Item>*/}
                    {/*<Dropdown.Item as="button" onClick={()=>handleDownload('xls')}>*/}
                    {/*  Export to excel</Dropdown.Item>*/}
                    <div className={dropActive?`${styles.dropdownContentActive}`:`${styles.dropdownContent}`}>
                      <a href="" onClick={(e)=>handleDownload(e,"csv")}>Download CSV</a>
                      <a href="" onClick={(e)=>handleDownload(e,"xlsx")}>Export Excel</a>
                    </div>
                  </button>
                </div>

                <button className={`${styles.btn_csv} ${styles.btn} -25`} onClick={() => setAddGuestModal(true)}>
                  Add Guest
                </button>
              </div>
            </div>
            <ListGuest search={search} onDelete={handleDelete} onEdit={handleEdit}/>
          </>
      )}
    </>
  );
}

export default Guests;
