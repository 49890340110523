import React from 'react';
import {useSelector} from "react-redux";
import {Modal} from 'react-bootstrap';
import * as yup from 'yup';
import WeeklyRateForm from './WeeklyRateForm';
import styles from '../../../../Rentals/rentals.module.css';

const WeeklyRate = (props) => {

    const properties = useSelector(({rentals})=>rentals && rentals.rentals);

    const schemaRate = yup.object().shape
    ({
        multiRentals: yup.array().required("Please select a rental!"),
        rate: yup.number().typeError('You must specify a number').required("Rate is a required field!").test('Is positive?',
            'The rate must be greater than 0!',
            (value) => (!(Number(value) < 0))),
        extraRate: yup.number().typeError('You must specify a number').required("Extra rate is a required field!").test('Is positive?',
            'The extra rate must be greater than 0!',
            (value) => (!(Number(value) < 0))),
        taxName: yup.string().when('taxAmount', {
            is: (value) => value && value.toString() !== "0",
            then: yup.string().trim().required('Tax name is a required field!'),
            otherwise: yup.string()
        }),
        taxAmount: yup.number().typeError('You must specify a number').when('taxName', {
            is: (value) => value && value.length > 0,
            then: yup.number().typeError('You must specify a number').required('Tax amount is a required field!'),
            otherwise: yup.number()
        }),
        extraTax:  yup.number().typeError('You must specify a number').test('Is positive?',
            'The extra tax must be greater than 0!',
            (value) => (!(Number(value) < 0)
        )),
    },['taxName','taxAmount']);

    const handleSubmit=(data,rentalSelection)=>{
        const payload=[];
        rentalSelection.map((rental)=>{
            payload.push({
                rentalId:rental.value,
                rate:data.rate,
                extraRate:data.extraRate,
                taxName:data.taxName,
                taxAmount:data.taxAmount,
                extraTax:data.extraTax,
            })
        });
        console.log("weekly rate payload",payload)
        props.onHide();
    }

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className={styles.modalTitle}>
                    Add weekly rate
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={styles.deleteModalBody}>
                <p>Weekly rate corresponds to a fixed amount charged for 7 nights
                    independent of the number of guests. This rate will only be used when
                    adding a special type of weekly booking (using the button ‘add fixed’
                    on the calendar).
                </p>
                <p>
                    Regular fees and taxes (including extra guest fees) do not apply here.
                    Set the weekly rate including all fees and taxes applicable. If you want
                    to show a tax as a separate item when invoicing the guest, you can set
                    this here as a flat rate. The rate + tax will be the fixed price per period.
                </p>
                <WeeklyRateForm
                    handleSubmit={handleSubmit}
                    schema={schemaRate}
                    currency={props.currency}
                    properties={properties}
                    onHide={props.onHide}
                />
            </Modal.Body>
        </Modal>
    );
};

export default WeeklyRate;