import React,{useState} from 'react';
import {Modal} from 'react-bootstrap';
import {useDispatch} from "react-redux";
import * as yup from 'yup';
import styles from "../../../../Rentals/rentals.module.css";
import ExtraGuestForm from "./ExtraGuestForm";
import {addExtraGuestFee} from '../../../../../../../general_redux/rates/actions';

function ExtraGuestFee(props){
    const dispatch=useDispatch();
    const schemaExtraGuest = yup.object({
        multiRentals: yup.array().required("Rental selection is a required field"),
        guestLimit: yup.number().required("Guest limit is a required field"),
        amount: yup.number().required("Amount is a required field").typeError("You must specify a number."),
    });

    const handleSubmit=(data,selectedRentals)=>{
        const payload=[];
        selectedRentals.map((rental)=>{
            payload.push({
                rentalId:rental.value,
                guestLimit:data.guestLimit,
                amount:Number(Number(data.amount).toFixed(2))
            })
        })
        // console.log(payload);
        dispatch(addExtraGuestFee(payload));
        props.onHide();
    }

    return(
        <>
            <Modal
                show={props.show}
                onHide={props.onHide}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className={styles.modalTitle}>
                        Add/Edit Extra guest fee
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={styles.deleteModalBody}>
                    <ExtraGuestForm
                        schema={schemaExtraGuest}
                        handleSubmit={handleSubmit}
                        initValue={false}
                        properties={props.properties}
                        currency={props.currency}
                        onHide={props.onHide}
                    />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ExtraGuestFee;