import React,{useState} from 'react';
import {Modal} from 'react-bootstrap';
import styles from './invoiceSend.module.css';
import MainFile from './MainFile';

function InvoiceSend(props){
    return(
        <div className={`pl-0`}>
            <Modal
                show={props.show}
                onHide={props.onHide}
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className={styles.modalHeader}>
                        {"Sending Invoice"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={styles.deleteModalBody}>
                    <MainFile {...props}/>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default InvoiceSend;