import React, { useEffect, useState } from 'react';
import styles from '../editBooking.module.css';
import { Row, Col } from 'react-bootstrap';
import Loading from '../../../../../../../components/loading';
import { useDispatch,useSelector } from 'react-redux';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import * as yup from 'yup';
import moment from 'moment';
import { Formik, Form, Field } from 'formik';
import ReactTooltip from 'react-tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PhoneInput from 'react-phone-input-2';
import { editGuest } from '../../../../../../../general_redux/guest/actions';
import {isoCountries} from "../../../../../../../config/data/iso2Country";
import {getGuestByBooking} from "../../../../../../../general_redux/guest/actions";
import CustomDate from "../../../../../../../components/CustomDate";

const GuestDetails = ({bookingId}) => {
  const phoneRegExp = /^[0-9-+\s()]*$/;
  const user= useSelector(({user})=>user && user.user);
  const actionForUpdateGuest = useSelector(({guests})=> guests && guests.actionForUpdateGuest);
  const guestDetails = useSelector(({guests})=>guests && guests.guestByBooking)[0];
  const [dateOfBirth,setDateOfBirth] = useState((guestDetails && guestDetails.dob && (guestDetails.dob!=="Invalid da" && guestDetails.dob!=="" && guestDetails.dob!=="null"))?new Date(guestDetails.dob):"");
  const dispatch = useDispatch();
  const countries = countryList().getData();

  const validationSchema=yup.object({
    name: yup.string().required('Guest name is required'),
    company: yup.string(),
    emailId:yup.string().email('Invalid email').required('Email address is required'),
    secondaryEmailId :yup.string().email('Invalid email'),
    phoneNo:yup.string().matches(phoneRegExp, 'Only numbers, space, parenthesis, + and - are accepted.'),
    country:yup.array().nullable(),
    address: yup.string(),
    postalCode: yup.string(),
    state: yup.string(),
    nationality:yup.string(),
    language:yup.string(),
    dob:yup.date().notRequired().nullable(),
    notes:yup.string(),
  });

  useEffect(()=>{
    if(actionForUpdateGuest.success){
      dispatch(getGuestByBooking(bookingId));
    }
  },[actionForUpdateGuest])

  const findArray = (val)=>{
    const ar=countryList().getData().filter((row)=>row.label===val);
    if(ar.length!==0){
      return ar[0];
    }
  }

  const findCountry=(val)=>{
    if(val){
      for (const key in isoCountries){
        if(isoCountries[key]===val){
          return key.toLocaleLowerCase();
        }
      }
    }else {
      return "us";
    }
  }
  const [country, setCountry] = useState((guestDetails.country)?
      findArray(guestDetails.country):findArray(user.country));

  const [code,setCode]=useState((guestDetails.country)?
      findCountry(guestDetails.country): findCountry(user.country));

  const guestForm = [
    { title: 'Name: ', name: 'name',type :"text" },
    { title: 'Company: ', name: 'company',type :"text" },
    { title: 'Email address: ', name: 'emailId',type :"text" },
    { title: 'Secondary email: ', name: 'secondaryEmailId',type :"text" },
    { title: 'Phone: ', name: 'phoneNo',type :"text" },
    { title: 'Country: ', name: 'country',type :"text" },
    { title: 'State: ', name: 'state',type:"text" },
    { title: 'Address details:', name: 'address',type :"text" },
    { title: 'Street and nr.:', name: 'street',type :"text" },
    { title: 'Postal code: ', name: 'postalCode',type :"text" },
    { title: 'Nationality: ', name: 'nationality',type :"text" },
    { title: 'Language: ', name: 'language',type :"text" },
    { title: 'Date of Birth:', name:'dob',type :"date"},
    { title: 'More info: ', name: 'notes',type:'textarea'}
  ];

  const handleSubmit = (data) => {
    const updatedData = { ...data,
      id:guestDetails.id,
      country: country? country.label:"",
      customerId:user.id,
      dob: moment(dateOfBirth).format().slice(0,10)
    };
    dispatch(editGuest(updatedData));
  };

  const initialValues = {
    name: guestDetails.name,
    company: guestDetails.company? guestDetails.company:'',
    emailId: guestDetails.emailId,
    secondaryEmailId: guestDetails.secondaryEmailId,
    phoneNo: guestDetails.phoneNo,
    postalCode: guestDetails.postalCode,
    state: guestDetails.state,
    nationality: guestDetails.nationality,
    language: guestDetails.language,
    country: country,
    address: guestDetails.address,
    street: guestDetails.street?guestDetails.street:"",
    dob: dateOfBirth && (guestDetails.dateOfBirth!=="Invalid da" && guestDetails.dateOfBirth!=="" && guestDetails.dateOfBirth!=="null") ? dateOfBirth : "",
    notes: guestDetails.notes?guestDetails.notes:""
  };

  return (
    <div>
      <Loading loadingStatus={actionForUpdateGuest.loading}/>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ values, errors, handleChange }) => (
            <Form className="p-3">
              <Row className="m-0">
                <Col s={12} lg={6} className={`${styles.wrapper} p-0`}>
                  <div className="pb-2 d-flex flex-direction-column">
                    <div className={styles.subHeader}> Guest </div>
                    <div className={styles.toolTipIconContainer}>
                      <InfoOutlinedIcon
                        fontSize="small"
                        color="inherit"
                        data-tip
                        data-for="guest"
                        className={styles.toolTipIcon}
                      />
                      <ReactTooltip place="bottom" type="dark" id="guest" effect="solid" className={styles.toolTip}>
                        <span>
                          Changes to the guest here will affect the guest details saved to this guest. This is the information you will find under the guest’s name by clicking on ‘Guests’ in the left menu
                        </span>
                      </ReactTooltip>
                    </div>
                  </div>
                  {guestForm.map((form) => {
                    const err = errors[form.name];
                    return (
                      <Row className={`${styles.form_wrapper} m-0`} key={form.title}>
                        <div className={styles.form_title}>{form.title}</div>
                        <div className={styles.field_width}>
                          {form.name === 'country' ? (
                            <Select
                              id="countrySelect"
                              options={countries}
                              isClearable={true}
                              value={country}
                              name={"country"}
                              className={styles.form_entry_select}
                              onChange={(val) => setCountry(val)}
                            />
                          ) : form.name === 'phoneNo' ? (
                            <div className={styles.form_entry_phone}>
                              <PhoneInput
                                country={code}
                                name="phoneNo"
                                value={values.phoneNo}
                                onChange={(value, data) => {
                                  const rawVal = value.slice(data.dialCode.length);
                                  values.phoneNo = `+${data.dialCode} ${rawVal}`;
                                }}
                                inputStyle={{ width: '100%', borderRadius: '5',fontSize:'13px'}}
                                countryCodeEditable={false}
                                enableSearch={true}
                                disableSearchIcon={true}
                              />
                            </div>
                          ) :
                            (form.name === 'dob'?(
                                <CustomDate
                                    id={"dobGuest"}
                                    name={"dob"}
                                    hasError={err}
                                    handleChange={(date)=>{
                                      setDateOfBirth(date);
                                      // handleChange(date);
                                    }}
                                    value={dateOfBirth}
                                />
                            ):(
                                <Field
                                    id={form.name}
                                    as={form.type==="textarea"?"textarea":undefined}
                                    type={form.type}
                                    rows={form.type==="textarea"?5:undefined}
                                    name={form.name}
                                    className={form.type === "textarea" ? `${styles.guest_form} ${styles.form_textarea_entry} ${err? styles.form_textarea_entry_error : ''}` : `${styles.guest_form} ${styles.form_entry} ${err? styles.form_entry_err : ''}`}
                                    onChange={handleChange}
                                />
                            ))}
                          <div className={err ? styles.form_error : styles.form_error_hidden}>{errors[form.name]}</div>
                        </div>
                      </Row>
                    );
                  })}
                </Col>
              </Row>
              <div className={`d-flex justify-content-center ${styles.button_group}`}>
                <div>
                  <button type="submit" className={styles.settings_save}>
                    Save changes
                  </button>
                </div>
              </div>
            </Form>
        )}
      </Formik>
    </div>
  );
};

export default GuestDetails;
