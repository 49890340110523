import React,{useEffect} from 'react';
import {Modal} from 'react-bootstrap';
import * as yup from 'yup';

import styles from '../../../../Rentals/rentals.module.css';
import FeeForm from './FeeForm';

function AddFee(props){
  const schemaFee = yup.object({
    multiRentals:yup.array().required("Please select a rental!"),
    name: yup.string().required("Name of fee is required."),
    feeType: yup.string().required("Fee type is a required field."),
    amount: yup.number().required("Amount is a required field.").typeError("You must specify a number."),
    modality: yup.string().required("Modality is a required field.")
  });

  const handleSubmit=(data,selectedRentals)=>{
    const payload=[];
    selectedRentals.map((rentals)=>{
      payload.push({
        rentalId:rentals.value,
        name:data.name,
        feeType:data.feeType,
        amount:data.amount,
        modality:data.modality,
      })
    })
    if(props.viewForEdit){
      const editPayload={
        ...data,
        rentalId:selectedRentals && selectedRentals[0] && selectedRentals[0].value,
        multiRentals:undefined,
      }
      props.onEdit(editPayload);
    }else{
      props.onEdit(payload);
    }
  }
    return(
        <Modal
          show={props.show}
          onHide={props.onHide}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
      >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className={styles.modalTitle}>
            Add/edit fee
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.deleteModalBody}>
        <FeeForm
         schema={schemaFee}
         handleSubmit={handleSubmit}
         currency={props.currency}
         initValue={(props.viewForEdit) ? props.viewForEdit: false}
         properties={props.properties}
         onHide={props.onHide}
        />
      </Modal.Body>
    </Modal>
    )
}
export default AddFee;