import React, { useState,useEffect,useRef } from 'react';
import Toggle from 'react-toggle';
import './toggle-css.css';
import getSymbolFromCurrency from 'currency-symbol-map';
import {useSelector,useDispatch} from 'react-redux';
import { Row } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
  chargeCalculation,
  editDiscount,
  fetchBookingById,
  fetchDiscountByBooking,
  resetCharges
} from '../../../../../../../general_redux/calendar/actions';
import Loading from "../../../../../../../components/loading";
import styles from '../editBooking.module.css';

const Payment = ({bookingId}) => {
  const dispatch =useDispatch();
  const bookingDiscount=useSelector(({calendar})=>calendar && calendar.bookingDiscount);
  const bookingData= useSelector(({calendar}) => calendar &&
      calendar.allBooking.filter((row)=>row.id===bookingId)[0]);
  const rentalDetail = useSelector(({rentals})=>rentals
      && rentals.rentals.filter((row)=>row.id===bookingData.rentalId)[0]);
  const currentCharges=useSelector(({calendar})=>calendar && calendar.currentCharges);
  const [newTotal, setNewTotal] = useState(bookingDiscount ? bookingDiscount.totalPrice:0.0);
  const [discount,setDiscount]=useState({
    name:bookingDiscount?bookingDiscount.discountName:"",
    amount:bookingDiscount?bookingDiscount.discountAmount:0.0,
  });
  const [charges,setCharges]=useState({
    name:bookingDiscount?bookingDiscount.chargeName:"",
    amount:bookingDiscount?bookingDiscount.chargeAmount:0.0,
  });
  const [editableMode,setEditableMode]=useState(bookingData && bookingDiscount && bookingData.price!==bookingDiscount.totalPrice);
  const [overWrittenTotal,setOverWrite]=useState(bookingData ? bookingData.price :0.0)
  const loadBookingStatus=useSelector(({calendar})=>calendar && calendar.actionForBooking)
  const [fee,setFee]=useState(bookingDiscount && bookingDiscount.fee);
  const [tax,setTax]=useState(bookingDiscount && bookingDiscount.tax)
  const [extraGuestFee,setExtraGuestFee]=useState(bookingDiscount && bookingDiscount.extraGuestFee);
  const [baseRate,setBaseRate]=useState(bookingDiscount && bookingDiscount.baseRate)
  const [errorDisc,setErrorDisc]=useState(null);

  useEffect(()=>{
    dispatch(fetchDiscountByBooking(bookingId));
    dispatch(fetchBookingById(bookingId));
  },[])

  useEffect(()=>{
    return()=>{
      dispatch(resetCharges());
    }
  },[])

  useEffect(()=>{
    if(bookingDiscount && bookingDiscount!==[] && (bookingDiscount.discountAmount!==discount.amount || bookingDiscount.chargeAmount!==charges.amount)){
      const payload={
        arrive:bookingData.arrive,
        depart:bookingData.depart,
        noOfGuests:Number(bookingData.noOfAdults+bookingData.noOfChildren),
        rentalId:bookingData.rentalId,
        extraCharge:charges.amount,
        discount:discount.amount
      }
      dispatch(chargeCalculation(payload))
    }
    else if(bookingDiscount.discountAmount===discount.amount || bookingDiscount.chargeAmount===charges.amount){
      setFee(bookingDiscount.fee)
      setTax(bookingDiscount.tax)
      setBaseRate(bookingDiscount.baseRate)
      setExtraGuestFee(bookingDiscount.extraGuestFee)
      setNewTotal(bookingDiscount.totalPrice)
      setOverWrite(bookingData.price)
    }
  },[discount.amount,charges.amount])

  useEffect(()=>{

    if(currentCharges && currentCharges.total){
      if(currentCharges.total<0){
        setErrorDisc("Please enter valid discount amount!");
      }else{
        setErrorDisc(null);
      }
      const tmp=currentCharges.total;
      setNewTotal(Number(tmp).toFixed(2));
      setOverWrite(Number(tmp).toFixed(2));
      setTax(currentCharges.taxes);
      setFee(currentCharges.fees);
      setBaseRate(currentCharges.daily_rate);
      setExtraGuestFee(currentCharges.extra_guest_fees);
    }
  },[currentCharges])

  useEffect(()=>{
    if(bookingDiscount && bookingDiscount!==[]){
      setDiscount({
        name:bookingDiscount.discountName,
        amount:bookingDiscount.discountAmount,
      })
      setCharges({
        name:bookingDiscount.chargeName,
        amount:bookingDiscount.chargeAmount,
      })
      setFee(bookingDiscount.fee)
      setTax(bookingDiscount.tax)
      setBaseRate(bookingDiscount.baseRate)
      setExtraGuestFee(bookingDiscount.extraGuestFee)
      setNewTotal(bookingDiscount.totalPrice)
    }
  },[bookingDiscount])

  useEffect(()=>{
    if(bookingData && bookingDiscount){
      setEditableMode(bookingData.price!==bookingDiscount.totalPrice)
    }
  },[bookingData,bookingDiscount])

  useEffect(()=>{

  })

  const handleSubmit = () => {
    let payload=[];
    if(!errorDisc && editableMode && newTotal!==overWrittenTotal){
      payload={
        bookingId:bookingId,
        rentalId:bookingData.rentalId,
        discountName: discount.amount > 0 && discount.name === "" ? "Discount" : discount.name,
        discountAmount:discount.amount,
        chargeName: charges.amount > 0 && charges.name === "" ? "Extra charge" : charges.name,
        chargeAmount:charges.amount,
        tax: tax,
        fees:fee,
        extraGuestFee:extraGuestFee,
        baseRate:baseRate,
        totalPrice:+Number(newTotal).toFixed(2),
        bookingPrice:+Number(overWrittenTotal).toFixed(2),
        nights:bookingData.nights,
        noOfGuests:bookingData.noOfAdults + bookingData.noOfChildren
      }
    }else{
      payload={
        bookingId:bookingId,
        rentalId:bookingData.rentalId,
        discountName: discount.amount > 0 && discount.name === "" ? "Discount" : discount.name,
        discountAmount:discount.amount,
        chargeName: charges.amount > 0 && charges.name === "" ? "Extra charge" : charges.name,
        chargeAmount:charges.amount,
        tax: tax,
        fees:fee,
        extraGuestFee:extraGuestFee,
        baseRate:baseRate,
        totalPrice:+Number(newTotal).toFixed(2),
        bookingPrice:+Number(newTotal).toFixed(2),
        nights:bookingData.nights,
        noOfGuests:bookingData.noOfAdults + bookingData.noOfChildren
      }
    }
    dispatch(editDiscount(payload,bookingDiscount.id));
  };

  return (
    <div className="p-3">
      <Loading loadingStatus={loadBookingStatus.loading}/>
      <div className="pb-4 d-flex flex-column align-items-center">
        <div className={styles.subHeader}> Total due: {`${overWrittenTotal} ${getSymbolFromCurrency(rentalDetail.currency)}`} </div>
        <div className={styles.defaultFont}>Payment status: {`${bookingData.paymentStatus}`} </div>
      </div>
      <div className="pb-2 d-flex flex-lg-row flex-column">
        <div className={`${styles.divWrapper} col-12 col-lg-6`}>
          <div className="pb-2 d-flex flex-direction-column">
            <div className={styles.subHeader}>Payment calculation </div>
            <div className={styles.toolTipIconContainer}>
              <InfoOutlinedIcon
                fontSize="small"
                color="inherit"
                data-tip
                data-for="paymentCalculation"
                className={styles.toolTipIcon}
              />
              <ReactTooltip
                place="bottom"
                type="dark"
                id="paymentCalculation"
                effect="solid"
                className={styles.toolTip}
              >
                <span>
                  Calculation based on your rates settings for this rental. You can change rates, taxes, fees and
                  discount for This rental by clicking on 'Rates' in the top menu.
                </span>
              </ReactTooltip>
            </div>
          </div>
          <Row className={styles.form_wrapper}>
            <div className={`${editableMode ? `text-muted`:``} ${styles.payment_title}`}>Base rate:</div>
            <div className={`${editableMode ? `text-muted`:``} ${styles.payment_data}`}>{`${baseRate} ${getSymbolFromCurrency(rentalDetail.currency)}`}</div>
          </Row>
          <Row className={styles.form_wrapper}>
            <div className={`${editableMode ? `text-muted`:``} ${styles.payment_title}`}>Extra guest fee:</div>
            <div className={`${editableMode ? `text-muted`:``} ${styles.payment_data}`}>{`${extraGuestFee} ${getSymbolFromCurrency(rentalDetail.currency)}`}</div>
          </Row>
          <Row className={styles.form_wrapper}>
            <div className={`${editableMode ? `text-muted`:``} ${styles.payment_title}`}>Total rent: </div>
            <div className={`${editableMode ? `text-muted`:``} ${styles.payment_data}`}>{`${baseRate+extraGuestFee} ${getSymbolFromCurrency(rentalDetail.currency)}`}</div>
          </Row>
          <Row className={styles.form_wrapper}>
            <div className={`${editableMode ? `text-muted`:``} ${styles.payment_title}`}>Discount:</div>
            <div className={`${editableMode ? `text-muted`:``} ${styles.payment_data}`}>{`${discount.amount} ${getSymbolFromCurrency(rentalDetail.currency)}`}</div>
          </Row>
          <Row className={styles.form_wrapper}>
            <div className={`${editableMode ? `text-muted`:``} ${styles.payment_title}`}>Extra charge: </div>
            <div className={`${editableMode ? `text-muted`:``} ${styles.payment_data}`}>{`${charges.amount} ${getSymbolFromCurrency(rentalDetail.currency)}`}</div>
          </Row>
          <Row className={styles.form_wrapper}>
            <div className={`${editableMode ? `text-muted`:``} ${styles.payment_title}`}>Fees:</div>
            <div className={`${editableMode ? `text-muted`:``} ${styles.payment_data}`}>{`${fee} ${getSymbolFromCurrency(rentalDetail.currency)}`}</div>
          </Row>
          <Row className={styles.form_wrapper}>
            <div className={`${editableMode ? `text-muted`:``} ${styles.payment_title}`}>Taxes: </div>
            <div className={`${editableMode ? `text-muted`:``} ${styles.payment_data}`}>{`${tax} ${getSymbolFromCurrency(rentalDetail.currency)}`}</div>
          </Row>
          <br/>
          {/*<Row className={styles.form_wrapper}>*/}
          {/*  <div className={styles.payment_title}>*/}
          {/*    <i>Total due:</i>*/}
          {/*  </div>*/}
          {/*  <div className={styles.payment_data}>{`${Number(baseRate)+*/}
          {/*  Number(bookingDiscount.extraGuestFee)+Number(discount.amount)-Number(charges.amount)} ${getSymbolFromCurrency(rentalDetail.currency)}`}</div>*/}
          {/*</Row>*/}
          <Row className={styles.form_wrapper}>
            <div className={`${editableMode ? `text-muted`:``} ${styles.payment_title}`}>
              <b>Total due:</b>
            </div>
            <div className={`${editableMode ? `text-muted`:``} ${styles.payment_data}`}>{`${newTotal} ${getSymbolFromCurrency(rentalDetail.currency)}`}</div>
          </Row>
        </div>
        <div className={`col-12 col-lg-6 mt-5 mt-lg-0 ${styles.divWrapper}`}>
          <div className="pb-2 d-flex flex-direction-column">
            <div className={styles.subHeader}>Add discount: </div>
          </div>
          <div className={`d-flex align-items-center ${styles.form_wrapper}`}>
            <div className={`${editableMode?`text-muted`:``} ${styles.payment_title_sm}`}>Name:</div>
            <input
                type={'text'}
                className={styles.payment_entry_sm}
                value={discount.name}
                onChange={(e) => setDiscount({
                  ...discount,
                  name:e.target.value,
                })}
                disabled={editableMode}
            />
          </div>
          <div className={`d-flex align-items-center ${styles.form_wrapper}`}>
            <div className={`${editableMode?`text-muted`:``} ${styles.payment_title_sm}`}>Amount:</div>
              <div>
                <input
                    type={'number'}
                    className={`${styles.payment_entry_amount}`}
                    value={discount.amount}
                    onChange={(e) => setDiscount({
                      ...discount,
                      amount:Math.abs(Number(e.target.value))
                    })}
                    min={0}
                    disabled={editableMode}
                />
                <div className={styles.customError}>
                  {errorDisc?errorDisc:""}
                </div>
              </div>
              <div className={`${editableMode?`text-muted`:``} ${styles.symbolClass} ml-2`}>{`${getSymbolFromCurrency(rentalDetail.currency)}`}</div>
          </div>
          <br />
          <div className="pb-2 d-flex flex-direction-column">
            <div className={styles.subHeader}>Add extra charge: </div>
          </div>
          <div className={`d-flex align-items-center ${styles.form_wrapper}`}>
            <div className={`${editableMode?`text-muted`:``} ${styles.payment_title_sm}`}>Name:</div>
            <input
                type={'text'}
                className={styles.payment_entry_sm}
                value={charges.name}
                onChange={(e) => setCharges({
                  ...charges,
                  name:e.target.value
                })}
                min={0}
                disabled={editableMode}
            />
          </div>
          <div className={`${styles.form_wrapper} d-flex align-items-center`}>
            <div className={`${editableMode?`text-muted`:``} ${styles.payment_title_sm}`}>Amount:</div>
            <input
                type={'number'}
                className={styles.payment_entry_amount}
                value={charges.amount}
                onChange={(e) => setCharges({
                  ...charges,
                  amount:Math.abs(Number(e.target.value))
                })}
                disabled={editableMode}
            />
            <div className={`${editableMode?`text-muted`:``} ${styles.symbolClass} ml-2`}>{`${getSymbolFromCurrency(rentalDetail.currency)}`}</div>
          </div>
          <br />
          <br />
          <br />
          <div className={`pb-2 d-flex flex-direction-column justify-content-between ${styles.withToggle}`}>
            <div className={`d-flex flex-direction-row`}>
              <div className={styles.subHeader}>Overwrite total due </div>
              <div className={styles.toolTipIconContainer}>
                <InfoOutlinedIcon
                    fontSize="small"
                    color="inherit"
                    data-tip
                    data-for="overWrite"
                    className={styles.toolTipIcon}
                />
                <ReactTooltip place="top" type="dark" id="overWrite" effect="solid" className={styles.toolTip}>
                <span>
                 Overwrite the total due for this reservation. Doing this will erase the system payment
                  calculation and any charges or discounts you set for this booking. This will become the total
                  amount due for this booking.<br/>
                  If you set this amount to 0, the system’s payment calculations will be used again, along with
                  charges or discounts you set above. If you intend not to charge for this booking, set the
                  discount amount equal to the total rent.
                </span>
                </ReactTooltip>
              </div>
            </div>
            <Toggle
                icons={false}
                id={"overWriteToggle"}
                checked={editableMode}
                onChange={()=>setEditableMode(!editableMode)}
            />
          </div>
          <div className={`d-flex align-items-center ${styles.form_wrapper}`}>
            <div className={styles.payment_title_sm}>
              <b>New total: </b>
            </div>
            <input
              type={'number'}
              className={styles.payment_entry_amount}
              onChange={(e) => setOverWrite(Number(e.target.value))}
              value={overWrittenTotal}
              disabled={!editableMode}
            />
            <div className={`${styles.symbolClass} ml-2`}>{`${getSymbolFromCurrency(rentalDetail.currency)}`}</div>
          </div>
        </div>
      </div>
      <div className={`d-flex justify-content-center ${styles.button_group}`}>
        <div>
          <button className={styles.settings_save} onClick={handleSubmit} disabled={errorDisc}>
            Save changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default Payment;
