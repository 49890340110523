import React, {useEffect, useState} from 'react';
import {toast} from "react-toastify";
import getSymbolFromCurrency from 'currency-symbol-map';
import { Form,InputGroup } from 'react-bootstrap';
import { Formik } from 'formik';
import styles from '../../../rates.module.css';
import MultiSelect from '../../BulkDefaultChanges/MultiSelect';
import {useSelector} from "react-redux";

function FeeForm({schema, handleSubmit, properties, initValue, currency, onHide}){
    const rentals = useSelector(({ rentals }) => rentals && rentals.rentals);
    const getValue=()=>{
        if(initValue && initValue.rentalId){
            const row=rentals.find((row)=>{
                return row.id===initValue.rentalId
            })
            return [{value:row.id, label:row.name}];
        }else{
            return [];
        }
    }
    const [selectedCurr,setSelectedCurr]=useState(initValue && initValue.currencyVal?initValue.currencyVal:currency);
    const [count,setCount]=useState(0);
    const [selectedRentals, setSelectedRentals] = useState(()=>getValue());
    const currencyVal=properties && properties.length!==0 && properties[0].currency;

    const sameCurrency=(row)=>{
        return row.currency===currencyVal;
    }
    // const rentalInput=document.getElementById("rentalSelection");
    function diffCurrencyWarning() {
        if (!properties.every(sameCurrency)) {
            setCount(count+1);
        }
    }
    useEffect( () => {
        count === 1 && toast.warn(`Different currencies used in different rentals. Check the changes.`
            ,{
                style:{
                    color:'#484848'
                }
            });
    }, [count]);

    const getOptions = () => {
        const tmp=[];
        rentals.map((row) => (tmp.push({ value: row.id, label: row.name })));
        return tmp;
    }

    const onSubmit=(data)=>{
        // console.log("Onsubmit is working ",data);
        if(selectedRentals && selectedRentals.length!==0){
            handleSubmit(data,selectedRentals)
        }
    }

    const handleReset=()=>{
        setSelectedRentals([]);
        onHide();
    }

    const validate=(values)=>{
        let errors = {};
        if(values.feeType === "percentage" && values.modality !== "per stay"){
            errors.modality = 'Select per stay as modality while fee type is percentage.'
        }
        if(!selectedRentals.length){
            errors.multiRentals = 'Rental selection is a required field'
        }
        return errors;
    }

    return(
        <>
        <Formik
            validationSchema={schema}
            initialValues={initValue ? {
                ...initValue,
                multiRentals: selectedRentals,
                rentalId:undefined
            } : {
              name: '',
              amount: '',
              feeType: '',
              modality: '',
              multiRentals: selectedRentals,
            }}
            onSubmit={onSubmit}
            validateOnChange={false}
            validateOnBlur={false}
            validate={validate}
        >
        {({ 
         values,errors, handleChange, handleSubmit, initialValues
        }) => (
            <Form onSubmit={handleSubmit}>
                <Form.Row className='py-1'>
                    <Form.Label className={`col-sm-4 col-form-label`}>Select rental:*</Form.Label>
                    <div className="col-md-8 col-lg-6">
                        <MultiSelect
                            options={getOptions()}
                            value={selectedRentals}
                            name="multiRentals"
                            onChange={setSelectedRentals}
                            onBlur={diffCurrencyWarning}
                            disabled={initValue && initValue.rentalId}
                            placeholder={"---Select Rental---"}
                            fieldHasError={!!errors.multiRentals}
                        />
                        <div style={{fontSize:'0.8em',color:'#dc3545',padding:'0em'}}>{errors.multiRentals}</div>
                    </div>
                </Form.Row>
              <br/>
              <Form.Row>
                  <Form.Label className="col-sm-4 col-form-label">Name of fee:</Form.Label>
                  <div className="col-md-5 col-lg-6">
                    <Form.Control
                      type="text"
                      name="name"
                      onChange={handleChange}
                      defaultValue={initialValues.name}
                      className={styles.form_entry}
                      isInvalid={!!errors.name}
                    />
                      <div style={{fontSize:'0.8em',color:'#dc3545',padding:'0em'}}>{errors.name}</div>
                  </div>
              </Form.Row>
              <br/>
              <Form.Row>
                  <Form.Label className="col-sm-4 col-form-label">Type of fee:</Form.Label>
                  <div className="col-md-5 col-lg-6">
                    <Form.Check
                      inline
                      name="feeType"
                      label="percentage"
                      id={"percentage"}
                      onChange={handleChange}
                      value="percentage"
                      type="radio"
                      defaultChecked={initialValues.feeType==="percentage"}
                    />
                    <Form.Check
                      inline
                      name="feeType"
                      label="flat rate"
                      id={"flat rate"}
                      onChange={handleChange}
                      value="flat rate"
                      type="radio"
                      defaultChecked={initialValues.feeType==="flat rate"}
                    />
                  <div style={{fontSize:'0.8em',color:'#dc3545',padding:'0em'}}>{errors.feeType}</div>
                  </div>
              </Form.Row>
              <br/>
              <Form.Row>
                  <Form.Label className="col-sm-4 col-form-label">Amount:</Form.Label>
                  <div className="col-md-5 col-lg-6">
                  <InputGroup className="mb-1">
                    <Form.Control
                      type="float"
                      name="amount"
                      onChange={handleChange}
                      defaultValue={initialValues.amount}
                      className={styles.form_entry}
                      isInvalid={!!errors.amount}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text id="basic-addon2" className={styles.form_entry}
                      >{(values.feeType==="percentage")?"%":(getSymbolFromCurrency(`${selectedCurr}`))}</InputGroup.Text>
                    </InputGroup.Append>
                    <Form.Control.Feedback type="invalid">{errors.amount}</Form.Control.Feedback>
                  </InputGroup>
                </div>
              </Form.Row>
              <br/>
              <Form.Row>
                  <Form.Label className="col-sm-4 col-form-label">Modality:</Form.Label>
                  <div className="col-md-8">
                  <Form.Check
                    inline
                    name="modality"
                    label="per stay"
                    id={"perStay"}
                    onChange={handleChange}
                    value="per stay"
                    type="radio"
                    defaultChecked={initialValues.modality==="per stay" || values.feeType==="percentage"}
                    checked={values.feeType=="percentage"}
                  />
                  <Form.Check
                    inline
                    name="modality"
                    label="per night"
                    id={"perNight"}
                    onChange={handleChange}
                    value="per night"
                    type="radio"
                    disabled={values.feeType==="percentage"}
                    defaultChecked={initialValues.modality==="per night"}
                  />
                   <Form.Check
                    inline
                    name="modality"
                    label="per person"
                    id={"perPerson"}
                    onChange={handleChange}
                    value="per person"
                    type="radio"
                    disabled={values.feeType==="percentage"}
                    defaultChecked={initialValues.modality==="per person"}
                  />
                  <Form.Check
                    inline
                    name="modality"
                    label="per person per night"
                    id={"pppn"}
                    onChange={handleChange}
                    value="pppn"
                    type="radio"
                    disabled={values.feeType==="percentage"}
                    defaultChecked={initialValues.modality==="pppn"}
                  />
                  <div style={{fontSize:'0.8em',color:'#dc3545',padding:'0em'}}>{errors.modality}</div>
                  </div>
              </Form.Row>
              <br/>
              <div className='p-2 d-flex justify-content-center'>
                  <div className='col-md-8 d-flex justify-content-around'>
                      <button  type="submit" className={`col-lg-5 p-1 mx-2 col-sm-5 ${styles.allBtn}`} >Save</button>
                      <button
                          type="reset"
                          className={`col-lg-5 p-1 mx-2 col-sm-5 ${styles.allBtn}`}
                          style={{backgroundColor:"#6c757d"}}
                          onClick={handleReset}
                      >Discard</button>
                  </div>
              </div>
          </Form>
        )}
        </Formik>
        </>
    )
}

export default FeeForm;