import React,{useState,useEffect} from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';
import { Modal } from 'react-bootstrap';
import styles from '../../../Rentals/rentals.module.css';
import styles2 from './viewBooking.module.css';
import {useSelector} from "react-redux";
import {tConvert} from '../../../../../../common/functions/utils';
import { useDispatch } from 'react-redux';
import {zeroPad} from '../../../../../../common/functions/utils'
import { deleteBooking } from '../../../../../../general_redux/calendar/actions';

function ViewBooking(props) {
  const guestDetail = useSelector(({guests})=>guests && guests.guestByBooking
      && guests.guestByBooking.length!==0 && guests.guestByBooking[0] );
  const setting=useSelector(({generalSetting})=>(generalSetting && generalSetting.setting));
  const guestByBooking=useSelector(({guests})=>(guests && guests.guestByBooking));

  const { data } = props;
    const rentalDetail = useSelector(({rentals})=>rentals
        && rentals.rentals.filter((row)=>row.id===data.rentalId)[0]);
  const dispatch = useDispatch();
  const [delBooking,setDelBooking]=useState(false);

  const handleDelete=()=>{
    dispatch(deleteBooking(data.id))
    setDelBooking(false);
    props.onHide();
  }

  return (
      <>
          <Modal
              show={props.show}
              size="sm"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={()=>{
                  setDelBooking(false);
                  props.onHide();
              }
          }>
              <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter" className={styles.modalTitle}>{guestDetail.name}</Modal.Title>
              </Modal.Header>
              <Modal.Body className={styles.deleteModalBody}>
                  {data && data.length !== 0 && (
                      <div className={styles2.mainContainer}>
                          <div className={styles2.sideText}>{(guestDetail.phoneNo!=="")?
                              <a href={`https://api.whatsapp.com/send?phone=${guestDetail.phoneNo}`} target={"_blank"} style={{textDecoration:"none",color:"grey"}}><span><i className={`fab fa-whatsapp ${styles.smallIconWA}`}/>{guestDetail.phoneNo}</span></a>:"Phone number unavailable."}</div>
                          <div className={styles2.sideText}><a href={`mailto: ${guestDetail.emailId}`}target={"_blank"} style={{textDecoration:"none",color:"grey"}}><i className={`far fa-envelope ${styles.smallIcon}`}/>{guestDetail.emailId}</a></div>
                          <br />
                          <div className={styles2.sideText}>{`Booking Number: ${new Date().getUTCFullYear()}${zeroPad(data.id, 4)}`}</div>
                          <div className={styles2.mainText}>{`Start date : ${data.start_time.format('Do MMMM, YYYY')}`}</div>
                          <div className={styles2.mainText}>{`End date : ${data.end_time.format('Do MMMM, YYYY')}`}</div>
                          <div className={styles2.sideText}>{`${data.nights} Nights `}</div>
                          <div className={styles2.sideText}>{`Check-in time: ${(setting.timeDisplay==="AM_PM")?
                              tConvert(data.checkInTime):data.checkInTime}`}</div>
                          <div className={styles2.sideText}>{`Check-out time:  ${(setting.timeDisplay === "AM_PM") ?
                              tConvert(data.checkOutTime) : data.checkOutTime}`} </div>
                          <div className={styles2.mainText}>{`Total guests : ${Number(data.noOfChildren)+Number(data.noOfAdults)}`}</div>
                          <div className={styles2.sideText}>{`Adults ${data.noOfAdults} | Children ${data.noOfChildren}`}</div>
                          <div className={styles2.mainText}>{`Price : ${data.price} ${getSymbolFromCurrency(rentalDetail.currency)}`}</div>
                          <div className={styles2.sideText}>{`Status : ${data.paymentStatus}`}</div>
                          <div className={styles2.mainText}>
                              <div>{`Colour :`}</div>
                              <div
                                  className={styles2.containerBox}
                                  style={{background:`${data.color}`}}
                                  key={data.color}
                              />
                          </div>
                          <div className={styles2.mininote}>
                              <span>{`${data.miniNotes}`}</span>
                          </div>
                          {(data.bookingType==="weekly" || data.bookingType==="monthly") && (
                            <div className={`${styles2.mininote} ${styles2.special} `}>
                                <span>{`Fixed term reservation`}</span>
                            </div>
                          )}
                      </div>
                  )}
              </Modal.Body>
              {(delBooking ===true) ?(
                  <div className={`{styles.modalFooter} p-2 d-flex justify-content-center `}>
                      <button type="submit" style={{backgroundColor:"#dc3545",width:'145px'}}
                              className={styles.btnClass} onClick={handleDelete}>
                          Confirm Delete
                          <span>
                    <i className={`fa fa-check-circle ${styles2.icons}`}/>
                  </span>
                      </button>
                      <button  type="submit" style={{backgroundColor:"#439a86"}}
                               className={styles.btnClass} onClick={()=>setDelBooking(false)}>
                          Cancel
                          <span>
                    <i className={`fa fa-undo ${styles2.icons}`}/>
                  </span>
                      </button>
                  </div>
              ):(
                  <div className={`{styles.modalFooter} p-2 d-flex justify-content-center `}>
                      <button type="submit" style={{backgroundColor:"#dc3545"}}
                              className={styles.btnClass} onClick={()=>setDelBooking(true)}>
                          Delete
                      </button>
                      <button  type="submit" style={{backgroundColor:"#439a86"}}
                               className={styles.btnClass} onClick={props.onEdit} disabled={guestByBooking && guestByBooking.length===0}>
                          Edit
                      </button>
                  </div>
              )}
          </Modal>
      </>

  );
}
export default ViewBooking;
