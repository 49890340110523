import React from 'react';
import {Button,Modal} from 'react-bootstrap';
import styles from "../../modules/view/Components/UserSetting/usersetting.module.css";

function ConfirmMessage(props){
    return (
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className={styles.modalHeader}
          >
            {props.confirmHeader}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.deleteModalBody}>
          <p>{props.confirmBody}</p>
        </Modal.Body>
        <div className={styles.modalFooter}>
          {props.confirmDelete ? (
            <>
              <button
                type="submit"
                onClick={props.onConfirmAct}
                className={`mr-3 ${styles.delBtn} ${styles.delConfirmBtn}`}
              >
                Confirm Delete
                <span>
                  <i className={`fa fa-check-circle ${styles.confirmIcon}`} />
                </span>
              </button>
              <button onClick={props.onHide} className={`${styles.savBtn} ${styles.cancelConfirm}`}>
                Cancel
                <span>
                    <i className={`fa fa-undo ${styles.confirmIcon}`}/>
                </span>
              </button>
            </>
          ) : (
            <>
              <button onClick={props.onHide} className={styles.savBtn}>
                Cancel
              </button>
              <button onClick={props.onConfirmAct} className={styles.delBtn}>
                {props.deleteBtnTitle ? props.deleteBtnTitle : "Delete"}
              </button>
            </>
          )}
        </div>
      </Modal>
    );
}
export default ConfirmMessage;