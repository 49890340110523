import React, { useState,useEffect } from 'react';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import styles from './blockdate.module.css';
import dateStyles from "../../../../../../config/dateStyles.module.css";
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import CustomDate from '../../../../../../components/CustomDate';
// import $ from "jquery";

function AddBlockDate({handleSave, initValues, handleEdit, isEdit}) {
  const rentals = useSelector(({ rentals }) => rentals && rentals.rentals);
  const dateDisplay = useSelector(({generalSetting})=>generalSetting && generalSetting.setting && generalSetting.setting.dateDisplay);
  const [departure,setDeparture] = useState("");
  const [arrival,setArrival] = useState("");
  const {numberOf, allowBookingFor} = useSelector(({generalSetting}) => generalSetting && generalSetting.setting);
  //=============================================
  // SCHEMA REQUIREMENT FOR FORM VALIDATION
  //=============================================
  const BlockDateSchema = yup.object().shape({
    rental: yup.string().required('Rental is required!'),
    eventName: yup.string().test('len', 'Must be less than 50 characters', val => (val===undefined || (val && (val.length===0 || val.length<=50)))),
    arrival: yup.date().required('Start date is required'),
    departure: yup.date().required('End date is required!'),
  });

  const initialValues = {
    rental:isEdit? initValues.rentalId : '',
    eventName: isEdit ? initValues.title : '',
    arrival: isEdit ? initValues.arrive : '',
    departure: isEdit ? initValues.depart : '',
  }

  //=============================================
  // Handle Save
  //=============================================

  function handleSubmit(data) {
    const payload = {
      id: (isEdit)? initValues.id: undefined,
      bookingType: 'blockdates',
      rentalId: Number(data.rental),
      title: (data.eventName!=="")?data.eventName:"Blocked",
      arrive: moment(arrival).format().slice(0,10),
      depart: moment(departure).format().slice(0,10),
      checkInTime:"08:00",
      checkOutTime:"07:00",
      noOfAdults:0,
      noOfGuests:0,
      noOfChildren:0,
      source:"Beehaz",
      tax:0,
      price:0,
      color: "grey",
      paymentStatus:"Paid",
      miniNotes:"",
      nights:moment(data.departure,"YYYY-MM-DD").diff(moment(data.arrival,"YYYY-MM-DD"),'day'),
      status:"Booked",
      notes:'',
      fees:0.0,
    }
    isEdit ? handleEdit(payload) : handleSave(payload);
  }

  //=============================================

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title className={styles.modalTitle}>Add event/block dates</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={BlockDateSchema}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, setFieldValue }) => {
            const today = moment().format('YYYY-MM-DD');
            return (
              <>
                <Form>
                  <div className={styles.form_wrapper}>
                    <div className={styles.form_title}>Select rental: </div>
                    <Field
                      as="select"
                      name="rental"
                      className={errors.rental && touched.rental ? styles.form_entry_err : styles.form_entry}
                    > <option key={""} value={""}>{"---Select Rental---"}</option>
                      {rentals && rentals.map((r) => <option key={r.id} value={r.id} >{r.name}</option>)}
                    </Field>
                    <div className={errors.rental && touched.rental ? styles.form_error : styles.form_error_hidden}>
                      {errors.rental}
                    </div>
                  </div>

                  <div className={styles.form_wrapper}>
                    <div className={styles.form_title}>Name of event: </div>
                    <Field
                      name="eventName"
                      className={styles.form_entry}
                    />
                    <div className={errors.eventName && touched.eventName ? styles.form_error : styles.form_error_hidden}>
                      {errors.eventName}
                    </div>
                  </div>
                  <div className={styles.form_wrapper}>
                    <div className={styles.form_title}>Start date: </div>
                    <CustomDate
                      id={"block_date_arrival"}
                      name={"arrival"}
                      handleChange={(date)=>{
                        setArrival(date);
                        setFieldValue('arrival',date);
                      }}
                      value={arrival}
                      hasError={errors.arrival}
                      max={(departure)?departure:new Date(`${moment().add(numberOf,allowBookingFor === "months" ? 'M' : 'y').format('YYYY-MM-DD')}`)}
                    ></CustomDate>
                    <div className={errors.arrival && touched.arrival ? styles.form_error : styles.form_error_hidden}>
                      {errors.arrival}
                    </div>
                  </div>

                  <div className={styles.form_wrapper}>
                    <div className={styles.form_title}>End date: </div>
                    <CustomDate
                      id={"block_date_departure"}
                      name={"departure"}
                      handleChange={(date)=>{
                        setDeparture(date);
                        setFieldValue('departure',date);
                      }}
                      value={departure}
                      min={values.arrival}
                      hasError={errors.departure}
                      max={new Date(`${moment().add(numberOf,allowBookingFor === "months" ? 'M' : 'y').format('YYYY-MM-DD')}`)}
                    ></CustomDate>
                    <div
                      className={errors.departure && touched.departure ? styles.form_error : styles.form_error_hidden}
                    >
                      {errors.departure}
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <button className={styles.settings_save} type="submit">
                      Save changes
                    </button>
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      </Modal.Body>
    </>
  );
}

export default AddBlockDate;
