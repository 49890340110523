import React, {useEffect, useState} from 'react';
import { Form } from 'react-bootstrap';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import WeekDayPick from '../../../../../../../common/components/weekDayPick';
import MultiSelect from '../../BulkDefaultChanges/MultiSelect';
import styles from '../ratesCalendar.module.css';
import CustomDate from "../../../../../../../components/CustomDate";
import {toast} from "react-toastify";
import moment from 'moment';

function RateForm({schema, updatedSubmit, onHide}) {
    const rentals = useSelector(({ rentals }) => rentals && rentals.rentals);
    const [selectedRentals, setSelectedRentals] = useState([]);
    const [selectedDays, setSelectedDays] = useState([]);
    const [isRest,setIsReset]=useState(false);
    const [rate,setRate]=useState("");
    const currencyVal=rentals && rentals.length!==0 && rentals[0].currency;
    const [count,setCount]=useState(0);
    const [dateFrom,setDateFrom] = useState("");
    const [dateTo,setDateTo] = useState("");

    const sameCurrency=(row)=>{
        return row.currency===currencyVal;
    }
    // const rentalInput=document.getElementById("rentalSelection");
    function diffCurrencyWarning() {
        if (!rentals.every(sameCurrency)) {
            setCount(count+1);
        }
    }

    const initialValues = {
        multiRentals: [],
        dateRange: {},
        rate: "",
        minStay: "",
        multiDays: [],
    }
    const resetForm =(handleReset)=>{
        handleReset();
        setIsReset(true);
        setSelectedRentals([]);
        onHide();
    }
    const getOptions = () => {
        const tmp=[];
        rentals.map((row) => (tmp.push({ value: row.id, label: row.name })));
        return tmp;
    }

    const handleSubmit = data => {
        const updatedData = {
            rate: rate,
            minStay: data.minStay,
            multiRentals: selectedRentals,
            multiDays: selectedDays,
            dateRange: {dateFrom: moment(dateFrom).format().slice(0,10), dateTo: moment(dateTo).format().slice(0,10)},
        }
        updatedSubmit( updatedData );
    }

    useEffect( () => {
        count === 1 && toast.warn(`Different currencies used in different rentals. Check the changes.`
            ,{
                style:{
                    color:'#484848'
                }
            });
    }, [count]);

    const validate=(values)=>{
        let errors = {};
        if(!selectedRentals.length){
            errors.multiRentals = 'Rental selection is a required field'
        }
        return errors;
    }

    return(
        <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={handleSubmit}
            validateOnChange={false}
            validateOnBlur={false}
            validate={validate}
        >
        {({ errors,
              handleChange,
              handleSubmit,
              handleReset,
              setFieldValue}) => (
            <Form onSubmit={handleSubmit}>
                <Form.Row className='py-1'>
                <Form.Label className={`col-md-4 ${styles.rateForm__label}`}>Select rental:*</Form.Label>
                    <div className="col-md-8 col-lg-7">
                        <MultiSelect
                          options={getOptions()}
                          value={selectedRentals}
                          name="multiRentals"
                          onChange={setSelectedRentals}
                          onBlur={diffCurrencyWarning}
                          fieldHasError={!!errors.multiRentals}
                          placeholder={"---Select Rental---"}
                        />
                        <div style={{fontSize:'0.8em',color:'#dc3545',padding:'3px 0em 0em'}}>{errors.multiRentals}</div>
                    </div>
                </Form.Row>
                <Form.Row className='py-1'>
                    <div className={`col-md-4  ${styles.rateForm__label}`}>Select date from:*</div>
                    <div className="col-md-8 col-lg-7 ">
                            <CustomDate
                                id={"dateFrom"}
                                name="dateFrom"
                                handleChange={(date)=>{
                                    setDateFrom(date);
                                    setFieldValue('dateFrom',date);
                                }}
                                value={dateFrom}
                                hasError={errors.dateFrom}
                                max={dateTo}
                            />
                            <div style={{fontSize:'0.8em',color:'#dc3545',padding:'0em'}}>{errors.dateFrom}</div>
                            {/* <CustomDate
                                id={"dateTo"}
                                name="dateTo"
                                handleChange={(date)=>{
                                    setDateTo(date);
                                    setFieldValue('dateTo',date);
                                }}
                                value={dateTo}
                                hasError={errors.dateTo}
                                min={dateFrom}
                            />
                            <div style={{fontSize:'0.8em',color:'#dc3545',padding:'0em'}}>{errors.dateTo}</div> */}
                    </div>
                </Form.Row>
                <Form.Row className='py-1'>
                    <div className={`col-md-4  ${styles.rateForm__label}`}>Select date Until:*</div>
                    <div className="col-md-8 col-lg-7 ">
                            {/* <CustomDate
                                id={"dateFrom"}
                                name="dateFrom"
                                handleChange={(date)=>{
                                    setDateFrom(date);
                                    setFieldValue('dateFrom',date);
                                }}
                                value={dateFrom}
                                hasError={errors.dateFrom}
                                max={dateTo}
                            />
                            <div style={{fontSize:'0.8em',color:'#dc3545',padding:'0em'}}>{errors.dateFrom}</div> */}
                            <CustomDate
                                id={"dateTo"}
                                name="dateTo"
                                handleChange={(date)=>{
                                    setDateTo(date);
                                    setFieldValue('dateTo',date);
                                }}
                                value={dateTo}
                                hasError={errors.dateTo}
                                min={dateFrom}
                            />
                            <div style={{fontSize:'0.8em',color:'#dc3545',padding:'0em'}}>{errors.dateTo}</div>
                    </div>
                </Form.Row>
                <Form.Row className='py-1'>
                  <Form.Label className={`col-md-4 ${styles.rateForm__label}`}>Set rate:</Form.Label>
                    <div className="col-md-8 col-lg-7">
                        <Form.Control
                            className={styles.rateForm__input}
                            type="float"
                            name="rate"
                            min={0}
                            value={rate}
                            onChange={(e)=>{
                                setRate(e.target.value);
                                handleChange(e);
                            }}
                            isInvalid={!!errors.rate }
                        />
                        <Form.Control.Feedback type="invalid">{errors.rate}</Form.Control.Feedback>
                    </div>
                </Form.Row>
                <Form.Row className='py-1'>
                  <Form.Label className={`col-md-4 ${styles.rateForm__label}`}>Set min. stay requirement: </Form.Label>
                    <div className="col-md-8 col-lg-7">
                        <Form.Control
                            className={styles.rateForm__input}
                            type="number"
                            name="minStay"
                            onChange={handleChange}
                            isInvalid={!!errors.minStay }
                        />
                        <Form.Control.Feedback type="invalid">{errors.minStay}</Form.Control.Feedback>
                    </div>
                </Form.Row>
                <Form.Row className='py-1'>
                  <Form.Label className={`col-md-4 ${styles.rateForm__label}`}>Apply only to following week days: </Form.Label>
                  <div className="col-md-8 col-lg-8 overflow-auto">
                    <WeekDayPick name='multiDays' selectedDays={ (days) => {
                        setSelectedDays(days);
                        setIsReset(false);
                    }} isReset={isRest}/>
                  </div>
                </Form.Row>
                <div className='p-2 d-flex justify-content-center'>
                    <div className='col-md-8 d-flex justify-content-around'>
                        <button  type="submit" className={`col-lg-5 p-1 mx-2 col-sm-5 ${styles.allBtn}`}>Save</button>
                        <button type="reset" className={`col-lg-5 p-1 mx-2 col-sm-5 ${styles.allBtn}`} style={{backgroundColor:"#6c757d"}} onClick={()=>resetForm(handleReset)} >Discard</button>
                    </div>
              </div>
          </Form>
        )}
        </Formik>
    )
}

export default RateForm;