import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import getSymbolFromCurrency from 'currency-symbol-map';
import {Box, Popover} from "@material-ui/core";
import Loading from '../../../../../../components/loading';
import AddFee from './AddFee/AddFee';
import ExtraGuestFee from './AddFee/ExtraGuestFee'
import { getFeeList, addFee, deleteFee, editFee,clearAction } from '../../../../../../general_redux/rates/actions';
import Table from '../../../../../../components/Table';
import styles from '../../rates.module.css';

function Fees() {
  const dispatch = useDispatch();
  const [addFeeModal, setAddFeeModal] = useState(false);
  const [extraGuestModal,setExtraGuestModal]=useState(false);
  const [edited, setEdited] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentDelete,setCurrentDelete] = useState(null);

  const cols = [
    { lable: 'Name', key: 'name' },
    { lable: 'Type', key: 'feeType' },
    { lable: 'Amount', key: 'amount' , numFormat:"1,000.00"},
    { lable: 'Modality', key: 'modality'},
    { lable: 'Actions', key: '' },
  ];

  const properties = useSelector(({ rentals }) => rentals && rentals.rentals);
  const currency =useSelector(({user})=>user && user.user && user.user.currency);
  const feesList = useSelector(({ rates }) => rates && rates.feesList);
  const actionForGetFeeList = useSelector(({ rates }) => rates && rates.actionForGetFeeList);
  const actionForEditFee=useSelector(({rates})=> rates && rates.actionForEditFee);
  const actionForAddFee = useSelector(({rates})=>rates && rates.actionForAddFee);
  const actionForDeleteFee=useSelector(({rates})=>rates && rates.actionForDeleteFee);
  const rateSettings = useSelector(({ rates }) => rates && rates.rateSettings);
  const actionForExtraGuest = useSelector(({rates}) => rates && rates.actionForExtraGuest);
  const [feeCategory,setFeeCategory]=useState([]);

  useEffect(() => {
    dispatch(getFeeList());
  }, []);

  useEffect(()=>{
    if(actionForGetFeeList.success || actionForEditFee.success || actionForAddFee.success || actionForDeleteFee.success){
      const tmp=[];
      console.log(feesList)
      if(feesList){
        properties.forEach((property)=>{
          const ls = feesList.filter((fee) => fee.rentalId === property.id);
          tmp[`${property.id}`]=ls;
        })
        setFeeCategory(tmp);
        dispatch(clearAction());
      }
    }
  },[actionForGetFeeList,actionForEditFee,actionForAddFee,actionForDeleteFee])

  const closeModal = (data) => {
      // const payload = {
      //   id: data.id,
      //   name: data.name,
      //   feeType: data.feeType,
      //   amount: data.amount,
      //   modality: data.modality,
      // };
    if (edited) {
      // console.log(data);
      dispatch(editFee(data));
    } else {
      dispatch(addFee(data));
    }
    setEdited(null);
    setAddFeeModal(false);
  };
  const deleteRow = (id) => {
    dispatch(deleteFee(id));
  };

  const editRow = (val) => {
    const payload={
      amount:val.amount,
      feeType:val.feeType,
      rentalId: {value:val.rentalId,label:val.rentalId},
      modality:val.modality,
      name:val.name,
      id:val.id
    }
    setEdited(val);
    setAddFeeModal(true);
  };
  const findCurrency = (id)=>{
    const tmp= properties.filter((rental)=>rental.id===Number(id))
    if(tmp.length!==0){
        return getSymbolFromCurrency(tmp[0].currency);
    }else{
        return "";
    }
  }
  const renderGuestFee = (rentalId) => {
    const rateSettingByRental = rateSettings.filter(item => item.rentalId === rentalId);
    console.log("rateSettingByRental",rateSettingByRental);
    return (
      <div className="my-2 d-flex align-items-center">
        <div className={styles.taxHeader}>Extra guest fee: </div>
        <div className="ml-2">
          {rateSettingByRental.length && rateSettingByRental[0].usdPerGuest !== 0 ? 
            `${findCurrency(rentalId)} ${rateSettingByRental[0].usdPerGuest} per guest after ${rateSettingByRental[0].guestPerNight} guests.` :
            'not added.'
          }
        </div>
      </div>
    )
  };

  const handleDelete=()=>{
    if(currentDelete && currentDelete.id){
      dispatch(deleteFee(currentDelete.id));
    }
    setAnchorEl(null);
    setCurrentDelete(null);
  }
  const handleConfirmDialog = (e,row) => {
    setCurrentDelete(row);
    setAnchorEl( anchorEl ? null : e.currentTarget);
  }

  const invoiceDeleteDialog = () => {
    return (
        <Popover
            id={"popover-1"}
            open={Boolean(anchorEl)}
            onClose={handleConfirmDialog}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: "center",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "center",
                horizontal: "right",
            }}
            PaperProps={{
                style: {
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    borderRadius: 0,
                    display: "flex",
                },
            }}
        >
            <div className={styles.contentWrap}>
                <div className={styles.deleteTitle}>
                    Are you sure you want to delete this fee?
                </div>
                <div className="text-center m-1">
                    <button
                        onClick={() => handleDelete()}
                        className={styles.delConfirmBtn}
                    >
                        Delete
                    </button>
                    <button
                        onClick={handleConfirmDialog}
                        className={styles.cancelBtn}
                    >
                        Cancel
                    </button>
                </div>
            </div>
            <Box
                className={styles.rightArrow}
            />
        </Popover>
    );
  };

  const renderFeesTable = useMemo(() => {
    return (
      <div className="col-lg-10 col-xl-8 px-0 mb-3">
        {properties &&
          properties.map((property, index) => (
            <div key={index} className="mb-4">
              <div className={styles.taxHeader}>{`${property.name}`}</div>
              {renderGuestFee(property.id)}
              <Table
                rows={(feeCategory && feeCategory[property.id]) ? feeCategory[property.id] :[]}
                cols={cols}
                onEdit={editRow}
                onDelete={(data) => deleteRow(data.id)}
                startKey={cols[0].key}
                confirmDeleteDialog={invoiceDeleteDialog}
                handleConfirmDialog={handleConfirmDialog}
              />
            </div>
          ))}
      </div>
    )
  },[dispatch,properties,feesList,rateSettings,feeCategory,anchorEl,actionForExtraGuest]);
 
  return (
    <div>
      <Loading loadingStatus={actionForGetFeeList.loading || actionForExtraGuest.loading} />
      <AddFee
        show={addFeeModal}
        onHide={() => {
          setAddFeeModal(false);
          setEdited(null);
        }}
        onEdit={(data) => closeModal(data)}
        viewForEdit={edited}
        properties={properties}
        currency={currency}
      />
      <ExtraGuestFee
          show={extraGuestModal}
          onHide={()=>{
            setExtraGuestModal(false);
          }}
          properties={properties}
          currency={currency}
      />
      <div className="py-3">
        <button className={styles.ratelistBtn} onClick={() => setAddFeeModal(true)}>
          Add Fee
        </button>
        <button className={styles.ratelistBtn} onClick={() => setExtraGuestModal(true)}>
          Extra Guest Fee
        </button>
      </div>
      {renderFeesTable}
    </div>
  );
}

export default Fees;
