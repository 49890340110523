import React, { useState,useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Box, Popover} from "@material-ui/core";
import AddTax from './AddTax/AddTax';
import Loading from '../../../../../../components/loading';
import {addTax, clearAction, deleteTax, editTax, fetchTax} from '../../../../../../general_redux/rates/actions';
import Table from '../../../../../../components/Table';
import styles from '../../rates.module.css';

function Taxes() {
  const [addTaxModal, setAddTaxModal] = useState(false);
  const [edited, setEdited] = useState(false);
  const dispatch = useDispatch();

  const [taxCategory,setTaxCategory]=useState([]);
  const actionForFetchTaxes=useSelector(({rates})=>rates && rates.actionForFetchTaxes);
  const actionForAddTax=useSelector(({rates})=>rates && rates.actionForAddTax);
  const actionForEditTax=useSelector(({rates})=>rates && rates.actionForEditTax);
  const actionForDeleteTax=useSelector(({rates})=>rates && rates.actionForDeleteTax);
  const currency =useSelector(({user})=>user && user.user && user.user.currency);
  const properties = useSelector(({ rentals }) => rentals && rentals.rentals);
  const taxList = useSelector(({ rates }) => rates && rates.taxList);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentDelete,setCurrentDelete]=useState(null);

  const cols = [
    { lable: 'Name', key: 'name' },
    { lable: 'Type', key: 'taxType' },
    { lable: 'Amount', key: 'amount' },
    { lable: 'Modality', key: 'modality' },
    { lable: 'Actions', key: '' },
  ];

  useEffect(()=>{
    dispatch(fetchTax());
  },[])

  useEffect(()=>{
    if(actionForFetchTaxes.success || actionForAddTax.success || actionForEditTax.success || actionForDeleteTax.success){
      const tmp=[];
      if(taxList){
        properties.forEach((property)=>{
          const listItems=taxList.filter((row)=>row.rentalId===property.id);
          tmp[`${property.id}`]=listItems;
        })
        setTaxCategory(tmp);
        dispatch(clearAction())
      }
    }
  },[actionForFetchTaxes,actionForAddTax,actionForEditTax,actionForDeleteTax])

  const closeModal = (data) => {
    if (edited) {
      dispatch(editTax(data));
    } else {
      dispatch(addTax(data));
    }
    setEdited(null);
    setAddTaxModal(false);
  };
  const editRow = (val) => {
    const tmp=properties.filter((row)=>row.id===val.rentalId);
    if(tmp && tmp.length!==0){
      setEdited({...val,currencyVal:tmp[0].currency});
    }else{
      setEdited(val);
    }
    setAddTaxModal(true);
  };
  const deleteRow = (id) => {
    dispatch(deleteTax(id));
  };

  const handleDelete=()=>{
    if(currentDelete && currentDelete.id){
      dispatch(deleteTax(currentDelete.id));
    }
    setAnchorEl(null);
    setCurrentDelete(null);
  }

  const handleConfirmDialog = (e,row) => {
    setCurrentDelete(row);
    setAnchorEl( anchorEl ? null : e.currentTarget);
  }

    const invoiceDeleteDialog = () => {
      return (
          <Popover
              id={"popover-1"}
              open={Boolean(anchorEl)}
              onClose={handleConfirmDialog}
              anchorEl={anchorEl}
              anchorOrigin={{
                  vertical: "center",
                  horizontal: "left",
              }}
              transformOrigin={{
                  vertical: "center",
                  horizontal: "right",
              }}
              PaperProps={{
                  style: {
                      backgroundColor: "transparent",
                      boxShadow: "none",
                      borderRadius: 0,
                      display: "flex",
                  },
              }}
          >
              <div className={styles.contentWrap}>
                  <div className={styles.deleteTitle}>
                      Are you sure you want to delete this tax?
                  </div>
                  <div className="text-center m-1">
                      <button
                          onClick={() => handleDelete()}
                          className={styles.delConfirmBtn}
                      >
                          Delete
                      </button>
                      <button
                          onClick={handleConfirmDialog}
                          className={styles.cancelBtn}
                      >
                          Cancel
                      </button>
                  </div>
              </div>
              <Box
                  className={styles.rightArrow}
              />
          </Popover>
      );
    };

  return (
    <>
      <Loading loadingStatus={actionForFetchTaxes.loading}/>
      <AddTax
        show={addTaxModal}
        onHide={() => {
          setAddTaxModal(false);
          setEdited(null);
        }}
        onEdit={(data) => closeModal(data)}
        viewforedit={edited}
        currency={currency}
      />
      <div className="py-3">
        <button className={styles.ratelistBtn} onClick={() => setAddTaxModal(true)}>
          Add Tax
        </button>
      </div>

      <div className="col-lg-10 col-xl-8 px-0 mb-3">
        {properties &&
          properties.map((property, index) => (
            <div key={index} className="mb-4">
              <div className={styles.taxHeader}>{property.name}</div>
              <Table
                rows={(taxCategory && taxCategory[property.id])?taxCategory[property.id]:[]}
                cols={cols}
                onEdit={editRow}
                onDelete={(data) => deleteRow(data.id)}
                startKey={cols[0].key}
                confirmDeleteDialog={invoiceDeleteDialog}
                handleConfirmDialog={handleConfirmDialog}
              />
            </div>
          ))}
      </div>
    </>
  );
}

export default Taxes;
