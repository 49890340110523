import React, {useState, useEffect} from 'react';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import {useSelector} from 'react-redux';
import SpecialBookingForm from "./SpecialBookingForm";
import styles from './addspecialbooking.module.css';

function AddSpecialBooking(props) {
    const {handleShowGuest} = props;
    const gList= useSelector(({guests})=>guests && guests.guestList);
    const rentalList = useSelector(({rentals})=>rentals && rentals.rentals);
    const [selectedGuest,setSelectedGuest]=useState("");
    const [guestError, setGuestError] = useState("");
    const actionForAddGuest = useSelector(({ guests }) => guests && guests.actionForAddGuest);
    const guestFlag = useSelector(({ guests }) => guests && guests.guestFlag);

    useEffect(() => {
        if(actionForAddGuest.success && guestFlag) {
            const addedGuest = gList.filter(item => item.id === guestFlag).map(row => ({label:row.name,value:row.id}));
            if(addedGuest.length)
                setSelectedGuest(addedGuest[0]);
        }
    },[actionForAddGuest]);

    const compare = (a, b) => {
        return a.label > b.label ? 1 : b.label > a.label ? -1 : 0;
    }

    const handleGuestChange = (val) => {
        if(val !== "") {
            setGuestError("");
        }
        setSelectedGuest(val);
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title  className={styles.modalTitle}>Add fixed-term booking</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mb-2">`
                    <h3 className={styles.header}>Guest Details</h3>
                    <div className={styles.searchGuest}>
                        <Select
                            options={gList.map((row)=>({label:row.name,value:row.id})).sort(compare)}
                            onChange={handleGuestChange}
                            value={selectedGuest}
                            isSearchable={true}
                            isClearable={true}
                            placeholder={"Search guest..."}
                    />
                        <div className={styles.form_error}>
                            {guestError}
                        </div>
                </div>
                    <button type="submit" className={`${styles.addBtn} ${styles.greyBtn}`} onClick={handleShowGuest}>
                        {'Add guest'}
                    </button>
                </div>
                <div>
                    <h3 className={styles.header}>Booking Details</h3>
                    <SpecialBookingForm 
                        guestDetail={selectedGuest}
                        rentals={rentalList}
                        handleGuestError={setGuestError}
                        onHide={props.onHide}
                        handleSave={(data,guestData,check) => props.handleSave(data,guestData,check)}
                    />
                </div>
            </Modal.Body>
        </>
    )
}

export default AddSpecialBooking;