import React, {useEffect, useState} from 'react';
import { Formik, Form, Field, FieldArray } from 'formik';
import { Row, Col } from 'react-bootstrap';
import {useSelector} from "react-redux";
import moment from "moment";
import * as yup from "yup";
import ReactTooltip from "react-tooltip";
import {createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import iconPlus from "../../../../../../icons/icons8-plus.svg";
import styles from "./addspecialbooking.module.css";
import CustomDate from '../../../../../../components/CustomDate';

const myTheme=createTheme({
    palette: {
        primary: { // works
            main: '#439a86',
            contrastText: '#fff',
        },
    },
});

function SpecialBookingForm(props){
    const {rentals, guestDetail, handleGuestError, handleSave, onHide} = props;
    const paymentOpt = ["Payment Pending","Partially Paid","Paid"];
    const colorDrop = ['#dcdcdc','#a4bdfc','#4287f5','#7ae7bf','#46d6db','#53bf4b','#ffc107','#C8A2C8','#dbadff','#dc3545'];

    const dateDisplay = useSelector(({generalSetting})=>generalSetting && generalSetting.setting && generalSetting.setting.dateDisplay);
    const currency = useSelector(({generalSetting}) => generalSetting && generalSetting.setting && generalSetting.setting.currency.label);
    const {numberOf, allowBookingFor} = useSelector(({generalSetting}) => generalSetting && generalSetting.setting);
    const propertyList = useSelector(({rentals})=>rentals && rentals.rentals);
    
    const [guestLimit,setGuestLimit]=useState();
    const [rental, setRental] = useState('');
    const [adult,setAdult] = useState(1);
    const [children,setChildren] = useState(0);
    const [totalGuests, setTotalGuests] = useState(adult+children);

    const longTermSchema=yup.object().shape({
        bookingType: yup.string().required('Select Type is required'),
        rental: yup.string().required('Rental is required'),
        totalGuest: yup.number(),
        adult: yup.number().min(1, 'At least 1 adult is required').required('At least 1 adult is required'),
        children: yup.number().min(0, 'Cannot be a negative number'),
        bookingDate: yup.array().min(1, "At least 1 start date is required").of(yup.string().trim().required("Start date is required")),
    });

    const initVal = () => {
        return {
            bookingType: 'monthly',
            rental:'',
            totalGuests:1,
            adult: 1,
            children: 0,
            price: 1,
            paymentStatus: 'Payment Pending',
            bookingDate: [""],
            color: '#D3D3D3'
        };
    };

    useEffect( () => {
        if (rental) {
            propertyList.map((p)=>{
                if(p.id===Number(rental)){
                    setGuestLimit(p.maxGuests);
                }
            });
        }
    }, [rental]);

    useEffect(()=>{
        const tmp=Number(totalGuests)-Number(children);
        if(tmp<1){
            setAdult(1);
            setChildren(0);
        }else{
            setAdult(tmp);
        }
    },[totalGuests,children])

    const validate = (field,value) => {
        let errorMessage;

        if(field==="totalGuests"){
            if(!totalGuests || totalGuests <= 0){
                errorMessage = 'At least 1 guest is required';
            } else if(totalGuests >guestLimit){
                errorMessage=`Only ${guestLimit} guests are allowed!`;
            }
        }
        return errorMessage;
    };

    const getMinDate = (index, bookingDates, bookingType) => {
        return index > 0 ? moment(bookingDates[index-1]).add(bookingType === "weekly" ? 7 : 1, bookingType === "weekly" ? 'd' : 'M').format('YYYY-MM-DD') : ""
    }

    const getMaxDate = (index, bookingDates, bookingType) => {
        return (bookingDates?.[index+1]) ?
            moment(bookingDates?.[index+1]).subtract(bookingType === "weekly" ? 7 : 1,bookingType === "weekly" ? 'd' : 'M').format('YYYY-MM-DD') :
            `${moment().add(numberOf,allowBookingFor === "months" ? 'M' : 'y').format('YYYY-MM-DD')}`
    }

    const validateGuest = () => {
        if(!guestDetail || guestDetail === ""){
            handleGuestError("Guest is required")
            return false;
        }
    }

    const handleSubmit = (values) => {
        let guestData={};
        const selectedRental = propertyList.filter(val => val.id === Number(values.rental));
 
        const payload=[];
        values.bookingDate.map((item) => {
            let departDate=moment(item,"YYYY-MM-DD").add(values.bookingType==="weekly" ? 7 : 1, values.bookingType==="weekly" ? 'days' : 'M').format('YYYY-MM-DD')
            payload.push({
                rentalId: Number(values.rental),
                noOfAdults: values.adult,
                noOfChildren: values.children || 0,
                source: "beehaz",
                checkInTime: selectedRental[0].checkInTime,
                checkOutTime: selectedRental[0].checkOutTime,
                arrive:moment(item).format().slice(0,10),
                depart:departDate,
                bookingType: values.bookingType,
                noOfGuests: values.totalGuests,
                paymentStatus: values.paymentStatus,
                title : `${guestDetail.label} | ${values.paymentStatus} | Direct Booking`,
                color: values.color,
                fees: 0,
                tax: 0,
                baseRate: Number(Number(values.price).toFixed(2)),
                price: Number(Number(values.price).toFixed(2)),
                miniNotes: "",
                notes: "",
                chargeName:"Extra charge",
                chargeAmount:0,
                discountName: "Discount",
                discountAmount:0,
                extraGuestFee:0,
                nights:moment(departDate,"YYYY-MM-DD").diff(moment(item,"YYYY-MM-DD"),'day')
            })
        })
        guestData={
            guestId:guestDetail.value
        }
        payload.map((payload) => {
            handleSave(payload,guestData,false);
        });
    }

    return (
        <MuiThemeProvider theme={myTheme}>
            <Formik
                initialValues={initVal()}
                validationSchema={longTermSchema}
                onSubmit={(data) => {
                    if(guestDetail && guestDetail !== ""){
                        handleSubmit(data)
                        onHide();
                    }
                }}
                validateOnChange={true}
                validateOnBlur={true}
                validate={(values) => validateGuest(values)}
            >
                {({ values, setFieldValue, handleChange,errors }) => (
                    <Form>
                        <Row>
                            <Col>
                                <div className={`d-flex ${styles.selectTypeRow}`}>
                                    <div className={`${styles.form_title} ${styles.typeTitle}`}>Select Type:</div>
                                    <div className={styles.customWidth}>
                                        <div className="d-flex" >
                                            <div className="d-flex mx-3">
                                                <Checkbox
                                                    name="bookingType"
                                                    color="primary"
                                                    style={myTheme.palette.primary}
                                                    size={"small"}
                                                    className={styles.term_select}
                                                    onChange={() => {setFieldValue("bookingType","monthly"); setFieldValue("bookingDate",['']);}}
                                                    value="monthly"
                                                    checked={values.bookingType === "monthly"}
                                                />
                                                <div>Monthly</div>
                                            </div>
                                            <div className="d-flex ml-1">
                                                <Checkbox
                                                    name="bookingType"
                                                    color="primary"
                                                    style={myTheme.palette.primary}
                                                    size={"small"}
                                                    className={styles.term_select}
                                                    onChange={() => {setFieldValue("bookingType","weekly"); setFieldValue("bookingDate",['']);}}
                                                    value="weekly"
                                                    checked={values.bookingType === "weekly"}
                                                />
                                                <div className={styles.form_date}>Weekly</div>
                                            </div>
                                        </div>
                                        {errors.bookingType && (
                                            <div className={`ml-3 ${styles.form_error}`}>
                                                {errors.bookingType}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="pl-0 pl-sm-3 mt-2">
                                    <div className={`d-flex ${styles.form_wrapper}`}>
                                        <div className={styles.form_title}>Select rental:</div>
                                        <div className={styles.customWidth}>
                                            <Field
                                                as={"select"}
                                                name={"rental"}
                                                className = {(errors.rental)?styles.form_entry_err:styles.form_entry}
                                                onChange={(e) => {
                                                    setFieldValue('rental',e.target.value)
                                                    setRental(e.target.value)
                                                }}
                                            >
                                                <option value={""} label={"---Select Rental---"}/>
                                                {rentals.map((opt)=>(
                                                    <option key={opt.id} value={opt.id}>{opt.name}</option>
                                                ))}
                                            </Field>
                                            {errors.rental && (
                                                <div className={styles.form_error}>
                                                    {errors.rental}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className={`d-flex ${styles.form_wrapper}`}>
                                        <div className={styles.form_title}>Number of guests:</div>
                                        <div className={styles.customWidth}>
                                            <Field
                                                type={"number"}
                                                name={"totalGuests"}
                                                className = {(errors.totalGuests)?styles.form_entry_err:styles.form_entry}
                                                value={totalGuests}
                                                onChange={(e)=>setTotalGuests(e.target.value)}
                                                validate={(value)=>validate('totalGuests',value)}
                                                min={1}
                                            />
                                            {errors.totalGuests && (
                                                <div className={styles.form_error}>
                                                    {errors.totalGuests}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className={`d-flex ${styles.form_wrapper}`}>
                                        <div className={styles.form_title}>Adults:</div>
                                    <div className={styles.customWidth}>
                                        <Field
                                            type={"number"}
                                            name={"adult"}
                                            value={adult}
                                            disabled={true}
                                            className={styles.form_entry}
                                            min={1}
                                        />
                                    </div>
                                    </div>
                                    <div className={`d-flex ${styles.form_wrapper}`}>
                                        <div className={styles.form_title}>Children:</div>
                                        <div className={styles.customWidth}>
                                            <Field
                                                type={"number"}
                                                name={"children"}
                                                value={children}
                                                onChange={(e)=>setChildren(e.target.value)}
                                                className={styles.form_entry}
                                                min={0}
                                            />
                                        </div>
                                    </div>
                                    <div className={`d-flex ${styles.form_wrapper}`}>
                                        <div className={styles.form_title}>Price per period:</div>
                                        <div className={styles.customWidth}>
                                            <Field
                                                type={"text"}
                                                name={"price"}
                                                value={`${values.price.toFixed(2)} ${currency}`}
                                                disabled={true}
                                                className={styles.form_entry}
                                            />
                                        </div>
                                    </div>
                                    <div className={`d-flex ${styles.form_wrapper}`}>
                                        <div className={styles.form_title}>Payment status:</div>
                                        <div className={styles.customWidth}>
                                            <Field
                                                as={"select"}
                                                name={"paymentStatus"}
                                                className={styles.form_entry}
                                            >
                                                <option key={""} value={""} disabled={true} label={"--Select Status--"}/>
                                                {paymentOpt.map((opt)=>(
                                                    <option value={opt} key={opt}>{opt}</option>
                                                ))}
                                            </Field>
                                        </div>
                                    </div>
                                    <FieldArray name={"bookingDate"}>
                                        {({ insert, remove, push }) => (
                                            <>
                                                {values.bookingDate.map((item, index) => (
                                                    <div className={`d-flex ${styles.form_wrapper} ${styles.date_main_wrapper}`} key={`long-${index}`}>
                                                        <div className={styles.dateWrap}>
                                                            <div className={styles.form_title}>Start date:</div>
                                                            <div className={`${styles.customWidth} ${values.bookingDate.length > 1 && styles.dateWidth}`}>
                                                                <CustomDate
                                                                    id={"fsDate"}
                                                                    name={`bookingDate[${index}]`}
                                                                    hasError = {(errors.bookingDate?.[index])}
                                                                    // min={getMinDate(index, values.bookingDate, values.bookingType)}
                                                                    // max={getMaxDate(index, values.bookingDate, values.bookingType)}
                                                                    value={values.bookingDate?.[index]?values.bookingDate[index]:""}
                                                                    handleChange={(date)=>{
                                                                        setFieldValue(`bookingDate[${index}]`,date);
                                                                    }}
                                                                />
                                                                {errors.bookingDate?.length && errors.bookingDate[index] && (
                                                                    <div className={styles.form_error}>
                                                                        {errors.bookingDate[index]}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        {values.bookingDate.length > 1 && (
                                                            <span className={styles.delDateIcon}>
                                                                <i className={`fa fa-trash ${index === 0 && 'd-none'}`} onClick={() => remove(index)}/>
                                                            </span>
                                                        )}
                                                    </div>
                                                ))}
                                                <div className={`d-flex ${styles.addDateCol}`}>
                                                    <img
                                                        src={iconPlus}
                                                        alt={"addStartDate"}
                                                        width={"22px"}
                                                        height={"22px"}
                                                        className={`mr-2 ${styles.addDateIcon} ${values.bookingDate.length >= 12 && styles.addDateDisabled}`}
                                                        onClick={() => values.bookingDate.length < 12 ? push("") : ""}
                                                    />
                                                    <div className={`${styles.addDateTitle} ${values.bookingDate.length >= 12 && styles.addDateDisabled}`}>add start date</div>
                                                    <InfoOutlinedIcon
                                                        fontSize="small"
                                                        color="inherit"
                                                        data-tip
                                                        data-for="addDate"
                                                        className={styles.toolTipIcon}
                                                    />
                                                </div>
                                                <div>
                                                    <ReactTooltip place="bottom" type="dark" id="addDate" effect="solid" className={styles.toolTip}>
                                                        <span>
                                                          You can add start date up to 12 only.
                                                        </span>
                                                    </ReactTooltip>
                                                </div>
                                            </>
                                        )}
                                    </FieldArray>

                                    <div className={`${styles.form_wrapper}`}>
                                        <div className={styles.form_title}>Choose colour:</div>
                                        <div>
                                            {colorDrop.map((item)=>(
                                                <div
                                                    className={`${styles.containerBox}`}
                                                    style={{background:`${item}`}}
                                                    onClick={()=>setFieldValue("color", item)}
                                                    key={item}
                                                />
                                            ))}
                                            <Field
                                                type={'color'}
                                                name={'color'}
                                                value={values.color}
                                                onChange={handleChange}
                                                className={styles.form_entry_color}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="d-flex justify-content-center">
                                    <button type="submit" className={styles.settings_save}>
                                        {'Save changes'}
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </MuiThemeProvider>
    )
};

export default SpecialBookingForm;