import React, { useState, useEffect,useRef } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { InputGroup,Container,Row,Col } from 'react-bootstrap';
import { useSelector,useDispatch } from 'react-redux';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { default as ReactSelect, components } from "react-select";
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import {createTheme, makeStyles, MuiThemeProvider} from '@material-ui/core/styles';
import {zeroPad} from '../../../../common/functions/utils';
import Table from '../../../../components/Table/TableBackup';
import {LoadInquiries,DeleteInquiry,downloadHistoricData} from '../../../../general_redux/inquiry/action';
import Loading from '../../../../components/loading';
import CustomDate from '../../../../components/CustomDate';
import ConfirmMessage from '../../../../components/Confirmation';
import styles from './inquiry.module.css';

const myTheme=createTheme({
  palette: {
    primary: { // works
      main: '#439a86',
      contrastText: '#fff',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  mainContainer: {
    position: "relative",
    zIndex: "auto",
  },
  palette: {
    type: "light",
    primary: {
      light: "#ffffff",
      main: "#26a69a",
      dark: "#004d40",
    },
  },
  formInput: {
    color: "white",
    backgroundColor: "pink",
  },
  inputLabel: {
    position: "unset",
    whiteSpace: "nowrap",
    margin: "0 12px 0 0",
    ["@media (max-width:991px)"]: {
      // eslint-disable-line no-useless-computed-key
      margin: "0 0 6px 0",
    },
    color: "white",
    fontSize: "0.8rem",
    fontFamily: "Montserrat",
  },
  textField: {
    // width: 200,
    color: "white",
    width: "170px",
    ["@media (max-width:991px)"]: {
      // eslint-disable-line no-useless-computed-key
      width: "95%",
    },
    background: "#fff",
    padding: "0 8px",
    border: "none",
    borderRadius: "5px",
    fontSize: "0.8rem",
    fontFamily: "Montserrat",
    height: "32px",
  },
  MuiSelect: {
    width: "170px",
    ["@media (max-width:991px)"]: {
      // eslint-disable-line no-useless-computed-key
      width: "100%",
    },
    ["@media (max-width:576px)"]: {
      // eslint-disable-line no-useless-computed-key
      width: "100%",
    },
    background: "#fff",
    padding: "0 8px",
    border: "none",
    borderRadius: "5px",
    fontSize: "0.8rem",
    height: "32px",
  },
  input: {
    fontSize: "0.8rem",
    fontFamily: "Montserrat",
  },
  underline: {
    color: "white",
  },
  formLabel: {
    color: theme.palette.primary.light,
  },
  formControl: {
    flexDirection: "row",
  },
  selectMenu: {
    top: "54px",
    width: "180px",
    marginTop: "50px",
    marginLeft: "-18px",
  },
  truncate: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "block !important",
    alignItems: "center",
    margin: "5px 0 0",
    overflow: "hidden",
  },
  cmvlaue:{
    flexWrap:"nowrap !important",
  },
}));

function Inquiry() {
  const classes = useStyles();
  const { width, ref } = useResizeDetector();
  const [showHeader,setShowHeader]=useState(false);
  const wrapperRef = useRef(null);
  const mainWrapper=useRef(null);
  const dropWrapper=useRef(null);
  const dropMobileWrapper = useRef(null);
  const rentals = useSelector(({ rentals }) => rentals && rentals.rentals && rentals.rentals.map((row) => ({label:row.name,value:row.id})));
  const guests=useSelector(({guests})=>guests && guests.guestList && guests.guestList.map((row) => ({label:row.name,value:row.id})));
  const [selectbar,setSelection]=useState(false);
  const user= useSelector(({user})=> user && user.user);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [dropActive,setActive]=useState(false);
  const [dropMobileActive,setMobileActive] = useState(false);
  const dispatch = useDispatch();
  const inquiryStatus = useSelector(({inquiry})=>inquiry && inquiry.actionForInquiries);
  const inquiryHistoryStatus = useSelector(({inquiry})=>inquiry && inquiry.actionForHistory);
  const historyData = useSelector(({inquiry}) => inquiry && inquiry.historyData);
  const queryResult = useSelector((state) => state.inquiry.inquiryData.map(row => ({...row, booking_nr: `${new Date().getUTCFullYear()}${zeroPad(row.id,4)}`})));
  const dateDisplay = useSelector(({generalSetting})=>generalSetting && generalSetting.setting && generalSetting.setting.dateDisplay);
  
  const [selectedRental,setSelectedRentals]=useState([]);
  const [selectedGuests,setSelectedGuests]=useState([]);
  const [selectedStatus,setSelectedStatus]=useState([]);
  const [selectedPStatus,setSelectedPStatus]=useState([]);
  const [isDownload, setIsDownload] = useState(false);
  const [confirmDel,setConfirmDel]=useState(false);
  const [deleteInquiry, setDeleteInquiry] = useState([]);

  const Payment=["Payment pending","Partially paid","Paid"];
  const statusArr=["Booked","Cancelled"];

  const inquiryCol = [
    { label: 'Booking nr.', key: 'id' },
    { label: 'Source', key: 'channel' },
    { label: 'Rental', key: 'rentalName' },
    { label: 'Name', key: 'guestName' },
    { label: 'Arrive', key: 'arrive', format: "DD/MM/YYYY" },
    { label: 'Depart', key: 'depart', format: "DD/MM/YYYY" },
    { label: 'Nights', key: 'nights' },
    { label: 'Adults', key: 'noOfAdults' },
    { label: 'Children', key: 'noOfChildren' },
    { label: 'Net amount', key: 'netAmount' },
    { label: 'Payment status', key: 'paymentStatus' }
  ];

  const guestCol = [
    { label: 'Company', key: 'guestCompany' },
    { label: 'Birth date', key: 'guestDob', format: "DD/MM/YYYY" },
    { label: 'Nationality', key: 'guestNationality' },
    { label: 'Country', key: 'guestCountry' },
    { label: 'Street and nr', key: 'guestStreet' },
    { label: 'Postal code', key: 'postalCode' },
    { label: 'State/Province', key: 'guestState' },
    { label: 'Phone', key: 'guestPhone' },
    { label: 'Email', key: 'guestEmail' }
  ];

  useEffect(() => {
    function handler(event) {
      if(!mainWrapper.current?.contains(event.target)){
        setSelection(false);
      }
      if(!dropWrapper.current?.contains(event.target)){
        setActive(false);
      }
      if(!dropMobileWrapper.current?.contains(event.target)){
        setMobileActive(false);
      }
    }
    window.addEventListener('click', handler)
    return () => window.removeEventListener('click', handler)
  }, []);

   useEffect(() => {
    const payload={
      customerId:user.id,
      dateFrom: (dateFrom==="")? null :dateFrom,
      dateTo: (dateTo==="")?null:dateTo,
      rentalId: selectedRental.map(({value})=>value),
      paymentStatus:selectedPStatus,
      status:selectedStatus,
      guestsId: selectedGuests.map(({value})=>value).filter(i => i !== "*")
    }
    dispatch(LoadInquiries(payload));
  }, [selectedRental,dateFrom,dateTo,selectedPStatus,selectedStatus,selectedGuests]);

  useEffect(() => {
    if(inquiryHistoryStatus.success && isDownload){
      downloadExcel("historic","Reservation_History",historyData);
      setIsDownload(false);
    }
  },[inquiryHistoryStatus,isDownload]);

  function clearFilter() {
    setDateFrom("");
    setDateTo("");
    setSelectedRentals([]);
    setSelectedGuests([]);
    setSelectedStatus([]);
    setSelectedPStatus([]);
  }
  const cols = [
    { lable: 'Booking Nr', key: 'booking_nr' },
    { lable: 'Channel', key: 'channel' },
    { lable: 'Rental', key: 'rentalName' },
    { lable: 'Guest Name', key: 'guestName' },
    { lable: 'Check-in', key: 'arrive', isDate:true },
    { lable: 'Check-out', key: 'depart',isDate:true },
    { lable: 'Nights', key: 'nights' },
    { lable: 'Net Amount', key: 'netAmount' },
    { lable: 'Created', key: 'createdAt',isDate:true},
    { lable: 'Payment Status', key: 'paymentStatus' },
    { lable: 'Status', key: 'status' },
  ];

  const downloadExcel = (eType, sheetName, rowData) => {
    let excelData = [];
    var inquiryHeading = inquiryCol.map(i => i.label)
    if (eType === "guest" || eType === "historic") {
      inquiryHeading = [...inquiryHeading, ...guestCol.map(i => i.label)]
    }
    rowData.forEach((items) => {
      let tmp = {};
      inquiryCol.map(col => {
          tmp[col.label] = col.format && items[col.key] ? moment(items[col.key]).format(col.format) : col.key === "id" ? `${new Date().getUTCFullYear()}${zeroPad(items[col.key],4)}` : items[col.key];
      });
      if (eType === "guest" || eType === "historic") {
        guestCol.map(col => {
          tmp[col.label] = col.format && items[col.key] ? moment(items[col.key]).format(col.format) : items[col.key];
        });
      }
      excelData.push(tmp);
    });
    const fileName = `${sheetName}_${Date.now()}`;
    let wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(excelData, { origin: 'A2', skipHeader: true });
    XLSX.utils.sheet_add_aoa(ws, [inquiryHeading], { origin: 'A1' });
    XLSX.utils.book_append_sheet(wb, ws, `${sheetName}Sheet`);
    XLSX.writeFile(wb, `${fileName}.xlsx`, { bookType: "xlsx", type: "array" });
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const selectAllOption = {
    label: "Select all",
    value: "*"
  };
  const MenuProps = {
    paperStyles: {
      style:{
        maxHeight: ITEM_HEIGHT * 3.5 + ITEM_PADDING_TOP,
        width: 180,
        marginTop:50,
        marginLeft:-18,
        top:54,
      },
    },
  };

  const customSelectStyles = {
    container: () => ({
      width: "170px",
      maxWidth: "250px",
      ["@media (max-width:991px)"]: {
        // eslint-disable-line no-useless-computed-key
        width: "100%",
        minWidth: "100%",
        maxWidth: "100%",
      },
      background: "#fff",
      padding: "0 8px",
      border: "none",
      borderRadius: "5px",
      fontSize: "13px",
      height: "32px",
    }),
    control: () => ({
      display: "flex",
      height: "32px",
      width: "100%",
    }),
    menu: (provided, state) => ({
      ...provided,
      width: "max-content",
      zIndex: "0",
      position:"absulate",
      ["@media (max-width:767px)"]: {
        width: "100%"
      }
    }),
    option: (provided, state) => ({
      ...provided,
      width: state.selectProps.width,
      backgroundColor: "transparent",
      color: "#333",
      padding: 0,
    }),
    ValueContainer: (base) => ({
      ...base,
      display: "flex",
      flexWrap: "nowrap",
    }),
    dropdownIndicator: () => ({
      color: "rgba(0, 0, 0, 0.54)",
      cursor: "pointer",
      width: "1em",
      height: "1em",
      display: "flex",
      justifyContent: "center",
      fontSize: "1rem",
      flexShrink: 0,
      paddingLeft: "8px",
    })
  };
  const confirmDelete = (selected) => {
    const inquiryList= queryResult.filter((inq)=>(selected.includes(inq.id))).map((b)=>b.inquiryId);
    const payload={
      inquiry:inquiryList,
      booking:selected,
    };
    dispatch(DeleteInquiry(payload));
    setDeleteInquiry([]);
  }
  const handleDelete=(selected)=>{
    const deletableGuests = queryResult.filter(inquiry => selected.indexOf(inquiry.id) >= 0 && inquiry.status === "Booked");
    if(deletableGuests.length){
      setDeleteInquiry(selected);
      setConfirmDel(true);
    } else {
      confirmDelete(selected);
    }
  }

  const handleDownload=(e,eType,sheetName)=>{
    e.preventDefault();
    downloadExcel(eType,sheetName,queryResult)
  }

  const handleHistoricData=(e)=>{
    e.preventDefault();
    const payload={
      customerId:user.id,
      dateTo: (dateTo==="")?null:dateTo,
      rentalId: selectedRental.map(({value})=>value),
      paymentStatus:selectedPStatus,
      status:selectedStatus,
      guestsId: selectedGuests.map(({value})=>value)
    }
    dispatch(downloadHistoricData(payload));
    setIsDownload(true);
  }

  const handleChange = (event,changeProp) => {
    if(changeProp==="rental"){
      let tmp=event.target.value;
      let lastElem=tmp[tmp.length-1];
      if(selectedRental.findIndex(({value})=>value===lastElem.value)===-1){
        setSelectedRentals(event.target.value);
      }else{
        let newRentals=event.target.value.filter((row)=>row.value!==lastElem.value);
        setSelectedRentals(newRentals);
      }
    }else if(changeProp==="bType"){
      setSelectedStatus(event.target.value);
    }else{
      setSelectedPStatus(event.target.value);
    }
  };

  const handleCustomSelectChange = (selected) => {
    if (
      selected !== null &&
      selected.length > 0 &&
      selected[selected.length - 1].value === "*"
    ) {
      setSelectedGuests([selectAllOption, ...guests]);
    } else {
      setSelectedGuests((prevState) => {
        if (
          prevState?.filter((prev) => prev.value === "*").length &&
          !selected.filter((prev) => prev.value === "*").length
        ) {
            setSelectedGuests([]);
        } else {
          setSelectedGuests(selected);
        }
      });
    }
  }


  const ValueContainer = ({ children, getValue, ...props }) => {
    let [values, input] = children;
    let allVal = [];
    if (Array.isArray(values)) {
      allVal = values
        .map((i) => i.props.data.label)
        .filter((i) => i !== "Select all")
        .join(",");
    }
    return (
      <components.ValueContainer
          {...props}
          className={classes.cmvlaue}
          style={{display: 'flex', flexWrap:'nowrap'}}
        >
          <label className={classes.truncate}>
            {allVal.length ? allVal : values}
          </label>
          {input}
        </components.ValueContainer>
    );
  };
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <MenuItem>
            <Checkbox
              checked={props.isSelected}
              color="primary"
              style={myTheme.palette.primary}
            />
            <ListItemText
              className={classes.input}
              primary={props.label}
              disableTypography={true}
            />
          </MenuItem>
        </components.Option>
      </div>
    );
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <i className="fa fa-caret-down"/>
      </components.DropdownIndicator>
    );
  };

  
  return (
    <MuiThemeProvider theme={myTheme}>
    <Loading loadingStatus={inquiryStatus.loading}/>
    <div className={`${styles.wrapper}`} ref={wrapperRef} >
        <div className={styles.header} ref={ref}>
            <div className={styles.first_col}>
              <div className={`${styles.filter_filter_name} `} ref={mainWrapper} onClick={(e)=>setShowHeader(!showHeader)}>
                <div className={styles.btnWrapper}>
                  <div className={styles.filterName}>
                    <i className={`fa fa-filter ${styles.filter_icon}`}/>{' '}
                    <span className={styles.filter_title_header}>Filter</span>
                  </div>
                  <div className={styles.filterDrop} >
                    <i className={`fa fa-caret-down`}/>
                  </div>
                </div>

              </div>
              <div className={`ml-3`}>
                  <button className={`${styles.btnGrey}`} onClick={clearFilter}>
                    Clear Filter
                  </button>
                </div>
              <div className={`${(width<=1219)?styles.twoHeadersHide:styles.twoHeaders}`}>
                <div className={`${styles.filter_sub_container}`}>
                  <div className={styles.firstBreak}>
                    <InputGroup className={styles.input_group}>
                      <InputLabel id="demo-mutiple-checkbox-label" className={classes.inputLabel}>Rental</InputLabel>
                      <Select
                          id="inquiry-rental-selection"
                          multiple
                          value={selectedRental}
                          onChange={(e)=>handleChange(e,"rental")}
                          input={<Input classes={{input:classes.input}}/>}
                          renderValue={(selected) => {
                            let out = [];
                            for (let i = 0; i < selected.length; i++) {
                              out.push(selected[i]["label"]);
                            }
                            return out.join(", ");
                          }}
                          MenuProps={MenuProps}
                          className={classes.MuiSelect}
                      >
                        {rentals.map((row) => (
                            <MenuItem key={row.value} value={row}>
                              <Checkbox
                                  checked={selectedRental.filter(({value})=>value===row.value).length>0}
                                  color="primary"
                                  style={myTheme.palette.primary}
                              />
                              <ListItemText className={classes.input} primary={row.label} disableTypography={true}/>
                            </MenuItem>
                        ))}
                      </Select>
                    </InputGroup>
                    <InputGroup className={styles.input_group}>
                      <InputLabel id="demo-mutiple-checkbox-label" className={classes.inputLabel}>Date from</InputLabel>
                      <CustomDate
                          id="date"
                          value={dateFrom}
                          handleChange={(date) => setDateFrom(date)}
                          customClass={classes.textField}
                      />
                    </InputGroup>

                    <InputGroup className={styles.input_group}>
                      <InputLabel id="demo-mutiple-checkbox-label" className={classes.inputLabel}>Date to</InputLabel>
                      <CustomDate
                          id="date"
                          value={dateTo}
                          handleChange={(date) => setDateTo(date)}
                          disabled={!dateFrom}
                          min={dateFrom}
                          customClass={classes.textField}
                      />
                    </InputGroup>
                  </div>
                </div>
                <div className={`${styles.filter_sub_container} ${(showHeader)?styles.bottomHeader:styles.hiddenBottomHeader}`}>
                  <div className={styles.firstBreak}>
                    <InputGroup className={styles.input_group}>
                      <InputLabel id="demo-mutiple-checkbox-label" className={classes.inputLabel}>Payment status</InputLabel>
                      <Select
                          labelId="demo-mutiple-checkbox-label"
                          id="demo-mutiple-checkbox"
                          multiple
                          value={selectedPStatus}
                          onChange={(e)=>handleChange(e,"payment")}
                          input={<Input classes={{input:classes.input}}/>}
                          renderValue={(selected) => selected.join(', ')}
                          MenuProps={MenuProps}
                          className={classes.MuiSelect}
                      >
                        {Payment.map((name) => (
                            <MenuItem key={name} value={name}>
                              <Checkbox
                                  checked={selectedPStatus.indexOf(name) > -1}
                                  color="primary"
                                  style={myTheme.palette.primary}
                              />
                              <ListItemText className={classes.input} primary={name} disableTypography={true} />
                            </MenuItem>
                        ))}
                      </Select>
                    </InputGroup>
                    <InputGroup className={styles.input_group}>
                      <InputLabel id="demo-mutiple-checkbox-label" className={classes.inputLabel}>Status</InputLabel>
                      <Select
                          labelId="demo-mutiple-checkbox-label"
                          id="demo-mutiple-checkbox"
                          multiple
                          value={selectedStatus}
                          onChange={(e)=>handleChange(e,"bType")}
                          input={<Input classes={{input:classes.input}}/>}
                          renderValue={(selected) => selected.join(', ')}
                          MenuProps={MenuProps}
                          className={classes.MuiSelect}
                      >
                        {statusArr.map((name) => (
                            <MenuItem key={name} value={name}>
                              <Checkbox
                                  checked={selectedStatus.indexOf(name) > -1}
                                  color="primary"
                                  style={myTheme.palette.primary}
                              />
                              <ListItemText className={classes.input} primary={name} disableTypography={true}/>
                            </MenuItem>
                        ))}
                      </Select>
                    </InputGroup>
                    <InputGroup className={styles.input_group}>
                      <InputLabel id="demo-mutiple-checkbox-label" className={classes.inputLabel}>Guest</InputLabel>
                      <ReactSelect
                        blurInputOnSelect={true}
                        hideSelectedOptions={false}
                        isMulti
                        options={[selectAllOption,...guests]}
                        onChange={handleCustomSelectChange}
                        value={selectedGuests}
                        components={{ Option, ValueContainer, DropdownIndicator }}
                        className={classes.customSelect}
                        styles = {customSelectStyles}
                        placeholder={<div>Select...</div>}
                      />
                    </InputGroup>
                  </div>
                </div>
              </div>
              <div className={(width>1219)?styles.btn_group_hide:styles.btn_group}>
                <div className={`${styles.dropdown}`} onClick={()=>setMobileActive(!dropMobileActive)} ref={dropMobileWrapper}>
                  <div className={`${styles.filter_filter_name} ${styles.leftNegative}`} >
                    <div className={styles.btnWrapper}>
                      <div className={styles.filterName}>
                        <span className={styles.filter_title_header}>Download</span>
                      </div>
                      <div className={styles.filterDrop} onClick={()=>setSelection(!selectbar)}>
                        <i className={`fa fa-caret-down`}/>
                      </div>
                    </div>
                  </div>
                  <div className={dropMobileActive?`${styles.dropdownContentActive}`:`${styles.dropdownContent}`}>
                    <a href="" onClick={(e)=>handleDownload(e,"inquiry","Reservations")}>{"Excel reservation list"}</a>
                    <a href="" onClick={(e)=>handleDownload(e,"guest","Reservation_Guests")}>{"Excel reservations and guest list"}</a>
                    <a href="" onClick={handleHistoricData}>{"Download historic data"}</a>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.secound_col}>
              <div className={(width<=1219)?styles.btn_group_hide:styles.btn_group}>
             
                <div className={`${styles.dropdown}`} onClick={()=>setActive(!dropActive)} ref={dropWrapper}>
                  <div className={`${styles.filter_filter_name} ${styles.leftNegative}`} >
                    <div className={styles.btnWrapper}>
                      <div className={styles.filterName}>
                        <span className={styles.filter_title_header}>Download</span>
                      </div>
                      <div className={styles.filterDrop} onClick={()=>setSelection(!selectbar)}>
                        <i className={`fa fa-caret-down`}/>
                      </div>
                    </div>
                  </div>
                  <div className={dropActive?`${styles.dropdownContentActive}`:`${styles.dropdownContent}`}>
                    <a href="" onClick={(e)=>handleDownload(e,"inquiry","Reservations")}>{"Excel reservation list"}</a>
                    <a href="" onClick={(e)=>handleDownload(e,"guest","Reservation_Guests")}>{"Excel reservations and guest list"}</a>
                    <a href="" onClick={handleHistoricData}>{"Download historic data"}</a>
                  </div>
                </div>

              </div>
              <div className={`${(width>1219)?styles.twoHeadersHide:styles.twoHeaders}`}>
                <div className={`${styles.filter_sub_container}`}>
                  <div className={styles.firstBreak}>
                    <InputGroup className={styles.input_group}>
                      <InputLabel id="demo-mutiple-checkbox-label" className={classes.inputLabel}>Rental</InputLabel>
                      <Select
                          id="inquiry-rental-selection"
                          multiple
                          value={selectedRental}
                          onChange={(e)=>handleChange(e,"rental")}
                          input={<Input classes={{input:classes.input}}/>}
                          renderValue={(selected) => {
                            let out = [];
                            for (let i = 0; i < selected.length; i++) {
                              out.push(selected[i]["label"]);
                            }
                            return out.join(", ");
                          }}
                          MenuProps={MenuProps}
                          className={classes.MuiSelect}
                          InputProps={{
                            disableUnderline:true,
                          }}
                      >
                        {rentals.map((row) => (
                            <MenuItem key={row.id} value={row} className={classes.menuItems}>
                              <Checkbox
                                  checked={selectedRental.filter(({value})=>value===row.value).length>0}
                                  color="primary"
                                  style={myTheme.palette.primary}
                              />
                              <ListItemText className={classes.input} primary={row.label} disableTypography={true}/>
                            </MenuItem>
                        ))}
                      </Select>
                    </InputGroup>
                    <div className="d-flex w-100">
                      <InputGroup className={styles.input_group}>
                        <InputLabel id="demo-mutiple-checkbox-label" className={classes.inputLabel}>Date from</InputLabel>
                        <CustomDate
                            id="date"
                            type="date"
                            value={dateFrom}
                            handleChange={(date) => setDateFrom(date)}
                            customClass={classes.textField}
                        />
                      </InputGroup>

                      <InputGroup className={styles.input_group}>
                        <InputLabel id="demo-mutiple-checkbox-label" className={classes.inputLabel}>Date to</InputLabel>
                        <CustomDate
                            id="date"
                            type="date"
                            value={dateTo}
                            handleChange={(date) => setDateTo(date)}
                            customClass={classes.textField}
                        />
                      </InputGroup>
                    </div>
                  </div>
                </div>
                <div className={`${styles.filter_sub_container} ${(showHeader)?styles.bottomHeader:styles.hiddenBottomHeader} mt-0`}>
                  <div className={styles.firstBreak}>
                    <InputGroup className={styles.input_group}>
                      <InputLabel id="demo-mutiple-checkbox-label" className={classes.inputLabel}>Payment status</InputLabel>
                      <Select
                          labelId="demo-mutiple-checkbox-label"
                          id="demo-mutiple-checkbox"
                          multiple
                          value={selectedPStatus}
                          onChange={(e)=>handleChange(e,"payment")}
                          input={<Input classes={{input:classes.input}}/>}
                          renderValue={(selected) => selected.join(', ')}
                          MenuProps={MenuProps}
                          className={classes.MuiSelect}
                      >
                        {Payment.map((name) => (
                            <MenuItem key={name} value={name}>
                              <Checkbox
                                  checked={selectedPStatus.indexOf(name) > -1}
                                  color="primary"
                                  style={myTheme.palette.primary}
                              />
                              <ListItemText className={classes.input} primary={name} disableTypography={true} />
                            </MenuItem>
                        ))}
                      </Select>
                    </InputGroup>
                    <InputGroup className={styles.input_group}>
                      <InputLabel id="demo-mutiple-checkbox-label" className={classes.inputLabel}>Status</InputLabel>
                      <Select
                          labelId="demo-mutiple-checkbox-label"
                          id="demo-mutiple-checkbox"
                          multiple
                          value={selectedStatus}
                          onChange={(e)=>handleChange(e,"bType")}
                          input={<Input classes={{input:classes.input}}/>}
                          renderValue={(selected) => selected.join(', ')}
                          MenuProps={MenuProps}
                          className={classes.MuiSelect}
                      >
                        {statusArr.map((name) => (
                            <MenuItem key={name} value={name}>
                              <Checkbox
                                  checked={selectedStatus.indexOf(name) > -1}
                                  color="primary"
                                  style={myTheme.palette.primary}
                              />
                              <ListItemText className={classes.input} primary={name} disableTypography={true}/>
                            </MenuItem>
                        ))}
                      </Select>
                    </InputGroup>
                    <InputGroup className={styles.input_group}>
                      <InputLabel id="demo-mutiple-checkbox-label" className={classes.inputLabel}>Guest</InputLabel>
                      <ReactSelect
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        isMulti
                        options={[selectAllOption,...guests]}
                        onChange={handleCustomSelectChange}
                        value={selectedGuests}
                        components={{ Option, ValueContainer, DropdownIndicator }}
                        className={classes.customSelect}
                        styles = {customSelectStyles}
                        placeholder={<div>Select...</div>}
                      />
                    </InputGroup>
                  </div>
                </div>
              </div>
            </div>
       </div>
       <div className={classes.mainContainer}>
      {!inquiryStatus.loading && (
          <Container fluid>
            <Row className="justify-content-center">
              <Col xs={12} md={12} className={`pt-3`}>
               <Table
                   rows={queryResult}
                   cols={cols}
                   dateFormat={dateDisplay.toUpperCase()}
                   startKey={cols[0].key}
                   heading={"Reservations"}
                   handleDelete={handleDelete}
               />
              </Col>
            </Row>
          </Container>
      )}
      </div>
    </div>
    <ConfirmMessage
          show={confirmDel}
          onHide={() => {
            setConfirmDel(false);
            setDeleteInquiry([]);
          }}
          confirmHeader={`Warning: this action will delete a calendar reservation`}
          confirmBody={<><span>{"Reservations with the 'booked' status are active booking that are shown on your calendar."}</span><br/><span>{"Deleting the selected reservation will result in the deletion of the calendar booking. This action can not be undone."}</span><p>{"Are you sure you want to proceed?"}</p></>}
          onConfirmAct={()=> {
            confirmDelete(deleteInquiry);
              setConfirmDel(false);
          }}
          confirmDelete={true}
      />
    </MuiThemeProvider>
  );
}

export default Inquiry;
