import React from 'react';
import styles from '../pricing.module.css';
import { Link } from 'react-router-dom';

function FAQ() {
    return (
        <>
            <div className={styles.container_head}>
                <span className={styles.header}>Frequently asked questions</span>
            </div>
            <div className={styles.container_question}>
                <span className={styles.question}>Can I connect Beehaz to Airbnb, Booking.com, and VRBO?</span>
            </div>
            <div className={styles.container_answer}>
        <span className={styles.answer}>
        Yes, you can synchronize your Beehaz calendar with channels such as Airbnb, Booking.com, VRBO, and many others via iCal links. This way, whenever you get a booking in one channel, this information will be transmitted to Beehaz, who will block those dates on your Beehaz calendar, as well as on the other channels you are connected to. Rates are not synchronized at the moment. You can read more about iCal connection by clicking <a className={styles.content_link} target="_blank" href={"https://beehazcloud.wixsite.com/website-2/ical"}>here</a>. Manual changes are a thing of the past. You can also link your Behaz calendar to Google this way!
        </span>
        </div>
            <div className={styles.container_question}>
                <span className={styles.question}>Do I need a merchant account in order to accept credit card payments?</span>
            </div>
            <div className={styles.container_answer}>
        <span className={styles.answer}>
            No. All you need in order to accept credit card payments is a Stripe account, and you can register for free.
        </span>
            </div>
            <div className={styles.container_question}>
                <span className={styles.question}>Do I need to download the Beehaz software?</span>
            </div>
            <div className={styles.container_answer}>
        <span className={styles.answer}>
          No, Beehaz is cloud-based. This means you can access your Beehaz account from any computer, tablet or phone. All you need is an internet connection and you are ready to log in!
        </span>
            </div>
            <div className={styles.container_question}>
                <span className={styles.question}>Do I have to sign a contract? What is your cancellation policy?</span>
            </div>
            <div className={styles.container_answer}>
        <span className={styles.answer}>
          No, you do not have to sign a contract. You can sign up and start using Beehaz right away, without having to enter your credit card details or billing information. If  you decide to switch to the paid version, you can cancel at any time. For more information about our policies, please read our
        </span>{' '}
                <Link to="/terms" className={styles.content_link}>
                    terms of service
                </Link>
                <span className={styles.answer}>.</span>
            </div>
            <div className={styles.container_question}>
                <span className={styles.question}>How safe is Beehaz? </span>
            </div>
            <div className={styles.container_answer}>
        <span className={styles.answer}>
          Beehaz does not store passwords, billing or credit card information. This makes Beehaz a safe software to use. You can sign in using either your email address (via tolken) or through Google or Facebook verification. Invoice credit card processing, if set up,  are paid through Stripe. Our cloud software is hosted in Amazon Web Services (AWS), which is very secure. No software or device connected to the internet is unhackable, but we make sure to take the necessary steps to make our services as secure as possible.
        </span>
            </div>
            <div className={styles.container_question}>
                <span className={styles.question}>Can I access Beehaz through my phone?</span>
            </div>
            <div className={styles.container_answer}>
        <span className={styles.answer}>
          Yes, you can log in from any device. When you access the software from a phone, you will have the same functionality as when you access it from your computer. This is because you are using a Progressive Web Application (PWA) instead of a native app. In a nutshell, the differences are: direct installation process  (from our website rather than from an app store), instant updates, loads faster even with poorer connectivity, and PWAs takes less space on your phone than their native apps counterparts. If you prefer not to install the application, you can still access the software from the browser on your phone.
        </span>
            </div>
            <div className={styles.container_question}>
                <span className={styles.question}>Is Beehaz for me?</span>
            </div>
            <div className={styles.container_answer}>
        <span className={styles.answer}>
          Our software was build by and for the small business owners. Beehaz is perfect for you if you rent out furnished apartments, vacation rentals, boutique hotels, guest houses, bed and breakfast, inns, aparthotels, and even rooms or parking spaces.
        </span>
                <br />
                <br />
                <span className={styles.answer}>
          Price is an important factor to entrepreneurs, and we understand that. That is the reason we have developed an intuitive interface that includes only the essential features you would expect to run your business. 
        </span>
                <br />
                <br />
                <span className={styles.answer}>
          Our pricing policy is very clear and there are no extra costs hidden in the small print. You pay as you grow.
        </span>
            </div>
            <div className={styles.container_question}>
                <span className={styles.question}>I still have questions. What is the best way to contact Beehaz?</span>
            </div>
            <div className={styles.container_answer}>
        <span className={styles.answer}>
          We look forward to answering any questions you may have!
You can send your questions via our
        </span>{' '}
                <Link to="/contact_us" className={styles.content_link}>
                    contact form
                </Link>
                <span className={styles.answer}>
          {' '}
                    contact form or write to us via live chat!
Please note that our business hours are presented in Central European Time (CET). If you do not find an agent online, leave us a message and we will get back to you as soon as possible!
        </span>
            </div>
        </>
    );
}

export default FAQ;