import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {Table} from 'react-bootstrap';
import AddWeeklyRate from './AddRate/WeeklyRate';
import MonthlyRate from './AddRate/MonthlyRate';
import styles from '../../rates.module.css';

const Special = () => {

    const [weeklyRateModal, setWeeklyRateModal] = useState(false);
    const [monthlyRateModal, setMonthlyRateModal] = useState(false);

    const rateList = useSelector(({rates}) => rates && rates.rateSettings);
    const rentals = useSelector(({rentals}) => rentals && rentals.rentals);
    const currency =useSelector(({user})=>user && user.user && user.user.currency);

    const rateName = (id) => {
        const tmp = rentals.filter((rental) => rental.id === Number(id))
        if (tmp.length !== 0) {
            return tmp[0].name;
        } else {
            return "";
        }
    }

    const findCurrency = (id) => {
        const tmp = rentals.filter((rental) => rental.id === Number(id))
        if (tmp.length !== 0) {
            return tmp[0].currency;
        } else {
            return "";
        }
    }

    return (
        <div>
            <AddWeeklyRate
                show={weeklyRateModal}
                onHide={() => {
                    setWeeklyRateModal(false);
                }}
                currency={currency}
            />
            <MonthlyRate
                show={monthlyRateModal}
                onHide={() => {
                    setMonthlyRateModal(false);
                }}
                currency={currency}
            />
            <div className="py-3">
                <button className={styles.ratelistBtn} onClick={() => setWeeklyRateModal(true)}>
                    Add weekly rate
                </button>
                <button className={styles.ratelistBtn} onClick={() => setMonthlyRateModal(true)}>
                    Add monthly rate
                </button>
            </div>
            <div className={`col-sm-8 col-lg-6 col-xl-4 col-xs-12 p-0`}>
                {
                    rateList && rateList.length !== 0 ? (
                        rateList.map((data, index) => {
                            return (
                                <Table responsive hover className={styles.ratelistTable} key={index}>
                                    <thead>
                                    <tr>
                                        <th colSpan={2}>
                                            <div className={styles.rentalName}>
                                                {rateName(data.rentalId)}
                                            </div>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className={styles.tableWidth65}>Weekly rate:</td>
                                        <td className={styles.tableWidth35}>
                                            {data.weekPrice} {findCurrency(data.rentalId)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={styles.tableWidth65}>Weekly tax:</td>
                                        <td className={styles.tableWidth35}>10.00 {findCurrency(data.rentalId)}, VAT
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={styles.tableWidth65}>Monthly rate:</td>
                                        <td className={styles.tableWidth35}>
                                            {data.monthlyPrice} {findCurrency(data.rentalId)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={styles.tableWidth65}>Monthly tax:</td>
                                        <td className={styles.tableWidth35}>20.00 {findCurrency(data.rentalId)}, VAT
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            );
                        })
                    ) : (
                        <h1>No table to display</h1>
                    )
                }
            </div>
        </div>
    );
};

export default Special;