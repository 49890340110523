import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux'
import {Form, InputGroup} from 'react-bootstrap';
import getSymbolFromCurrency from 'currency-symbol-map';
import {Formik} from 'formik';
import {toast} from "react-toastify";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ReactTooltip from "react-tooltip";
import MultiSelect from '../../BulkDefaultChanges/MultiSelect';
import styles from '../../../rates.module.css';

const MonthlyRateForm = ({schema, handleSubmit, properties, currency, onHide}) => {

    const rentals = useSelector(({rentals}) => rentals && rentals.rentals);

    const [selectedRentals, setSelectedRentals] = useState([]);
    const [count, setCount] = useState(0);

    useEffect(() => {
        count === 1 && toast.warn(`Different currencies used in different rentals. Check the changes.`
            , {
                style: {
                    color: '#484848'
                }
            });
    }, [count]);

    const getOptions = () => {
        const tmp = [];
        rentals.map((row) => (tmp.push({value: row.id, label: row.name})));
        return tmp;
    }

    const validate = (values) => {
        let errors = {};
        if (!selectedRentals.length) {
            errors.multiRentals = 'Rental selection is a required field'
        }
        return errors;
    }

    const onSubmit = (data) => {
        if (selectedRentals && selectedRentals.length !== 0) {
            handleSubmit(data, selectedRentals);
        }
    }

    const sameCurrency = (row) => {
        return row.currency === currency;
    }

    const diffCurrencyWarning = () => {
        if (!properties.every(sameCurrency)) {
            setCount(count + 1);
        }
    }

    const handleReset = () => {
        setSelectedRentals([]);
        onHide();
    }

    return (
        <>
            <Formik
                initialValues={{
                    multiRentals: [],
                    rate: 0,
                    extraRate:0,
                    taxName: '',
                    taxAmount: 0,
                    extraTax:0,
                }}
                validationSchema={schema}
                onSubmit={onSubmit}
                validateOnChange={false}
                validateOnBlur={false}
                validate={validate}
            >
                {({
                      values, errors, handleChange, handleSubmit, initialValues
                  }) => (
                    <Form onSubmit={handleSubmit}>
                        <Form.Row className='py-1'>
                            <Form.Label className={`col-sm-4 col-form-label`}>Select rental:*</Form.Label>
                            <div className="col-md-8 col-lg-6">
                                <MultiSelect
                                    options={getOptions()}
                                    value={selectedRentals}
                                    name="multiRentals"
                                    onChange={setSelectedRentals}
                                    fieldHasError={!!errors.multiRentals}
                                    onBlur={diffCurrencyWarning}
                                />
                                <div style={{
                                    fontSize: '0.8em',
                                    color: '#dc3545',
                                    padding: '3px 0em 0em'
                                }}>{errors.multiRentals}</div>
                            </div>
                        </Form.Row>
                        <br/>
                        <Form.Row>
                            <Form.Label className="col-sm-6 col-md-6 col-lg-4 col-form-label">{`Set monthly rate (fixed price for 1 month):`}</Form.Label>
                            <div className="col-md-5 col-lg-6">
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        type="float"
                                        name="rate"
                                        onChange={handleChange}
                                        isInvalid={!!errors.rate}
                                        defaultValue={initialValues.rate}
                                        className={styles.form_entry}
                                    />
                                    <InputGroup.Append>
                                        <InputGroup.Text id="basic-addon2" className={styles.form_entry}>{getSymbolFromCurrency(`${currency}`)}</InputGroup.Text>
                                    </InputGroup.Append>
                                    <Form.Control.Feedback type="invalid">{errors.rate}</Form.Control.Feedback>
                                </InputGroup>
                            </div>
                        </Form.Row>
                        <Form.Row>
                            <Form.Label className="col-sm-6 col-md-6 col-lg-4 col-form-label">{`Extra night for monthly rate:`}
                                <InfoOutlinedIcon
                                    fontSize="small"
                                    color="inherit"
                                    data-tip
                                    data-for="monthly-booking"
                                    className={styles.toolTipIcon}
                                />
                            </Form.Label>
                            <ReactTooltip place="bottom" type="dark" id="monthly-booking" effect="solid" className={styles.toolTip}>
                                <span>
                                    Fixed - term monthly reservations set a fixed rate for 1 month bookings. After the reservation had been changed, shall you decide to add extra nights for the reservation, this special extra night rate will be used to calculate the new price.
                                    Reservations created with the ‘add fixed’ button cannot be transformed into regular reservations after being generated.
                                </span>
                            </ReactTooltip>
                            <div className="col-md-5 col-lg-6">
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        type="float"
                                        name="extraRate"
                                        onChange={handleChange}
                                        isInvalid={!!errors.extraRate}
                                        defaultValue={initialValues.extraRate}
                                        className={styles.form_entry}
                                    />
                                    <InputGroup.Append>
                                        <InputGroup.Text id="basic-addon2" className={styles.form_entry}>{getSymbolFromCurrency(`${currency}`)}</InputGroup.Text>
                                    </InputGroup.Append>
                                    <Form.Control.Feedback type="invalid">{errors.extraRate}</Form.Control.Feedback>
                                </InputGroup>
                            </div>
                        </Form.Row>
                        <Form.Row>
                            <Form.Label className="col-form-label">Set tax for the monthly rate:</Form.Label>
                        </Form.Row>
                        <br/>
                        <Form.Row>
                            <Form.Label className="col-sm-6 col-md-6 col-lg-4 col-form-label">Name of tax:</Form.Label>
                            <div className="col-md-5 col-lg-6">
                                <Form.Control
                                    type="text"
                                    name="taxName"
                                    onChange={handleChange}
                                    defaultValue={initialValues.taxName}
                                    className={styles.form_entry}
                                    isInvalid={!!errors.taxName}
                                />
                                <div
                                    style={{fontSize: '0.8em', color: '#dc3545', padding: '0em'}}>{errors.taxName}</div>
                            </div>
                        </Form.Row>
                        <br/>
                        <Form.Row>
                            <Form.Label className="col-sm-6 col-md-6 col-lg-4 col-form-label">Tax amount (flat rate):</Form.Label>
                            <div className="col-md-5 col-lg-6">
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        type="float"
                                        name="taxAmount"
                                        onChange={handleChange}
                                        isInvalid={!!errors.taxAmount}
                                        defaultValue={initialValues.taxAmount}
                                        className={styles.form_entry}
                                    />
                                    <InputGroup.Append>
                                        <InputGroup.Text
                                            id="basic-addon2"
                                            className={styles.form_entry}
                                        >
                                            {getSymbolFromCurrency(`${currency}`)}
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                                    <Form.Control.Feedback type="invalid">{errors.taxAmount}</Form.Control.Feedback>
                                </InputGroup>
                            </div>
                        </Form.Row>
                        <Form.Row>
                            <Form.Label className="col-sm-6 col-md-6 col-lg-4 col-form-label">{`Tax amount (flat rate for extra night):`}</Form.Label>
                            <div className="col-md-5 col-lg-6">
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        type="float"
                                        name="extraTax"
                                        onChange={handleChange}
                                        isInvalid={!!errors.extraTax}
                                        defaultValue={initialValues.extraTax}
                                        className={styles.form_entry}
                                    />
                                    <InputGroup.Append>
                                        <InputGroup.Text id="basic-addon2" className={styles.form_entry}>{getSymbolFromCurrency(`${currency}`)}</InputGroup.Text>
                                    </InputGroup.Append>
                                    <Form.Control.Feedback type="invalid">{errors.extraTax}</Form.Control.Feedback>
                                </InputGroup>
                            </div>
                        </Form.Row>
                        <br/>
                        <div className='p-2 d-flex justify-content-center flex-column'>
                            <div className={`col d-flex justify-content-around ${styles.endNote}`}>
                                {`Monthly rate total: ${(parseFloat(values.rate)+ parseFloat(values.taxAmount)).toFixed(2)} ${getSymbolFromCurrency(`${currency}`)}`}
                            </div>
                            <div className='mt-2 col d-flex justify-content-around'>
                                <button type="submit" className={`col-lg-5 p-1 mx-2 col-sm-5 ${styles.allBtn}`}>Save
                                </button>
                                {/* <button type="reset" className={`col-lg-5 p-1 mx-2 col-sm-5 ${styles.allBtn}`}
                                        style={{backgroundColor: "#6c757d"}} onClick={handleReset}>Discard
                                </button> */}
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default MonthlyRateForm;