import React from 'react';
import {useSelector} from "react-redux";
import {Modal} from 'react-bootstrap';
import * as yup from 'yup';

import styles from '../../../../Rentals/rentals.module.css';
import TaxForm from './TaxForm';

function AddTax(props){
  const optionsSelect=useSelector(({rentals})=>rentals && rentals.rentals);
  const schemaTax = yup.object({
    multiRentals: yup.array().required("Rental selection is a required field!"),
    tax_name: yup.string().required("Tax name is a required field!"),
    tax_type: yup.string().required("Tax Type is a required field!"),
    amount: yup.number().required("Amount is a required field!").typeError("You must specify a number."),
    modality: yup.string().required("Modality is a required field."),
  });

  const handleSubmit=(data,rentalSelection)=>{
    const payload=[];
    rentalSelection.map((rental)=>{
      payload.push({
        rentalId:rental.value,
        name:data.tax_name,
        taxType:data.tax_type,
        amount:data.amount,
        modality:data.modality
      })
    });
    // console.log(payload);
    if(props.viewforedit){
      props.onEdit({...payload[0], id: props.viewforedit.id});
    }else{
      props.onEdit(payload);
    }
  }
    return(
    <Modal
      onHide={props.onHide}
      show={props.show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className={styles.modalTitle}>
            Add New Tax
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.deleteModalBody}>
        <TaxForm
          schema={schemaTax}
          properties={optionsSelect}
          handleSubmit={handleSubmit}
          initValue={(props.viewforedit) ? props.viewforedit: false}
          currency={props.currency}
          onHide={props.onHide}
        />
      </Modal.Body>
    </Modal>
    )
}
export default AddTax;