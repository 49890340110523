import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import MailingList from '../Home/Containers/MailingList';
import {useDispatch} from "react-redux";
import styles from '../Home/homeDesign.module.css';
import ContactUs from '../Home/Containers/ContactUs';
import {changeCurrentPage, onLoad} from '../../general_redux/actions';

function Footer(props) {
  const dispatch=useDispatch();
  const [viewMailing, setViewMailing] = useState(false);
  const [viewAbout, setViewAbout] = useState(false);
  const [viewContactUs, setViewContactUs] = useState(false);

  return (
      <>
        <MailingList
            show={viewMailing}
            onHide={() => {
              setViewMailing(false);
            }}
        />
        <div className={`${styles.footerDiv} ${(props.isSticky?styles.stickBottom:'')}`}>
          <div className={styles.footerSection1}>
            <div className={styles.footerSection1inner}>
              <Link to={'/contact_us'} className={styles.footer_links}>
                Contact
              </Link>
              <Link to={"/aboutUs"} className={styles.footer_links}>
                About us
              </Link>
            </div>
            <div className={styles.footerSection1inner}>
              <a
                  className={styles.footer_links}
                  href={"https://www.beehaz-resources.com/"}
                  target={"_blank"}
              >Resources</a>
              <a
                  className={styles.footer_links}
                  href={"http://www.beehaz-resources.com/sitemap"}
                  target={"_blank"}
              >Sitemap</a>
            </div>
            <div className={styles.footerSection1inner3}>
              <Link to={"/"} onClick={()=>dispatch(changeCurrentPage("Home"))}>
                <img src="https://static.wixstatic.com/media/e30525_f12b8c35f7b34fcfafdde1cfe42a5ac0~mv2.png/v1/crop/x_118,y_82,w_780,h_218/fill/w_118,h_32,al_c,q_85,usm_0.66_1.00_0.01/logo-nobackground-1000.webp" />
              </Link>
              <span className={styles.footerIcontext}>Operated by Yaz Häuser AG </span>
            </div>
          </div>
          <div className={styles.footerSection2}>
            <div className={styles.footerSection1inner}>
              <Link className="mx-1" to={'/terms'}>
                Terms of Service
              </Link>
            </div>
            |
            <div className={styles.footerSection1inner}>
              <Link className={'mx-1'} to={'/privacy-policy'}>
                Privacy Policy
              </Link>
            </div>
            |
            <div className={styles.footerSection1inner}>
              <Link className={'mx-1'} to={'/cookie-policy'}>
                Cookie Policy
              </Link>
            </div>
          </div>
          <div className={styles.copyRightsdiv}>
          <span className={styles.copyRightsdivinner}>
            Copyright © 2021{` `}
            <a
                href="https://beehaz.com/"
                target="_blank"
                data-content="https://beehaz.com/"
                data-type="external"
                rel="nofollow noopener"
                style={{color:'#989696'}}
            >
              BeeHaz
            </a>
            . All rights reserved.
          </span>
          </div>
        </div>
      </>
  );
}

export default Footer;