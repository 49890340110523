import { toast } from 'react-toastify';
import {
    GET_INVOICES,
    ADD_INVOICE,
    DELETE_INVOICE,
    EDIT_INVOICE_BY_BOOKING,
    EDIT_INVOICE_BY_GUEST,
    EDIT_INVOICE_LIST,
    INVOICE_BY_BOOKING,
    INVOICE_BY_GUEST,
    CLEAR_INVOICE,
    DELETE_MULTIPLE_INVOICE,
    GET_FILTER_INVOICES,
    MARK_INVOICE,
    SEND_INVOICE,
    SCHEDULE_INVOICE,
    GET_SCHEDULE_INVOICE,
    CANCEL_SCHEDULE_INVOICE,
    RESET_INVOICE_ACTIONS
} from "./types";

import { actionForState } from '../../common/functions/utils';
toast.configure();

const INITIAL_STATE = {
   invoiceList:[],
   invoiceByBooking:[],
   invoiceByGuest:[],
   sendInvoiceData:[],
   action: {
        loading: false,
        success: undefined,
   },
   actionForGetInvoice:{
        loading: false,
        success: false,
        error: false,
   },
   actionForAddInvoice:{
       loading: false,
       success: false,
       error: false,
   },
   actionForDeleteInvoice:{
       loading: false,
       success: false,
       error: false,
   },
   actionForBookingInvoice:{
       loading:false,
       success:false,
       error:false
   },
   actionForEditInvoice:{
       loading:false,
       success:false,
       error:false
   },
   actionForGuest:{
       loading:false,
       success:false,
       error:false,
   },
   actionForSendInvoice:{
       loading:false,
       success:false,
       error:false
   },
   actionForScheduleInvoice:{
        loading:false,
        success: false,
        error: false
   },
   actionForCancelInvoice:{
        loading: false,
        success:false,
        error: false,
   }
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_INVOICES.pending:
            return{
                ...state,
                actionForGetInvoice: actionForState(state.action,'pending')
            };
        case GET_INVOICES.success:
            return{
                ...state,
                invoiceList: action.payload.invoices,
                actionForGetInvoice: actionForState(state.action,'success')
            }
        case GET_INVOICES.error:
            toast.error(action.payload.message)
            return{
                ...state,
                actionForGetInvoice: actionForState(state.action,'error')
            }
        case ADD_INVOICE.pending:
            return{
                ...state,
                actionForAddInvoice: actionForState(state.action,'pending')
            }
        case ADD_INVOICE.success:
            toast.success("Invoice added successfully!")
            return{
                ...state,
                invoiceList: state.invoiceList.concat({
                    ...action.payload.invoice
                }),
                actionForAddInvoice: actionForState(state.action,'success')
            }
        case ADD_INVOICE.error:
            toast.error(action.payload.message)
            return{
                ...state,
                actionForAddInvoice: actionForState(state.action,'error')
            }
        case INVOICE_BY_BOOKING.pending:
            return{
                ...state,
                actionForBookingInvoice:actionForState(state.action,'pending')
            }
        case INVOICE_BY_BOOKING.success:
            return{
                ...state,
                invoiceByBooking: action.payload.invoices,
                actionForBookingInvoice:actionForState(state.action,'success')
            }
        case INVOICE_BY_BOOKING.error:
            toast.error(action.payload.message)
            return{
                ...state,
                actionForBookingInvoice:actionForState(state.action,'error')
            }
        case DELETE_INVOICE.pending:
            return{
                ...state,
                actionForDeleteInvoice: actionForState(state.action,'pending')
            }
        case DELETE_INVOICE.success:
            toast.success("Invoice deleted successfully!")
            return{
                ...state,
                invoiceList: state.invoiceList.filter((row) => {
                    if (row.id !== Number(action.payload.id)) {
                        return row;
                    }
                }),
                invoiceByGuest: state.invoiceByGuest.filter((row) => {
                    if(row.id !== Number(action.payload.id)) {
                        return row;
                    }
                }),
                actionForDeleteInvoice: actionForState(state.action,'success')
            }
        case DELETE_INVOICE.error:
            toast.error(action.payload.message)
            return{
                ...state,
                actionForDeleteInvoice: actionForState(state.action,'error')
            }
        case DELETE_MULTIPLE_INVOICE.pending:
            return{
                ...state,
                actionForDeleteInvoice: actionForState(state.action,'pending'),
            }
        case DELETE_MULTIPLE_INVOICE.success:
            toast.success('Invoices deleted successfully!')
            const deleteInvoice = action.payload.invoices
            return{
                ...state,
                invoiceList: state.invoiceList.filter((row) => !deleteInvoice.includes(row.id)),
                invoiceByGuest: state.invoiceByGuest.filter((row) => !deleteInvoice.includes(row.id)),
                actionForDeleteInvoice: actionForState(state.action,'success')
            }
        case DELETE_MULTIPLE_INVOICE.error:
            toast.error(action.payload.message)
            return{
                ...state,
                actionForDeleteInvoice: actionForState(state.action,'error')
            }
        case CLEAR_INVOICE:
            return{
                ...state,
                invoiceByBooking: INITIAL_STATE.invoiceByBooking,
                actionForGetInvoice: INITIAL_STATE.actionForGetInvoice,
                actionForBookingInvoice: INITIAL_STATE.actionForBookingInvoice,
                actionForAddInvoice: INITIAL_STATE.actionForAddInvoice,
                actionForDeleteInvoice: INITIAL_STATE.actionForDeleteInvoice,
                actionForGuest: INITIAL_STATE.actionForGuest,
            }
        case EDIT_INVOICE_BY_BOOKING.pending:
            return{
                ...state,
                actionForEditInvoice: actionForState(state.action,'pending')
            }
        case EDIT_INVOICE_BY_BOOKING.success:
            toast.success("Invoice successfully updated!")
            const InvoiceList=state.invoiceByBooking
            const updateIndex=InvoiceList.findIndex(({id})=>id ===action.payload.invoices.id)
            if(updateIndex>=0){
                InvoiceList[updateIndex]=action.payload.invoices
            }
            return{
                ...state,
                invoiceByBooking: InvoiceList,
                actionForEditInvoice: actionForState(state.action,'success')
            }
        case EDIT_INVOICE_BY_BOOKING.error:
            toast.error(action.payload.message)
            return{
                ...state,
                actionForEditInvoice: actionForState(state.action,'error')
            }
        case EDIT_INVOICE_BY_GUEST.pending:
            return{
                ...state,
                actionForEditInvoice: actionForState(state.action,'pending')
            }
        case EDIT_INVOICE_BY_GUEST.success:
            toast.success("Invoice successfully updated!")
            const InvoiceListG=state.invoiceByGuest
            const updateIndexG=InvoiceListG.findIndex(({id})=>id ===action.payload.invoices.id)
            if(updateIndexG>=0){
                InvoiceListG[updateIndexG]=action.payload.invoices
            }
            return{
                ...state,
                invoiceByGuest: InvoiceListG,
                actionForEditInvoice: actionForState(state.action,'success')
            }
        case EDIT_INVOICE_BY_GUEST.error:
            toast.error(action.payload.message)
            return{
                ...state,
                actionForEditInvoice: actionForState(state.action,'error')
            }
        case EDIT_INVOICE_LIST.pending:
            return{
                ...state,
                actionForEditInvoice: actionForState(state.action,'pending')
            }
        case EDIT_INVOICE_LIST.success:
            toast.success("Invoice successfully updated!")
            const InvoicesList = state.invoiceList
            const updatedIndex = InvoicesList.findIndex(({id})=>id ===action.payload.invoices.id)
            if(updatedIndex>=0) {
                InvoicesList[updatedIndex]={...action.payload.invoices, guestName: InvoicesList[updatedIndex].guestName, rentalName: InvoicesList[updatedIndex].rentalName}
            }
            return{
                ...state,
                invoiceList: InvoicesList,
                actionForEditInvoice: actionForState(state.action,'success')
            }
        case EDIT_INVOICE_LIST.error:
            toast.error(action.payload.message)
            return{
                ...state,
                actionForEditInvoice: actionForState(state.action,'error')
            }
        case INVOICE_BY_GUEST.pending:
            return{
                ...state,
                actionForGuest: actionForState(state.action,'pending')
            }
        case INVOICE_BY_GUEST.success:
            return{
                ...state,
                invoiceByGuest: action.payload.invoices,
                actionForGuest: actionForState(state.action,'success')
            }
        case INVOICE_BY_GUEST.error:
            toast.error(action.payload.message)
            return{
                ...state,
                actionForGuest: actionForState(state.action,'error')
            }
        case GET_FILTER_INVOICES.pending:
            return{
                ...state,
                actionForGetInvoice: actionForState(state.action,'pending')
            };
        case GET_FILTER_INVOICES.success:
            return{
                ...state,
                invoiceList: action.payload.data,
                actionForGetInvoice: actionForState(state.action,'success')
            }
        case GET_FILTER_INVOICES.error:
            toast.error(action.payload.message)
            return{
                ...state,
                actionForGetInvoice: actionForState(state.action,'error')
            }
        case MARK_INVOICE.pending:
            return{
                ...state,
            }
        case MARK_INVOICE.success:
            const InvoiceListReplica = state.invoiceList
            const InvoiceListBooking = state.invoiceByBooking
            const updateIndexList = InvoiceListReplica.findIndex(({id})=>id ===action.payload.data.id)
            const bookingInvoiceIndex = InvoiceListBooking.findIndex(({id})=>id ===action.payload.data.id)
            if(updateIndexList>=0){
                InvoiceListReplica[updateIndexList] = {...InvoiceListReplica[updateIndexList],markSent:action.payload.data.markSent}
            }
            if(bookingInvoiceIndex>=0){
                InvoiceListBooking[bookingInvoiceIndex]={...InvoiceListBooking[bookingInvoiceIndex],markSent:action.payload.data.markSent}
            }
            return{
                ...state,
                invoiceList:InvoiceListReplica,
                invoiceByBooking: InvoiceListBooking
            }
        case MARK_INVOICE.error:
            toast.error("Some error occurred please try again!")
            return{
                ...state,
            }
        case SEND_INVOICE.pending:
            return{
                ...state,
                actionForSendInvoice:actionForState(state.action,'pending')
            }
        case SEND_INVOICE.success:
            toast.success("Invoice sent successfully!")
            return{
                ...state,
                actionForSendInvoice: actionForState(state.action,'success')
            } 
        case SEND_INVOICE.error:
            toast.error(action.payload.message)
            return{
                ...state,
                actionForSendInvoice: actionForState(state.action,'error',action.payload.message),
            }
        case SCHEDULE_INVOICE.pending:
            return{
                ...state,
                actionForScheduleInvoice: actionForState(state.action, 'pending')
            }   
        case SCHEDULE_INVOICE.success:
            toast.success("Email has been scheduled successfully!")
            return{
                ...state,
                actionForCancelInvoice: INITIAL_STATE.actionForCancelInvoice,
                actionForScheduleInvoice: actionForState(state.action, 'success'),
                sendInvoiceData: action.payload,
            }
        case SCHEDULE_INVOICE.error:
            toast.error(action.payload.message)
            return{
                ...state,
                actionForScheduleInvoice: actionForState(state.action,'error',action.payload.message)
            }
        case GET_SCHEDULE_INVOICE.pending:
            return{
                ...state,
            }
        case GET_SCHEDULE_INVOICE.success:
            return{
                ...state,
                sendInvoiceData: action.payload,
            }
        case GET_SCHEDULE_INVOICE.error:
            return{
                ...state,
            }
        case CANCEL_SCHEDULE_INVOICE.pending:
            return{
                ...state,
                actionForCancelInvoice: actionForState(state.action,'pending')
            }
        case CANCEL_SCHEDULE_INVOICE.success:
            toast.success(action.payload.message);
            return{
                ...state,
                actionForScheduleInvoice: INITIAL_STATE.actionForScheduleInvoice,
                actionForCancelInvoice: actionForState(state.action,'success'),
                sendInvoiceData: INITIAL_STATE.sendInvoiceData
            }
        case CANCEL_SCHEDULE_INVOICE.error:
            toast.error(action.payload.message);
            return{
                ...state,
                actionForCancelInvoice: actionForState(state.action,'error',action.payload.message)
            }
        case RESET_INVOICE_ACTIONS:
            return{
                ...state,
                actionForScheduleInvoice: INITIAL_STATE.actionForScheduleInvoice,
                actionForCancelInvoice: INITIAL_STATE.actionForCancelInvoice,
                actionForSendInvoice: INITIAL_STATE.actionForSendInvoice,
                actionForGetInvoice: INITIAL_STATE.actionForGetInvoice,
                sendInvoiceData: INITIAL_STATE.sendInvoiceData,
            }
        default:
            return{
                ...state,
            }
    }
}