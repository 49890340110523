import React,{useState,useEffect} from 'react'
import {useSelector,useDispatch} from 'react-redux';
import styles from './channel.module.css';
import {Form} from 'react-bootstrap';
import styles2 from '../Rates/rates.module.css';
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ReactTooltip from "react-tooltip";
import AddChannel from './Components/AddChannels/AddChannels';
import {addChannel,delChannel} from '../../../../general_redux/calendar/actions';
import channelText from './Components/AddChannels/channelText';
import Confirmation from '../../../../components/Confirmation';
import {Field} from 'formik';

function ChannelManagement(props){
    const dispatch =useDispatch();
    const [showDel,setShowDel]=useState(null);
    const [colorVal,setColorVal]=useState('#3D3D3D');
    const [paymentSts,setPaymentSts]=useState("Paid");
    const channels = useSelector(({calendar})=>calendar && calendar.channels);
    const [editMode,setEditMode]=useState(false);
    const rentals=useSelector(({rentals})=>rentals && rentals.rentals);
    const [channelName,setChannelName]=useState('');
    const [addChannelView,setaddChannelView]=useState(false);
    const [editData,setEditData]=useState(null);
    const confirmHeader="Delete Channel";
    const confirmBody="Are you sure you want to delete the channel\n" +
        "connection? Synchronization between Beehaz\n" +
        "and this calendar will be broken.";
    const paymentStatus=[
        "Payment pending",
        "Partially paid",
        "Paid",
    ];

    const setInit=()=>{
        let tmp=[];
        if(rentals){
            rentals.map((rental)=>{
                tmp.push({id:rental.id, link:''})
            })
        }
        return tmp;
    }

    const [channelData,setChannelData]=useState(setInit);

    const onSelection=(data)=>{
        setaddChannelView(false);
        setEditMode(true);
        setEditData(data);
        dispatch(addChannel([data]));
    }
    const handleSubmit=(e)=>{
        e.preventDefault();
        console.log(channelData);
    }

    const handleFieldChange=(e,id)=>{
        // const tmp=;
        let tmp=[...channelData];
        const indx=channelData.findIndex((ch)=>ch.id===Number(id));

        if(indx!==-1){
            tmp[indx]={
                    id:Number(id),
                    link:e.target.value
                }
            setChannelData(tmp);
        }
    }

    const handleDelete=(value)=>{
        dispatch(delChannel(value));
        onHide();
    }

    const handleCopy=(e,id)=>{
        e.preventDefault();
        const copyElem=document.getElementById(`myLinkCopy-${id}`);
        copyElem.select();
        document.execCommand("copy");
    }

    const onHide=()=>{
        setShowDel(null);
    }

    return(
        <>
            <div className={styles.guestHeader}>
                <div>
                    <div className={styles.header}>
                        <span>iCal Connections</span>
                        <div className={styles2.toolTipIconContainer}>
                            <InfoOutlinedIcon
                                fontSize="small"
                                color="white"
                                data-tip
                                data-for="newChannel"
                                className={styles2.toolTipIcon}
                            />
                            <ReactTooltip place="bottom" type="dark" id="newChannel" className={styles2.toolTip}>
                          <span className={styles.lightSp}>
                            Beehaz uses iCal connections to synchronize rental calendars with other
                            channels. This allows you to view all reservations from one place.
                            Synchronization times may vary according to the connected calendars.
                            Reservation details shared by other channels will vary.
                          </span>
                          <br></br>
                          <br></br>
                          <span className={styles.lightSp}>
                          Note that your rates, minimum stay requirements, and calendar window will not synchronize. We recommend disabling instant
                          bookings on OTAs.
                          </span>
                            </ReactTooltip>
                        </div>
                    </div>
                </div>
                <div className={styles.flexEnd}>
                    <button className={`${styles.btn} w-25`} onClick={()=>setaddChannelView(true)}>
                        Add Connection
                    </button>
                </div>
            </div>
            <AddChannel
                show={addChannelView}
                onHide={()=>setaddChannelView(false)}
                onSelection={(data)=>onSelection(data)}
            />
            {!editMode ?(
                <div className={`p-3`} style={{"marginTop":"30px"}}>
                    <div className={styles.channelHeader}>
                        Connected Channels
                    </div>
                    <Confirmation
                       onHide={onHide}
                       show={!!showDel}
                       confirmHeader={confirmHeader}
                       confirmBody={confirmBody}
                       onConfirmAct={()=>{
                           handleDelete(showDel);
                       }}
                    />
                    {(channels && channels.length===0) ?(
                        <>
                            <span>You have not yet linked any calendar to Beehaz.</span>
                        </>
                    ):(
                        <>
                            {channels && channels.map((channel)=>(
                                <>
                                    <div className={styles.listItem} key={channel.value}>
                                        <div>{`${channel.label}`}</div>
                                        <div className={`{styles.modalFooter} p-0 d-flex justify-content-center `}>
                                            <button
                                                type="submit"
                                                className={styles.btnClass}
                                                style={{backgroundColor:"#439a86"}}
                                                onClick={()=> {
                                                    setEditMode(true);
                                                    setEditData(channel);
                                                }}
                                            >
                                                Edit
                                            </button>
                                            <button
                                                className={styles.btnClass}
                                                style={{backgroundColor:"#dc3545"}}
                                                onClick={(e)=>{
                                                    e.preventDefault();
                                                    setShowDel(channel.value)
                                                }}
                                            >
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                    <hr className={styles.underLine} />
                                </>
                            ))}
                        </>
                    )}
                </div>
            ):(
                <div className={`p-3`}>
                    <div className={` d-flex justify-content-between`} style={{marginTop:"41px"}}>
                        <div className={styles.editHeader}>
                            <div>{editData.label}</div>
                        </div>
                        <button
                            className={styles.btnSave}
                            onClick={()=> {
                                setEditMode(false);
                                setEditData(null);
                            }}
                        >Back</button>
                    </div>
                    <hr className={styles.underLine} />
                    <div>
                        <p>{channelText[`${editData.value}`]}</p>
                    </div>
                    <div>
                        <Form onSubmit={handleSubmit}>
                            <div className={`d-flex`}>
                                <Form.Control
                                    className={`mr-2 ${styles.form_entry}`}
                                    name={"nameAlter"}
                                    value={channelName}
                                    onChange={(e)=>setChannelName(e.target.value)}
                                />
                                <button
                                    className={styles.btnSave}
                                    onClick={()=>console.log(channelName)}
                                >
                                    Save name
                                </button>
                            </div>
                            <br/>
                            <div className={'d-flex'}>
                                <div className={`d-flex justify-content-lg-start`}>
                                    <div
                                        className={`${styles.formInp1}`}
                                    >
                                        Set colour of bookings for this channel:
                                    </div>
                                    <Form.Control
                                        className={`${styles.form_entry_color} ${styles.formInp2}`}
                                        id={`selectColor`}
                                        type={"color"}
                                        value={colorVal}
                                        onChange={(e)=>setColorVal(e.target.value)}
                                    />
                                </div>
                            </div><br/>
                            <div className={`${styles.formInp1}`}>
                                <div className={styles.formInp1}>
                                    <div
                                        className={` col-form-label ${styles.markBookingLabel}`}
                                    >
                                        Mark incoming bookings as:
                                    </div>
                                    <Form.Control
                                        className={`w-100 ${styles.form_entry}`}
                                        id={`paymentsts`}
                                        as={"select"}
                                        value={paymentSts}
                                        onChange={(e)=>setPaymentSts(e.target.value)}
                                    >
                                        {paymentStatus.map((opt,index)=>(
                                            <option value={opt} key={index}>{opt}</option>
                                        ))}
                                    </Form.Control>
                                </div>
                            </div><br/>
                            {rentals && rentals.map((rental)=>(
                                <>
                                    <div id={rental.id} className={`d-flex mb-2 justify-content-lg-start`} key={rental.id}>
                                        <div className={styles.formInp1}>
                                            <div
                                                className={`col-form-label ${styles.rentalList}`}
                                            >
                                                <div className={styles.rentalName}>{rental.name}</div>
                                            </div>
                                            <Form.Control
                                                className={`w-100 ${styles.form_entry}`}
                                                // id={`${rental.id}-input`}
                                                id={`myLinkCopy-${rental.id}`}
                                                readOnly={true}
                                                value={`https://ical.link.from.beehaz.shows.here/${rental.id}`}
                                            />
                                        </div>
                                        <div className={styles.formInp2}>
                                            <button
                                                className={styles.btnCopy}
                                                onClick={(e)=>handleCopy(e,rental.id)}
                                            >Copy</button>
                                            <icon
                                                className={`fas fa-copy ${styles.hideIcon}`}
                                                style={{color:'#555',padding:'10px 0'}}
                                                onClick={(e)=>handleCopy(e,rental.id)}
                                            />
                                            <Form.Control
                                                className={`w-100 ${styles.form_entry}`}
                                                // id={`${rental.id}-input`}
                                                onChange={(e)=>handleFieldChange(e,rental.id)}
                                            />
                                        </div>
                                        <br/>
                                    </div>
                                </>
                            ))}
                            <button
                                type={"submit"}
                                className={styles.btnSave}
                            >Save</button>
                            <button
                                type={"reset"}
                                style={{background:'rgb(108, 117, 125)'}}
                                className={`${styles.btnSave}`}
                            >
                            Discard
                            </button>
                        </Form>
                    </div>
                </div>
            )}
        </>
    )
}

export default ChannelManagement;
