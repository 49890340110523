import React, {useEffect, useState} from 'react';
import getSymbolFromCurrency from 'currency-symbol-map'
import { Form,InputGroup } from 'react-bootstrap';
import { Formik } from 'formik';
import styles from '../../../rates.module.css';
import {toast} from "react-toastify";
import MultiSelect from "../../BulkDefaultChanges/MultiSelect";
import {useSelector} from "react-redux";

function ExtraGuest({schema, handleSubmit, properties, initValue, currency, onHide}){
    const rentals = useSelector(({ rentals }) => rentals && rentals.rentals);
    const [selectedCurr,setSelectedCurr]=useState(currency);
    const [count,setCount]=useState(0);
    const [selectedRentals, setSelectedRentals] = useState([]);
    const currencyVal=properties && properties.length!==0 && properties[0].currency;
    const sameCurrency=(row)=>{
        return row.currency===currencyVal;
    }
    const getOptions = () => {
        const tmp=[];
        rentals.map((row) => (tmp.push({ value: row.id, label: row.name })));
        return tmp;
    }

    function diffCurrencyWarning() {
        if (!properties.every(sameCurrency)) {
            setCount(count+1);
        }
    }
    const onSubmit=(data)=>{
        if(selectedRentals && selectedRentals.length!==0){
            handleSubmit(data,selectedRentals);
        }
    }

    useEffect( () => {
        count === 1 && toast.warn(`Different currencies used in different rentals. Check the changes.`
            ,{
                style:{
                    color:'#484848'
                }
            });
    }, [count]);

    const resetForm = (handleReset) => {
        handleReset();
        setSelectedRentals([]);
        onHide();
    }

    const validate = (values) => {
        let errors = {};
        if(!selectedRentals.length){
            errors.multiRentals = 'Rental selection is a required field'
        }
        return errors;
    }

    return(
        <>
            <Formik
                validationSchema={schema}
                initialValues={initValue ? initValue : {
                    multiRentals: [],
                    amount: '',
                    guestLimit: '',
                }}
                onSubmit={onSubmit}
                validateOnChange={false}
                validateOnBlur={false}
                validate={validate}
            >
                {({
                      values,handleReset,errors, handleChange, handleSubmit, initialValues
                  }) => (
                    <Form onSubmit={handleSubmit}>
                        {/*<Form.Row>*/}
                        {/*    <Form.Label className="col-sm-4 col-form-label">Select rental or group:</Form.Label>*/}
                        {/*    <div className="col-md-5 col-lg-6">*/}
                        {/*        <Form.Control*/}
                        {/*            as="select"*/}
                        {/*            name="rentalId"*/}
                        {/*            onChange={(e,val)=>{*/}
                        {/*                const tmp=properties.filter((row)=>row.id===Number(e.target.value));*/}
                        {/*                if(tmp && tmp.length!==0){*/}
                        {/*                    setSelectedCurr(tmp[0].currency);*/}
                        {/*                }*/}
                        {/*                values.rentalId=e.target.value;*/}
                        {/*            }}*/}
                        {/*            onBlur={diffCurrencyWarning}*/}
                        {/*            disabled={initValue}*/}
                        {/*            className={styles.form_entry}*/}
                        {/*            defaultValue={initialValues.rentalId}*/}
                        {/*        >*/}
                        {/*            {!initValue && <option disabled={true}> -- Select -- </option>}*/}
                        {/*            {(properties.every(sameCurrency)) && (<option key={"all"} value={"all"}>{"All rentals"}</option>)}*/}
                        {/*            {properties.map((property) => <option key={property.id} value={property.id}>{property.name}</option>)}*/}
                        {/*        </Form.Control>*/}
                        {/*        <div style={{fontSize:'0.8em',color:'#dc3545',padding:'0em'}}>{errors.rentalId}</div>*/}
                        {/*    </div>*/}
                        {/*</Form.Row>*/}
                        {/*<br/>*/}
                        <Form.Row className='py-1'>
                            <Form.Label className={`col-sm-4 col-form-label`}>Select rental:*</Form.Label>
                            <div className="col-md-8 col-lg-6">
                                <MultiSelect
                                    options={getOptions()}
                                    value={selectedRentals}
                                    name="multiRentals"
                                    onChange={setSelectedRentals}
                                    onBlur={diffCurrencyWarning}
                                    placeholder={"---Select Rental---"}
                                    fieldHasError={!!errors.multiRentals}
                                />
                                <div style={{fontSize:'0.8em',color:'#dc3545',padding:'0em'}}>{errors.multiRentals}</div>
                            </div>
                        </Form.Row>
                        <br/>
                        <Form.Row>
                            <Form.Label className="col-sm-4 col-form-label">Amount (per guest per night):</Form.Label>
                            <div className="col-md-5 col-lg-6">
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        type="float"
                                        name="amount"
                                        onChange={handleChange}
                                        defaultValue={initialValues.amount}
                                        className={styles.form_entry}
                                        isInvalid={!!errors.amount}
                                    />
                                    <InputGroup.Append>
                                        <InputGroup.Text id="basic-addon2" className={styles.form_entry}
                                        >{getSymbolFromCurrency(`${selectedCurr}`)}</InputGroup.Text>
                                    </InputGroup.Append>
                                    <Form.Control.Feedback type="invalid">{errors.amount}</Form.Control.Feedback>
                                </InputGroup>
                            </div>
                        </Form.Row>
                        <br/>
                        <Form.Row>
                            <Form.Label className="col-sm-4 col-form-label">For each guest after:</Form.Label>
                            <div className="col-md-5 col-lg-6">
                                <Form.Control
                                    type="number"
                                    name="guestLimit"
                                    onChange={handleChange}
                                    defaultValue={initialValues.guestLimit}
                                    className={styles.form_entry}
                                    isInvalid={!!errors.guestLimit}
                                />
                                <div style={{fontSize:'0.8em',color:'#dc3545',padding:'0em'}}>{errors.guestLimit}</div>
                            </div>
                        </Form.Row>
                        <br/>
                        <div className='p-2 d-flex justify-content-center'>
                            <div className='col-md-8 d-flex justify-content-around'>
                                <button  type="submit" className={`col-lg-5 p-1 mx-2 col-sm-5 ${styles.allBtn}`} >Save</button>
                                <button
                                    type="reset"
                                    className={`col-lg-5 p-1 mx-2 col-sm-5 ${styles.allBtn}`}
                                    style={{backgroundColor:"#6c757d"}}
                                    onClick={()=>resetForm(handleReset)}
                                >Discard</button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
};

export default ExtraGuest;